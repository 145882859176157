import { push } from 'connected-react-router';
import { EditItem as EditItemEndpoint, GetItems } from 'crypthum-sdk';
import { CreateItem as CreateItemEndpoint } from 'crypthum-sdk/dist/endpoints/items/CreateItem';
import { ListItem as ListItemEndpoint } from 'crypthum-sdk/dist/endpoints/items/ListItem';
import { ListItem } from 'crypthum-sdk/dist/schemas/ListItem';
import { NewItem } from 'crypthum-sdk/dist/schemas/NewItem';
import { Item } from 'crypthum-sdk/src/schemas/Item';
import { NotificationManager } from 'react-notifications';
import ItemForm from 'src/entities/ItemForm';
import { getNewItemAndUploadMedia } from 'src/factories/Item';

import { SDKProvider } from '../../components/providers/SDKProvider';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getItems = async (limit: number, offset: number, query: string): Promise<ListItem> =>
  await cryptoMuseumsSDK.endpoint<GetItems>(GetItems).run({
    limit: limit,
    offset: offset,
    query: query,
  });

const createItem = async (body: NewItem) =>
  await cryptoMuseumsSDK.endpoint<CreateItemEndpoint>(CreateItemEndpoint).run({
    body: body,
  });

const getItemById = async (id: number): Promise<Item> =>
  await cryptoMuseumsSDK.endpoint<ListItemEndpoint>(ListItemEndpoint).run({
    item_id: id,
  });

const editItem = async (id: number, body: NewItem) => {
  await cryptoMuseumsSDK.endpoint<EditItemEndpoint>(EditItemEndpoint).run({
    item_id: id,
    body: body,
  });
};

export interface CreateItem {
  type: types.CREATE_ITEM;
}

export interface CreateItemFailed {
  type: types.CREATE_ITEM_FAILED;
  error: string;
}

export interface CreateItemSuccess {
  type: types.CREATE_ITEM_SUCCESS;
}

export interface EditItem {
  type: types.EDIT_ITEM;
}

export interface EditItemFailed {
  type: types.EDIT_ITEM_FAILED;
  error: string;
}

export interface EditItemSuccess {
  type: types.EDIT_ITEM_SUCCESS;
}

export interface RequestItems {
  type: types.REQUEST_ITEMS;
}

export interface RequestItemsFailed {
  type: types.REQUEST_ITEMS_FAILED;
  error: string;
}

export interface RequestItemsSuccess {
  type: types.REQUEST_ITEMS_SUCCESS;
}

export interface ReceiveItems {
  type: types.RECEIVE_ITEMS;
  items: ListItem;
}

export interface RequestItem {
  type: types.REQUEST_ITEM;
}

export interface RequestItemFailed {
  type: types.REQUEST_ITEM_FAILED;
  error: string;
}

export interface RequestItemSuccess {
  type: types.REQUEST_ITEM_SUCCESS;
}

export interface ReceiveItem {
  type: types.RECEIVE_ITEM;
  item: Item;
}

export type ItemAction =
  | RequestItems
  | RequestItemsSuccess
  | RequestItemsFailed
  | ReceiveItems
  | CreateItem
  | CreateItemFailed
  | CreateItemSuccess
  | EditItem
  | EditItemFailed
  | EditItemSuccess
  | RequestItem
  | RequestItemFailed
  | RequestItemSuccess
  | ReceiveItem;

export const receiveItems = (items: ListItem) => (dispatch) => {
  dispatch({ type: types.RECEIVE_ITEMS, items: items });
};

export const createItemSuccess = () => (dispatch) => {
  dispatch({ type: types.CREATE_ITEM_SUCCESS });
  dispatch(receiveItems(new ListItem(0, [])));
  dispatch({ type: types.CREATE_PRESALE_SUCCESS });
  dispatch(push('/items'));
  NotificationManager.success('El asset ha sido creado correctamente.');
};

export const createItemFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_ITEM_FAILED, error: errorKey });
  NotificationManager.success(errorKey);
};

export const editItemSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_ITEM_SUCCESS });
  dispatch(push('/items'));
  NotificationManager.success('El asset ha sido editado correctamente.');
};

export const editItemFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_ITEM_FAILED, error: errorKey });
  NotificationManager.error(errorKey);
};

export const createItemAction = (request: ItemForm) => async (dispatch) => {
  dispatch({ type: types.CREATE_ITEM });
  dispatch(startLoading());
  createItem(await getNewItemAndUploadMedia(request))
    .then(() => {
      dispatch(createItemSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(endLoading());
      dispatch(createItemFailed(e));
    });
};

export const editItemAction = (id: number, request: ItemForm) => async (dispatch) => {
  dispatch({ type: types.EDIT_ITEM });
  dispatch(startLoading());
  editItem(id, await getNewItemAndUploadMedia(request))
    .then(() => {
      dispatch(editItemSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      console.log(e);
      dispatch(editItemFailed(e));
      dispatch(endLoading());
    });
};

export const requestItemsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEMS_FAILED, error: errorKey });
};

export const requestItems = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEMS });

  getItems(limit, offset, query)
    .then((items) => {
      dispatch(receiveItems(items));
    })
    .catch((e) => {
      dispatch(requestItemsFailed(e));
    });
};

export const requestItemsSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEMS_SUCCESS });
};

export const receiveItem = (item: Item) => (dispatch) => {
  dispatch({ type: types.RECEIVE_ITEM, item: item });
};

export const requestItemFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEM_FAILED, error: errorKey });
};

export const requestItemById = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEM });

  getItemById(id)
    .then((item) => {
      dispatch(receiveItem(item));
    })
    .catch((e) => {
      dispatch(requestItemFailed(e));
    });
};

export const requestItemSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_ITEM_SUCCESS });
};
