import { CreateRol, GetAclsActions, GetRoles, NewRolSchema, RolSchema, UpdateRol } from 'crypthum-sdk';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import * as types from './types';
import { startLoading, endLoading } from './global';

const cryptoMuseumsSDK = SDKProvider.get();

const getRoles = async (): Promise<RolSchema.Rol[]> => await cryptoMuseumsSDK.endpoint<GetRoles>(GetRoles).run();

const getActions = async (): Promise<string[]> => await cryptoMuseumsSDK.endpoint<GetAclsActions>(GetAclsActions).run();

const createRol = async (body: NewRolSchema.NewRol) =>
  await cryptoMuseumsSDK.endpoint<CreateRol>(CreateRol).run({
    body: body
  });

const editRol = async (id: number, body: NewRolSchema.NewRol) =>
  await cryptoMuseumsSDK.endpoint<UpdateRol>(UpdateRol).run({
    id: id,
    body: body
  });

export interface NewRol {
  type: types.NEW_ROL;
}

export interface NewRolFailed {
  type: types.NEW_ROL_FAILED;
  error: string;
}

export interface NewRolSuccess {
  type: types.NEW_ROL_SUCCESS;
}

export interface EditRol {
  type: types.EDIT_ROL;
}

export interface EditRolFailed {
  type: types.EDIT_ROL_FAILED;
  error: string;
}

export interface EditRolSuccess {
  type: types.EDIT_ROL_SUCCESS;
}

export interface RequestRoles {
  type: types.REQUEST_ROLES;
}

export interface ReceiveRoles {
  type: types.RECEIVE_ROLES;
  roles: RolSchema.Rol[];
}

export interface OpenAddModal {
  type: types.OPEN_ADD_MODAL;
}

export interface CloseAddModal {
  type: types.CLOSE_ADD_MODAL;
}

export interface OpenEditModal {
  type: types.OPEN_EDIT_MODAL;
  rol: RolSchema.Rol;
}

export interface CloseEditModal {
  type: types.CLOSE_EDIT_MODAL;
}

export interface RequestActions {
  type: types.REQUEST_ACTIONS;
}

export interface ReceiveActions {
  type: types.RECEIVE_ACTIONS;
  actions: string[];
}

export interface AddUserToRol {
  type: types.ADD_USER_TO_ROL;
  user: string;
}

export interface RemoveUserToRol {
  type: types.REMOVE_USER_TO_ROL;
  users: string[];
}

export type RolAction = EditRol |
  EditRolFailed |
  EditRolSuccess |
  NewRol |
  NewRolSuccess |
  NewRolFailed |
  RequestRoles |
  ReceiveRoles |
  OpenAddModal |
  CloseAddModal |
  OpenEditModal |
  CloseEditModal |
  RequestActions |
  ReceiveActions |
  AddUserToRol |
  RemoveUserToRol;

export const createRolSuccess = () => (dispatch) => {
  dispatch({ type: types.NEW_ROL_SUCCESS });
  NotificationManager.success('El rol ha sido creado correctamente.');
};

export const receiveRoles = (roles: RolSchema.Rol[]) => (dispatch) => {
  dispatch({ type: types.RECEIVE_ROLES, roles: roles });
};

export const requestRoles = () => (dispatch) => {
  dispatch({ type: types.REQUEST_ROLES });
  getRoles().then(roles => {
    dispatch(receiveRoles(roles));
  });
};

export const createRolFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.NEW_ROL_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear el rol.');
};

export const createRolAction = (request: NewRolSchema.NewRol) => (dispatch) => {
  dispatch({ type: types.NEW_ROL });
  dispatch(startLoading());
  createRol(request).then(() => {
    dispatch(CloseAddModal());
    dispatch(createRolSuccess());
    dispatch(requestRoles());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(createRolFailed(e.message));
    dispatch(endLoading());
  });
};

export const closeEditModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_EDIT_MODAL });
};

export const editRolSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_ROL_SUCCESS });
  NotificationManager.success('El rol ha sido editado correctamente.');
};

export const editRolFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_ROL_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al editar el rol.');
};

export const editRolAction = (id: number, request: NewRolSchema.NewRol) => (dispatch) => {
  dispatch({ type: types.EDIT_ROL });
  dispatch(startLoading());
  editRol(id, request).then(() => {
    dispatch(closeEditModal());
    dispatch(editRolSuccess());
    dispatch(requestRoles());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(editRolFailed(e.message));
    dispatch(endLoading());
  });
};

export const openAddModal = () => (dispatch) => {
  dispatch({ type: types.OPEN_ADD_MODAL });
};

export const CloseAddModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_ADD_MODAL });
};

export const openEditModal = (rol: RolSchema.Rol) => (dispatch) => {
  dispatch({ type: types.OPEN_EDIT_MODAL, rol: rol });
};

export const receiveActions = (actions: string[]) => (dispatch) => {
  actions.unshift('*');
  return dispatch({ type: types.RECEIVE_ACTIONS, actions: actions });
};

export const requestActions = () => (dispatch) => {
  dispatch({ type: types.REQUEST_ACTIONS });
  getActions().then((actions) => dispatch(receiveActions(actions)));
};

export const addUserToRol = (email: string) => (dispatch) => {
  dispatch({ type: types.ADD_USER_TO_ROL, user: email });
};

export const removeUserToRol = (emails: string[]) => (dispatch) => {
  dispatch({ type: types.REMOVE_USER_TO_ROL, users: emails });
};
