import { ListItem } from 'crypthum-sdk/dist/schemas/ListItem';
import { ItemSchema } from 'crypthum-sdk/src';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Item {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  item: StateModel<ItemSchema.Item>;
  itemList: StateModel<ListItem>;
}

export function getInitialState(): Item {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    item: EmptyStateModel,
    itemList: EmptyStateModel,
  };
}
