import { ListPresale } from 'crypthum-sdk/dist/schemas/ListPresale';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Presale {
  create: StateModel<void>;
  presaleList: StateModel<ListPresale>;
  endedPresaleList: StateModel<ListPresale>;
}

export function getInitialState(): Presale {
  return {
    create: EmptyStateModel,
    presaleList: EmptyStateModel,
    endedPresaleList: EmptyStateModel
  };
}
