import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'status',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a status option'
      }
    ]
  },
  {
    name: 'categoryName',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Category name cannot be empty'
      }
    ]
  }
]);
