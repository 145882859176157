import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import '../../css/error-pages.css';
import { Link } from 'react-router-dom';

class NotFound extends React.Component {

  render() {
    return (
      <section id="wrapper" className="error-page">
        <div className="error-box">
          <div className="error-body text-center">
            <h1 className="text-info">404</h1>
            <h3 className="text-uppercase"><FormattedMessage id="404.page_not_found" /></h3>
            <p className="text-muted m-t-30 m-b-30"><FormattedMessage id="404.subtitle" /></p>
            <Link to="/" className="btn btn-info btn-rounded waves-effect waves-light m-b-40">
              <FormattedMessage id="404.back" />
            </Link>
          </div>
          <footer className="footer text-center">
            <FormattedMessage id="404.copiright" />
          </footer>
        </div>
      </section>
    )
  }
}

export default NotFound