import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestGraph } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Transaction } from '../../redux/state/transactions';

interface DispatchProps {
  getGraph: (start: string, end: string) => void;
}

interface GraphTransactionProps extends Transaction, DispatchProps {}

class GraphTransactions extends React.Component<GraphTransactionProps> {
  constructor(public readonly props: GraphTransactionProps) {
    super(props);
    this.chartReference = React.createRef();
  }

  chartReference;
  chartType;
  bars = {};
  chartData = {
    labels: [],
    data: [],
  };

  componentDidMount() {
    this.getLastWeekChart();
  }

  componentDidUpdate() {
    if (this.chartReference.current && this.chartReference.current.chartInstance) {
      this.chartReference.current.chartInstance.update();
    }
  }

  private isSameDay(firstDay: Date, secondDay: Date) {
    return (
      firstDay.getDate() === secondDay.getDate() &&
      firstDay.getMonth() === secondDay.getMonth() &&
      firstDay.getFullYear() === secondDay.getFullYear()
    );
  }

  private get24hChart() {
    this.props.getGraph(
      new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
      new Date().toISOString()
    );
    this.chartType = '24H';
  }

  private getLastWeekChart() {
    this.props.getGraph(
      new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
      new Date().toISOString()
    );
    this.chartType = '1W';
  }

  private getLastMonthChart() {
    this.props.getGraph(
      new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
      new Date().toISOString()
    );
    this.chartType = '1M';
  }

  private getLastYearChart() {
    this.props.getGraph(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString(),
      new Date().toISOString()
    );
    this.chartType = '1Y';
  }

  private getChartData(period: string) {
    let timePeriod: Date;

    switch (period) {
      case '24H':
        timePeriod = new Date(new Date().setDate(new Date().getDate() - 1));
        break;
      case '1W':
        timePeriod = new Date(new Date().setDate(new Date().getDate() - 7));
        break;
      case '1M':
        timePeriod = new Date(new Date().setMonth(new Date().getMonth() - 1));
        break;
      case '1Y':
        timePeriod = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        break;
      default:
        break;
    }

    const days = { labels: [], data: [] };

    if (this.props.graphsList.data) {
      let totalAmount = this.props.graphsList.data.balance;

      const userId = localStorage.getItem('userId');
      let dayCounter = new Date();

      while (timePeriod <= dayCounter) {
        let amount: number = totalAmount;

        this.props.graphsList.data.data.forEach((element) => {
          const elementDate = new Date(
            new Date(element.created).getFullYear(),
            new Date(element.created).getMonth(),
            new Date(element.created).getDate()
          );

          if (
            this.isSameDay(elementDate, dayCounter) &&
            element.senderId !== null &&
            element.senderId.id === parseInt(userId, undefined)
          ) {
            amount = totalAmount + element.amount;
          } else if (
            this.isSameDay(elementDate, dayCounter) &&
            element.receiverId !== null &&
            element.receiverId.id === parseInt(userId, undefined)
          ) {
            amount = totalAmount - element.amount;
          }
        });

        totalAmount = amount;

        days.labels.push(
          dayCounter.getFullYear() + '/' + (dayCounter.getMonth() + 1) + '/' + dayCounter.getDate()
        );
        days.data.push(amount);

        dayCounter = new Date(dayCounter.setDate(dayCounter.getDate() - 1));
      }
    }

    return {
      labels: days.labels,
      data: days.data,
    };
  }

  render() {
    if (this.chartType) {
      this.chartData = this.getChartData(this.chartType);

      this.bars = {
        labels: this.chartData.labels.reverse(),
        datasets: [
          {
            label: 'Price',
            data: this.chartData.data.reverse(),
            backgroundColor: 'rgba(38, 194, 129, 0)',
            borderColor: 'rgb(79, 99, 255)',
            pointBackgroundColor: 'rgb(79, 99, 255)',
            borderWidth: 1,
          },
        ],
        options: {
          legend: {
            display: false,
          },
          display: false,
          scales: {
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Graphs</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Graphs</li>
              <li className='breadcrumb-item active'>List graphs</li>
            </ol>
          </div>
        </div>
        <div className='row graph-buttons'>
          <button
            className='btn btn-inverse'
            onClick={() => {
              this.get24hChart();
            }}
          >
            Last Day
          </button>
          <button
            className='btn btn-inverse'
            onClick={() => {
              this.getLastWeekChart();
            }}
          >
            Last Week
          </button>
          <button
            className='btn btn-inverse'
            onClick={() => {
              this.getLastMonthChart();
            }}
          >
            Last Month
          </button>
          <button
            className='btn btn-inverse'
            onClick={() => {
              this.getLastYearChart();
            }}
          >
            Last Year
          </button>
        </div>
        <div className='row'>{<Line ref={this.chartReference} data={this.bars} />}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getGraph: (start: string, end: string) => dispatch(requestGraph(start, end)),
  };
};

export default connect<Transaction, DispatchProps>(
  (store: StoreState) => store.transaction,
  mapDispatchToProps
)(GraphTransactions);
