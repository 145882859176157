import { PlayerSchema } from 'crypthum-sdk';
import { ListPlayer } from 'crypthum-sdk/dist/schemas/ListPlayer';

import { PlayerRate } from 'crypthum-sdk/dist/schemas/PlayerRate';
import { EmptyStateModel, StateModel } from './types/state.model';

export interface Player {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  player: StateModel<PlayerSchema.Player>;
  playerList: StateModel<ListPlayer>;
  rate: StateModel<PlayerRate>;
}

export function getInitialState(): Player {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    player: EmptyStateModel,
    playerList: EmptyStateModel,
    rate: EmptyStateModel,
  };
}
