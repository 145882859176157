import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import MUIDataTable from 'mui-datatables';

import {
    requestPlayerRate,
} from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Player } from '../../redux/state/players';
import {dataTableOptions} from '../ui/DataTableOptions';
import { gql } from 'apollo-boost';
import {Query, Mutation, useMutation} from 'react-apollo';

const CREATE_LICENSE = gql`
    mutation (
        $name: String!
        $surname: String!
        $email: String!
        $phone: String
        $document: String!
        $birthDate: Date!
    ){
        createLicense(
            name: $name
            surname: $surname
            email: $email
            phone: $phone
            document: $document
            birthDate: $birthDate
        )
    }
`;

const UPDATE_LICENSE = gql`
    mutation (
        $id: String!
        $name: String
        $surname: String
        $email: String
        $phone: String
        $document: String
        $birthDate: Date
        $hasPayed: Boolean
    ){
        updateLicense(
            input: {
                id: $id
                name: $name
                surname: $surname
                email: $email
                phone: $phone
                document: $document
                birthDate: $birthDate
                hasPayed: $hasPayed
            }
        ) {
            id
            name
            surname
            email
            phone
            document
            birthDate
            hasPayed
        }
    }
`;

const LicensesQuery = () => {
    return (
        <Query
            query={gql`
                query {
                    licenses {
                        id
                        name
                        surname
                        email
                        phone
                        document
                        birthDate
                        hasPayed
                        code
                    }
                }
            `}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <p>Loading...</p>;
                }

                const fixedData: any = [];
                for (const element of data.licenses) {

                    let fixedBirthDate;
                    const date = new Date(parseInt(element.birthDate, 10));

                    const day = date.getDate();
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();

                    if (month < 10) {
                        fixedBirthDate = `${day}/0${month}/${year}`;
                    } else {
                        fixedBirthDate = `${day}/${month}/${year}`;
                    }

                    let fixedPhone = element.phone;
                    if (fixedPhone === 'undefined') {
                        fixedPhone = '';
                    }

                    const buttonDisabled = element.hasPayed;
                    let buttonStyle;
                    let buttonContent;

                    if (buttonDisabled) {
                        buttonContent = <span><i className='fa fa-check' /> <FormattedMessage id='users.licenses.paid' /></span>;
                        buttonStyle = {
                            width: '200px',
                            cursor: 'not-allowed',
                        };
                    } else {
                        buttonContent = <FormattedMessage id='users.licenses.mark_as_paid' />;
                        buttonStyle = {
                            width: '200px',
                        };
                    }

                    fixedData.push([
                        element.name + ' ' + element.surname,
                        element.email,
                        fixedPhone,
                        element.document,
                        fixedBirthDate,
                        element.code,
                        <div>
                            <Mutation mutation={UPDATE_LICENSE}>
                                {(mutation, { data }) => (
                                    <button
                                        onClick={e => {
                                            e.preventDefault();
                                            mutation({
                                                variables: {
                                                    id: element.id,
                                                    hasPayed: true,
                                                }
                                            });
                                        }}
                                        style={buttonStyle}
                                        className='btn btn-outline-dark waves-effect waves-light'
                                        type='button'
                                        disabled={buttonDisabled}
                                    >
                                        {buttonContent}
                                    </button>
                                )}
                            </Mutation>

                        </div>
                    ]);
                }

                const columns = [
                    'Nombre',
                    'Correo',
                    'Teléfono',
                    'DNI',
                    'Fecha de nacimiento',
                    'Code',
                    'Botón',
                ];

                const options = {
                    ...dataTableOptions,
                };

                if (error) {

                    return <div className='card card-inverse card-danger'>
                        <div className='card-body'>
                            <h3 className='card-title'>Error fetching data</h3>
                            <p className='card-text'>There was an error downloading the data. Please, try again in a few minutes.</p>
                        </div>
                    </div>;

                } else {
                    return <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h4 className='card-title'><FormattedMessage id='users.licenses_table.title'/></h4>
                                    <h6 className='card-subtitle'><FormattedMessage id='users.licenses_table.subtitle'/></h6>
                                    <div className='table-responsive'>
                                        <MUIDataTable
                                            data={fixedData}
                                            columns={columns}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>;
                }
            }}
        </Query>
    );
};




interface DispatchProps {
    getRate: (instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string) => void;
}
//
// interface AllRateProps extends Player, DispatchProps { }

class Licenses extends React.Component {

    render() {

        return (
            <div className='container-fluid'>
                <div className='row page-titles'>
                    <div className='col-md-12 align-self-center'>
                        <h3 className='text-themecolor'><FormattedMessage id='users.users' /></h3>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><FormattedMessage id='users.users' /></li>
                            <li className='breadcrumb-item active'><FormattedMessage id='users.licenses' /></li>
                        </ol>
                    </div>
                </div>
                <LicensesQuery />
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
    return {
        getRate: (
            instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string
        ) => dispatch(requestPlayerRate(instagram, clubInstagrams, sofifaUrl, transfermarktUrl)),
    };
};

export default connect<Player, DispatchProps>(
    (store: StoreState) => store.player,
    mapDispatchToProps,
)(Licenses);
