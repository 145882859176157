import MUIDataTable from "mui-datatables";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { gql } from "apollo-boost";
import { Mutation, Query } from "react-apollo";
import { StoreState } from "../../redux/state";
import { Player } from "../../redux/state/players";
import { dataTableOptions } from "../ui/DataTableOptions";

const MARK_AS_DONE = gql`
  mutation($id: String!) {
    markShirtAsDone(shirtId: $id) {
      id
    }
  }
`;

const ShirtsQuery = () => {
  return (
    <Query
      query={gql`
        query {
          shirts {
            id
            code
            name
            email
            imageURL
            videoURL
            isDone
            created
            foundation
            instagram
            description
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <p>Loading...</p>;
        }

        const fixedData: any = [];
        for (const element of data.shirts) {
          let buttonDisabled = element.isDone;
          let buttonStyle;
          let buttonContent;

          if (buttonDisabled) {
            buttonContent = (
              <span>
                <i className="fa fa-check" /> Hecho
              </span>
            );
            buttonStyle = {
              width: "200px",
              cursor: "not-allowed",
            };
          } else {
            buttonContent = "Marcar como hecho";
            buttonStyle = {
              width: "200px",
            };
          }

          fixedData.push([
            element.code,
            element.name,
            element.email,
            element.foundation,
            <a href={element.imageURL} target="BLANK">
              <button className="btn btn-success waves-effect waves-light" type="button">
                <i className="fa fa-download" />
              </button>
            </a>,
            <a href={element.videoURL} target="BLANK">
              <button className="btn btn-success waves-effect waves-light" type="button">
                <i className="fa fa-download" />
              </button>
            </a>,
            element.instagram,
            element.description,
            <div>
              <Mutation mutation={MARK_AS_DONE}>
                {(mutation, { data }) => (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      mutation({
                        variables: {
                          id: element.id,
                        },
                      });
                      buttonDisabled = true;
                      buttonContent = (
                        <span>
                          <i className="fa fa-check" /> Hecho
                        </span>
                      );
                    }}
                    style={buttonStyle}
                    className="btn btn-outline-dark waves-effect waves-light"
                    type="button"
                    disabled={buttonDisabled}
                  >
                    {buttonContent}
                  </button>
                )}
              </Mutation>
            </div>,
          ]);
        }

        const columns = [
          "Código",
          "Nombre",
          "Email",
          "Fundación",
          "Foto",
          "Video",
          "Instagram",
          "Match",
          "Terminado",
        ];

        const options = {
          ...dataTableOptions,
          sort: true,
          filter: true,
          responsive: 'scrollFullHeightFullWidth'
        };

        if (error) {
          return (
            <div className="card card-inverse card-danger">
              <div className="card-body">
                <h3 className="card-title">Error fetching data</h3>
                <p className="card-text">
                  There was an error downloading the data. Please, try again in a few minutes.
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      <FormattedMessage id="sidebar.shirts" />
                    </h4>
                    <h6 className="card-subtitle">
                      <FormattedMessage id="users.licenses_table.subtitle" />
                    </h6>
                    <div className="table-responsive">
                      <MUIDataTable data={fixedData} columns={columns} options={options} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }}
    </Query>
  );
};

class Shirts extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col-md-12 align-self-center">
            <h3 className="text-themecolor">
              <FormattedMessage id="sidebar.shirts" />
            </h3>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <FormattedMessage id="users.users" />
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="sidebar.shirts" />
              </li>
            </ol>
          </div>
        </div>
        <ShirtsQuery />
      </div>
    );
  }
}

export default connect<Player, {}>(
  (store: StoreState) => store.player,
  {}
)(Shirts);
