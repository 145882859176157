import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { NewPlayer } from 'crypthum-sdk/dist/schemas/NewPlayer';

import { SDKProvider } from '../components/providers/SDKProvider';
import PlayerForm from '../entities/PlayerForm';

const getValueFromReactTags = (tags: { id: string; text: string }[]) => tags.map((tag) => tag.text).join(',');

export class ValidationException extends Error {
  constructor(message: string) {
    super(message);
  }
}

export const getNewPlayerAndUploadMedia = async (playerForm: PlayerForm) => {
  const cryptoMuseumsSDK = SDKProvider.get();

  if (playerForm.birthDate === null) {
    throw new ValidationException('birthDate_cant_be_null');
  }
  if (playerForm.laterality === '') {
    throw new ValidationException('laterality_cant_be_empty');
  }
  if (playerForm.contractUntil === null) {
    throw new ValidationException('contractUntil_cant_be_null');
  }
  if (playerForm.lastRenovation === null) {
    throw new ValidationException('lastRenovation_cant_be_null');
  }

  let shirtMedia;
  if (playerForm.shirtMedia) {
    const file: File = playerForm.shirtMedia.file!;
    shirtMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }

  let backgroundMedia;
  if (playerForm.backgroundMedia) {
    const file: File = playerForm.backgroundMedia.file!;
    backgroundMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }

  let emblemMedia;
  if (playerForm.emblemMedia) {
    const file: File = playerForm.emblemMedia.file!;
    emblemMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }

  let playerIntroMedia;
  if (playerForm.playerIntroMedia) {
    const file: File = playerForm.playerIntroMedia.file!;
    playerIntroMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }

  let playerIdleMedia;
  if (playerForm.playerIdleMedia) {
    const file: File = playerForm.playerIdleMedia.file!;
    playerIdleMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }

  return new NewPlayer(
    playerForm.fullName,
    getValueFromReactTags(playerForm.hashtags),
    playerForm.birthDate.toISOString(),
    playerForm.birthPlace,
    playerForm.nationality,
    playerForm.position,
    playerForm.laterality,
    playerForm.actualClub,
    playerForm.contractUntil.toISOString(),
    playerForm.lastRenovation.toISOString(),
    getValueFromReactTags(playerForm.clubHistory),
    playerForm.instragram,
    playerForm.twitter,
    playerForm.youtube,
    playerForm.facebook,
    playerForm.linkedin,
    shirtMedia,
    backgroundMedia,
    emblemMedia,
    playerIntroMedia,
    playerIdleMedia,
    shirtMedia,
    playerForm.clubId,
    playerForm.type,
    playerForm.additionalInformation
  );
};
