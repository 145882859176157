export const REQUEST_NEWS = 'REQUEST_NEWS';
export type REQUEST_NEWS = typeof REQUEST_NEWS;

export const REQUEST_NEWS_FAILED = 'REQUEST_NEWS_FAILED';
export type REQUEST_NEWS_FAILED = typeof REQUEST_NEWS_FAILED;

export const REQUEST_NEWS_SUCCESS = 'REQUEST_NEWS_SUCCESS';
export type REQUEST_NEWS_SUCCESS = typeof REQUEST_NEWS_SUCCESS;

export const RECEIVE_NEWS = 'RECEIVE_NEWS';
export type RECEIVE_NEWS = typeof RECEIVE_NEWS;

export const CREATE_NEWS = 'CREATE_NEWS';
export type CREATE_NEWS = typeof CREATE_NEWS;

export const CREATE_NEWS_FAILED = 'CREATE_NEWS_FAILED';
export type CREATE_NEWS_FAILED = typeof CREATE_NEWS_FAILED;

export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export type CREATE_NEWS_SUCCESS = typeof CREATE_NEWS_SUCCESS;

export const EDIT_NEWS = 'EDIT_NEWS';
export type EDIT_NEWS = typeof EDIT_NEWS;

export const EDIT_NEWS_FAILED = 'EDIT_NEWS_FAILED';
export type EDIT_NEWS_FAILED = typeof EDIT_NEWS_FAILED;

export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export type EDIT_NEWS_SUCCESS = typeof EDIT_NEWS_SUCCESS;

export const REQUEST_NEWS_BY_ID = 'REQUEST_NEWS_BY_ID';
export type REQUEST_NEWS_BY_ID = typeof REQUEST_NEWS_BY_ID;

export const RECEIVE_NEWS_BY_ID_SUCCESS = 'RECEIVE_NEWS_BY_ID_SUCCESS';
export type RECEIVE_NEWS_BY_ID_SUCCESS = typeof RECEIVE_NEWS_BY_ID_SUCCESS;

export const RECEIVE_NEWS_BY_ID_FAILED = 'RECEIVE_NEWS_BY_ID_FAILED';
export type RECEIVE_NEWS_BY_ID_FAILED = typeof RECEIVE_NEWS_BY_ID_FAILED;
