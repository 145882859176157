import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestPlayers } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Player } from '../../redux/state/players';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getPlayers: (limit: number, offset: number, query: string) => void;
}

interface ListPlayersProps extends Player, DispatchProps { }

class ListPlayers extends React.Component<ListPlayersProps> {

  constructor(public readonly props: ListPlayersProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getPlayers(100, 0, '');
  }

  render() {

    const columns = [
      {
        name: 'avatar',
        label: 'Avatar'
      },
      {
        name: 'fullName',
        label: 'Full Name'
      }, {
        name: 'actualClub',
        label: 'Actual Club'
      }, {
        name: 'nationality',
        label: 'Nationality'
      }, {
        name: 'created',
        label: 'Created'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const data: any[] = [];

    if (this.props.playerList.data) {
      for (const player of this.props.playerList.data.data) {
        data.push({
          avatar: <img src={player.user.avatar} className='player-avatar' />,
          fullName: player.fullName,
          actualClub: (player.club) ? player.club.name : player.actualClub,
          nationality: player.nationality,
          created: moment(player.created).format('YYYY-MM-DD HH:mm:ss'),
          options: <Link to={`/player/edit/${player.id}`}>
            <button className='btn btn-success waves-effect waves-light' type='button'>
              <i className='fa fa-pencil-alt'></i>
            </button>
          </Link>
        });
      }
    }

    const count = this.props.playerList.data ? this.props.playerList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      isLoading: this.props.playerList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getPlayers(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      }
    };

    let body: any;

    if (this.props.playerList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching players</h3>
          <p className='card-text'>There was an error downloading the player list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getPlayers(100, 0, ''); }}>Try again</button>
        </div>
      </div>;

    } else {

      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='players.player_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='players.player_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='players.players' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='players.players' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='players.list_players' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/player/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle'></i> <FormattedMessage id='players.create_player' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getPlayers: (limit: number, offset: number, query: string) => dispatch(requestPlayers(limit, offset, query)),
  };
};

export default connect<Player, DispatchProps>(
  (store: StoreState) => store.player,
  mapDispatchToProps,
)(ListPlayers);
