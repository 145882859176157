import { push } from 'connected-react-router';
import { EditFanzone as EditFanzoneEndpoint, GetAllFanzone, NewMomentSchema } from 'crypthum-sdk';
import { FanzoneData } from 'crypthum-sdk/dist/schemas/FanzoneData';
import { NewFanzoneData } from 'crypthum-sdk/dist/schemas/NewFanzoneData';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import * as types from './types';
import { startLoading, endLoading } from './global';
import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { CreateMoment as CreateMomentEndpoint} from 'crypthum-sdk';
import { FormMedia } from '../state/entities/FormMedia';

const cryptoMuseumsSDK = SDKProvider.get();

const getFanzone = async (): Promise<FanzoneData[]> => {
  return await cryptoMuseumsSDK.endpoint<GetAllFanzone>(GetAllFanzone).run();
};

const editFanzone = async (newFanzone: NewFanzoneData[]) => {
  return await cryptoMuseumsSDK.endpoint<EditFanzoneEndpoint>(EditFanzoneEndpoint).run({
    body: newFanzone[0]
  });
};

export interface RequestFanzone {
  type: types.REQUEST_FANZONE;
}

export interface ReceiveFanzoneSuccess {
  type: types.RECEIVE_FANZONE_SUCCESS;
  fanzone: FanzoneData[];
}

export interface ReceiveFanzoneFailed {
  type: types.RECEIVE_FANZONE_FAILED;
  error: string;
}

export interface EditFanzone {
  type: types.EDIT_FANZONE;
}

export interface EditFanzoneFailed {
  type: types.EDIT_FANZONE_FAILED;
  error: string;
}

export interface EditFanzoneSuccess {
  type: types.EDIT_FANZONE_SUCCESS;
}


export interface CreateMoment {
  type: types.CREATE_MOMENT;
}

export interface CreateMomentFailed {
  type: types.CREATE_MOMENT_FAILED;
  error: string;
}

export interface CreateMomentSuccess {
  type: types.CREATE_MOMENT_SUCCESS;
}

export type FanzoneAction =
  CreateMoment |
  CreateMomentFailed |
  CreateMomentSuccess |
  EditFanzone |
  EditFanzoneFailed |
  EditFanzoneSuccess |
  RequestFanzone |
  ReceiveFanzoneSuccess |
  ReceiveFanzoneFailed;


export const receiveFanzoneSuccess = (fanzone: FanzoneData[]) => (dispatch) => {
  dispatch({ type: types.RECEIVE_FANZONE_SUCCESS, fanzone: fanzone });
};

export const receiveFanzoneFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.RECEIVE_FANZONE_FAILED, error: errorKey });
};

export const requestFanzoneAction = () => (dispatch) => {
  dispatch({ type: types.REQUEST_FANZONE });
  dispatch(startLoading());
  getFanzone().then(fanzone => {
    dispatch(receiveFanzoneSuccess(fanzone));
    dispatch(endLoading());
  }).catch(e => {
    dispatch(receiveFanzoneFailed(e));
    dispatch(endLoading());
  });
};

export const editFanzoneSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_FANZONE_SUCCESS });
  dispatch(push('/fanzone/order'));
};

export const editFanzoneFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_FANZONE_FAILED, error: errorKey });
};

export const editFanzoneAction = (request: NewFanzoneData[]) => (dispatch) => {
  dispatch({ type: types.EDIT_FANZONE });
  dispatch(startLoading());
  editFanzone(request).then(() => {
    dispatch(editFanzoneSuccess());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(editFanzoneFailed(e));
    dispatch(endLoading());
  });
};


export const createMomentSuccess = () => (dispatch) => {
  dispatch({ type: types.CREATE_MOMENT_SUCCESS });
  dispatch(push('/fanzone/moment/add'));
  NotificationManager.success('El video ha sido creado correctamente.');
};

export const createMomentFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_MOMENT_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear el video.');
};

const createMoment = async (body: NewMomentSchema.NewMoment) =>
  await cryptoMuseumsSDK.endpoint<CreateMomentEndpoint>(CreateMomentEndpoint).run({
    body: body
  });

export const createMomentAction = (userId: number, media: FormMedia, itemId: number) => async (dispatch) => {

  dispatch({ type: types.CREATE_CLUB });
  dispatch(startLoading());

  if (!media.file) {
    throw new Error('media is null');
  }

  const file: File = media.file;
  const newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;

  createMoment(new NewMomentSchema.NewMoment(
    userId, newMedia, null, itemId,
  )).then(() => {
    dispatch(createMomentSuccess());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(createMomentFailed(e));
    dispatch(endLoading());
  });
};
