export interface StateModel<DataType> {
  data: DataType | null;
  error: string | null;
  isFetching: boolean;
}

export const EmptyStateModel = {
  data: null,
  error: null,
  isFetching: false
};
