import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { WithContext as ReactTags } from 'react-tag-input';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import PlayerForm from '../../entities/PlayerForm';
import { createPlayerAction, editPlayerAction, requestClubs, requestPlayer, requestUsers } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { FormMedia } from '../../redux/state/entities/FormMedia';
import FileDropzone from '../utils/FileDropzone';
import * as InputFormat from '../utils/HTMLInputValuesFormatter';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';
import { NotificationManager } from 'react-notifications';

interface DispatchProps {
  getPlayer: (id: number) => void;
  getClubs: (limit: number, offset: number, query: string) => void;
  createPlayer: (request: PlayerForm) => void;
  editPlayer: (id: number, request: PlayerForm) => void;
  getUsers: (limit: number, offset: number, query: string) => void;
}

interface AddEditPlayerProps extends StoreState, DispatchProps {}

class AddEditPlayers extends React.Component<AddEditPlayerProps, RouteProps> {
  private playerId: number = this.props['match']['params']['id'];

  private fullName: React.RefObject<HTMLInputElement> = React.createRef();
  private birthPlace: React.RefObject<HTMLInputElement> = React.createRef();
  private nationality: React.RefObject<HTMLInputElement> = React.createRef();
  private birthdate: React.RefObject<HTMLInputElement> = React.createRef();
  private actualClub: React.RefObject<Select> = React.createRef();
  private position: React.RefObject<HTMLInputElement> = React.createRef();
  private lastRenovation: React.RefObject<HTMLInputElement> = React.createRef();
  private contractUntil: React.RefObject<HTMLInputElement> = React.createRef();
  private linkedin: React.RefObject<HTMLInputElement> = React.createRef();
  private facebook: React.RefObject<HTMLInputElement> = React.createRef();
  private youtube: React.RefObject<HTMLInputElement> = React.createRef();
  private twitter: React.RefObject<HTMLInputElement> = React.createRef();
  private instragram: React.RefObject<HTMLInputElement> = React.createRef();
  private laterality: React.RefObject<HTMLSelectElement> = React.createRef();
  private type: React.RefObject<HTMLSelectElement> = React.createRef();

  private extraInfoKey: React.RefObject<HTMLInputElement> = React.createRef();
  private extraInfoValue: React.RefObject<HTMLInputElement> = React.createRef();

  private shirtMedia: FormMedia;
  private backgroundMedia: FormMedia;
  private emblemMedia: FormMedia;
  private playerIntroMedia: FormMedia;
  private playerIdleMedia: FormMedia;

  private tags: { id: string; text: string }[] = [];
  private clubHistory: { id: string; text: string }[] = [];
  private additionalInformation: { [key: string]: string } = {};

  private validationService: ValidationService;
  private formSubmitted = false;
  private tableBody = [];

  constructor(public readonly props: AddEditPlayerProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };

    this.props.player.player.data = null;
    this.validationService = addEditValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        fullName: this.validationService.getLastError('fullName'),
        birthPlace: this.validationService.getLastError('birthPlace'),
        nationality: this.validationService.getLastError('nationality'),
        birthdate: this.validationService.getLastError('birthdate'),
        actualClub: this.validationService.getLastError('actualClub'),
        position: this.validationService.getLastError('position'),
        lastRenovation: this.validationService.getLastError('lastRenovation'),
        contractUntil: this.validationService.getLastError('contractUntil'),
        linkedin: this.validationService.getLastError('linkedin'),
        facebook: this.validationService.getLastError('facebook'),
        youtube: this.validationService.getLastError('youtube'),
        twitter: this.validationService.getLastError('twitter'),
        instragram: this.validationService.getLastError('instragram'),
        laterality: this.validationService.getLastError('laterality'),
        shirtMedia: this.validationService.getLastError('shirtMedia'),
        backgroundMedia: this.validationService.getLastError('backgroundMedia'),
        emblemMedia: this.validationService.getLastError('emblemMedia'),
        playerIntroMedia: this.validationService.getLastError('playerIntroMedia'),
        playerIdleMedia: this.validationService.getLastError('playerIdleMedia'),
        type: this.validationService.getLastError('type'),
      },
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  getFileDropzonePlayerData(type: string) {
    if (this.props.player.player.data && !(type in this.state.fields)) {
      return this.props.player.player.data[type].mediaStorage;
    } else if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  submit(e) {
    this.formSubmitted = true;

    e.preventDefault();

    if (this.tags.length === 0) {
      NotificationManager.error('El campo "hashtags" no puede estar vacío');
      return;
    }

    if (this.clubHistory.length === 0) {
      NotificationManager.error('El campo "club history" no puede estar vacío');
      return;
    }

    this.validationService.updateValue('fullName', GetReactRef.asString(this.fullName));
    this.validationService.updateValue('birthPlace', GetReactRef.asString(this.birthPlace));
    this.validationService.updateValue('nationality', GetReactRef.asString(this.nationality));
    this.validationService.updateValue('birthdate', GetReactRef.asString(this.birthdate));
    this.validationService.updateValue(
      'actualClub',
      this.actualClub.current.state.value ? this.actualClub.current.state.value.value : null
    );
    this.validationService.updateValue('position', GetReactRef.asString(this.position));
    this.validationService.updateValue('lastRenovation', GetReactRef.asString(this.lastRenovation));
    this.validationService.updateValue('contractUntil', GetReactRef.asString(this.contractUntil));
    this.validationService.updateValue('linkedin', GetReactRef.asString(this.linkedin));
    this.validationService.updateValue('facebook', GetReactRef.asString(this.facebook));
    this.validationService.updateValue('youtube', GetReactRef.asString(this.youtube));
    this.validationService.updateValue('twitter', GetReactRef.asString(this.twitter));
    this.validationService.updateValue('instragram', GetReactRef.asString(this.instragram));
    this.validationService.updateValue('laterality', GetReactRef.asLaterality(this.laterality));
    this.validationService.updateValue('type', GetReactRef.asType(this.type));
    this.validationService.updateValue('shirtMedia', this.getFileDropzonePlayerData('shirtMedia'));
    this.validationService.updateValue('backgroundMedia', this.getFileDropzonePlayerData('backgroundMedia'));
    this.validationService.updateValue('emblemMedia', this.getFileDropzonePlayerData('emblemMedia'));
    this.validationService.updateValue(
      'playerIntroMedia',
      this.getFileDropzonePlayerData('playerIntroMedia')
    );
    this.validationService.updateValue('playerIdleMedia', this.getFileDropzonePlayerData('playerIdleMedia'));

    if (this.handleValidation()) {
      this.sendPlayerForm();
    }
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleSelectChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  private isEdditing(): boolean {
    return this.playerId !== undefined;
  }

  inputHandleChange = (inputText: string) => {
    this.props.getUsers(10, 0, inputText);
  }

  getUsersOptions = () =>
    this.props.user.userList.data
      ? this.props.user.userList.data.data.map((val) => ({
          value: val.id,
          label: val.name + ' ' + val.surname,
        }))
      : []

  getClubsOptions = () =>
    this.props.club.clubList.data
      ? this.props.club.clubList.data.data.map((val) => ({ value: val.id, label: val.name }))
      : []

  private sendPlayerForm() {

    console.log(this.additionalInformation);

    const playerForm = new PlayerForm(
      GetReactRef.asString(this.fullName),
      this.tags,
      GetReactRef.asDate(this.birthdate),
      GetReactRef.asString(this.birthPlace),
      GetReactRef.asString(this.nationality),
      GetReactRef.asString(this.position),
      GetReactRef.asLaterality(this.laterality),
      null,
      GetReactRef.asDate(this.contractUntil),
      GetReactRef.asDate(this.lastRenovation),
      this.clubHistory,
      GetReactRef.asString(this.instragram),
      GetReactRef.asString(this.twitter),
      GetReactRef.asString(this.youtube),
      GetReactRef.asString(this.facebook),
      GetReactRef.asString(this.linkedin),
      this.shirtMedia,
      this.backgroundMedia,
      this.emblemMedia,
      this.playerIntroMedia,
      this.playerIdleMedia,
      this.shirtMedia,
      this.actualClub.current.state.value.value,
      GetReactRef.asType(this.type),
      JSON.stringify(this.additionalInformation)
    );

    if (this.isEdditing()) {
      this.props.editPlayer(this.playerId, playerForm);
    } else {
      this.props.createPlayer(playerForm);
    }
  }

  inputHandleChangeClubs = (inputText: string) => {
    this.props.getClubs(10, 0, inputText);
  }

  handleAdditionHashtag(tag: { id: string; text: string }) {
    this.tags.push(tag);
  }

  handleDeleteHashtag(tagIndex: number) {
    this.tags.splice(tagIndex, 1);
  }

  handleAdditionClubHistory(tagName: string) {
    this.clubHistory.push({ id: tagName, text: tagName });
  }

  handleDeleteClubHistory(tagIndex: number) {
    this.clubHistory.splice(tagIndex, 1);
  }

  addToData() {
    if (GetReactRef.asString(this.extraInfoKey) && GetReactRef.asString(this.extraInfoValue)) {
      this.tableBody = [
        <tr key={GetReactRef.asString(this.extraInfoKey)}>
          <td>{GetReactRef.asString(this.extraInfoKey)}</td>
          <td>{GetReactRef.asString(this.extraInfoValue)}</td>
          <td>
            <button className='btn btn-info' disabled>
              Delete
            </button>
          </td>
        </tr>,
        ...this.tableBody,
      ];

      this.additionalInformation[GetReactRef.asString(this.extraInfoKey)] = GetReactRef.asString(
        this.extraInfoValue
      );

      this.extraInfoKey.current.value = null;
      this.extraInfoValue.current.value = null;

      this.setState({ state: this.state });

      console.log(this.additionalInformation);
    }
  }

  componentDidMount() {
    if (this.isEdditing()) {
      this.props.getPlayer(this.playerId);
      this.props.getClubs(10, 0, '');
    }

    this.props.getUsers(10, 0, '');
    this.props.getClubs(10, 0, '');
  }

  render() {
    if (this.props.player.player.isFetching) {
      return (
        <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
          <span className='sr-only'>...</span>
        </div>
      );
    }

    const hasPlayerData =
      this.props.player.player.data != null && this.props.player.player.data.id == this.playerId;

    if (
      !this.props.player.player.isFetching &&
      this.playerId &&
      (this.props.player.player.data == null || this.props.player.player.data.id != this.playerId)
    ) {
      this.props.getPlayer(this.playerId);
    }

    if (hasPlayerData && this.tags.length === 0) {
      if (this.props.player.player.data.hashtags != null) {
        this.tags = this.props.player.player.data.hashtags
          .split(',')
          .map((value) => ({ id: value, text: value }));
      }
    }

    if (hasPlayerData && this.clubHistory.length === 0) {
      if (this.props.player.player.data.clubHistory != null) {
        this.clubHistory = this.props.player.player.data.clubHistory
          .split(',')
          .map((value) => ({ id: value, text: value }));
      }
    }

    if (hasPlayerData && Object.keys(this.additionalInformation).length === 0) {
      if (this.props.player.player.data.additionalInformation != null) {

        console.log(this.additionalInformation);

        this.additionalInformation = JSON.parse(this.props.player.player.data.additionalInformation);

        console.log(this.additionalInformation);

        Object.entries(this.additionalInformation).forEach(([key, value]) => {
          this.tableBody = [
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
              <td>
                <button className='btn btn-info' disabled>
                  Delete
                </button>
              </td>
            </tr>,
            ...this.tableBody,
          ];
        });
      }
    }

    let selectClub = null;

    if (this.props.player.player.data && this.isEdditing()) {
      selectClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.actualClub}
          defaultValue={(this.props.player.player.data.club) ? {
            label: this.props.player.player.data.club.name,
            value: this.props.player.player.data.club.id,
          } : this.props.player.player.data.actualClub }
          style={{ width: '100%' }}
          options={this.getClubsOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    } else if (!this.isEdditing() || (this.isEdditing() && !this.props.player.player.data)) {
      selectClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.actualClub}
          style={{ width: '100%' }}
          options={this.getClubsOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='players.players' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='players.players' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id={this.isEdditing() ? 'players.edit_player' : 'players.create_player'} />
              </li>
            </ol>
          </div>
        </div>

        <div className='card'>
          <div className='card-body'>
            <form onSubmit={this.submit.bind(this)}>
              <div className='form-body'>
                <fieldset>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <h3 className='box-title'>Personal</h3>
                      <hr />
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['fullName'] ? ' error' : '')}>
                            <label className='control-label'>Full name</label>
                            <input
                              onChange={this.handleChange.bind(this, 'fullName')}
                              value={this.state.fields['fullName']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.fullName : ''
                              }
                              ref={this.fullName}
                              type='text'
                              className='form-control'
                            />
                            <span className='error-message'>{this.state.errors['fullName']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['birthPlace'] ? ' error' : '')}>
                            <label className='control-label'>Place of birth</label>
                            <input
                              onChange={this.handleChange.bind(this, 'birthPlace')}
                              value={this.state.fields['birthPlace']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.birthplace : ''
                              }
                              type='text'
                              className='form-control'
                              ref={this.birthPlace}
                            />
                            <span className='error-message'>{this.state.errors['birthPlace']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['nationality'] ? ' error' : '')}>
                            <label className='control-label'>Nationality</label>
                            <input
                              onChange={this.handleChange.bind(this, 'nationality')}
                              value={this.state.fields['nationality']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.nationality : ''
                              }
                              type='text'
                              className='form-control'
                              ref={this.nationality}
                            />
                            <span className='error-message'>{this.state.errors['nationality']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['birthdate'] ? ' error' : '')}>
                            <label className='control-label'>Date of birth</label>
                            <input
                              onChange={this.handleChange.bind(this, 'birthdate')}
                              value={this.state.fields['birthdate']}
                              defaultValue={
                                this.props.player.player.data
                                  ? InputFormat.getDate(new Date(this.props.player.player.data.birthdate))
                                  : ''
                              }
                              ref={this.birthdate}
                              type='date'
                              className='form-control'
                              placeholder='YYYY-MM-DD'
                            />
                            <span className='error-message'>{this.state.errors['birthdate']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['laterality'] ? ' error' : '')}>
                            <label className='control-label'>Laterality</label>
                            <select
                              onChange={this.handleChange.bind(this, 'laterality')}
                              value={this.state.fields['laterality']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.laterality : ''
                              }
                              ref={this.laterality}
                              className='form-control custom-select'
                            >
                              <option value='' disabled>
                                -- Select type --
                              </option>
                              <option value='LEFT'>Left</option>
                              <option value='RIGHT'>Right</option>
                              <option value='BOTH'>Both</option>
                            </select>
                            <span className='error-message'>{this.state.errors['laterality']}</span>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label className='control-label'>Hashtags</label>
                            <span className='error-message'>{this.state.errors['hashtags']}</span>
                            <ReactTags
                              inputFieldPosition='inline'
                              handleAddition={(tag: { id: string; text: string }) =>
                                this.handleAdditionHashtag(tag)
                              }
                              handleDelete={(tagIndex: number) => this.handleDeleteHashtag(tagIndex)}
                              allowDragDrop={false}
                              tags={this.tags}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-6'>
                      <h3 className='box-title'>Club</h3>
                      <hr />
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['actualClub'] ? ' error' : '')}>
                            <label className='control-label'>Actual club</label>
                            {selectClub}
                            <span className='error-message'>{this.state.errors['actualClub']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['type'] ? ' error' : '')}>
                            <label className='control-label'>Type</label>
                            <select
                              onChange={this.handleChange.bind(this, 'type')}
                              value={this.state.fields['type']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.type : ''
                              }
                              ref={this.type}
                              className='form-control custom-select'
                            >
                              <option value='' disabled>
                                -- Select type --
                              </option>
                              <option value='PLAYER'>Player</option>
                              <option value='CLUB'>Club</option>
                              <option value='OTHER'>Other</option>
                            </select>
                            <span className='error-message'>{this.state.errors['type']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['position'] ? ' error' : '')}>
                            <label className='control-label'>Position</label>
                            <input
                              onChange={this.handleChange.bind(this, 'position')}
                              value={this.state.fields['position']}
                              defaultValue={
                                this.props.player.player.data ? this.props.player.player.data.position : ''
                              }
                              ref={this.position}
                              type='text'
                              className='form-control'
                            />
                            <span className='error-message'>{this.state.errors['position']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div
                            className={'form-group' + (this.state.errors['lastRenovation'] ? ' error' : '')}
                          >
                            <label className='control-label'>Last renovation</label>
                            <input
                              onChange={this.handleChange.bind(this, 'lastRenovation')}
                              value={this.state.fields['lastRenovation']}
                              defaultValue={
                                this.props.player.player.data
                                  ? InputFormat.getDate(
                                      new Date(this.props.player.player.data.lastRenovation)
                                    )
                                  : ''
                              }
                              ref={this.lastRenovation}
                              type='date'
                              className='form-control'
                              placeholder='YYYY-MM-DD'
                            />
                            <span className='error-message'>{this.state.errors['lastRenovation']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div
                            className={'form-group' + (this.state.errors['contractUntil'] ? ' error' : '')}
                          >
                            <label className='control-label'>Contract until</label>
                            <input
                              onChange={this.handleChange.bind(this, 'contractUntil')}
                              value={this.state.fields['contractUntil']}
                              defaultValue={
                                this.props.player.player.data
                                  ? InputFormat.getDate(new Date(this.props.player.player.data.contractUntil))
                                  : ''
                              }
                              type='date'
                              className='form-control'
                              placeholder='YYYY-MM-DD'
                              ref={this.contractUntil}
                            />
                            <span className='error-message'>{this.state.errors['contractUntil']}</span>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className='form-group'>
                            <label className='control-label'>Club history</label>
                            <ReactTags
                              inputFieldPosition='inline'
                              handleAddition={(tag: { id: string; text: string }) =>
                                this.handleAdditionClubHistory(tag.text)
                              }
                              handleDelete={(tagIndex: number) => this.handleDeleteClubHistory(tagIndex)}
                              allowDragDrop={false}
                              tags={this.clubHistory}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <h3 className='box-title m-t-40'>Additional Data</h3>
                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='table-responsive'>
                            <table className='table table-striped'>
                              <thead>
                                <tr>
                                  <th>Data</th>
                                  <th>Value</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>{this.tableBody}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>Data</label>
                            <input type='text' ref={this.extraInfoKey} className='form-control' />
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label>Value</label>
                            <input type='text' ref={this.extraInfoValue} className='form-control' />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12'>
                          <span
                            onClick={() => {
                              this.addToData();
                            }}
                            className='btn btn-success'
                          >
                            Add Data
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <h3 className='box-title m-t-40'>Social network</h3>
                      <hr />
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['instragram'] ? ' error' : '')}>
                            <label>Instagram</label>
                            <div className='input-group mb-3'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text' id='basic-addon1'>
                                  <i className='ti-instagram' />
                                </span>
                              </div>
                              <input
                                onChange={this.handleChange.bind(this, 'instragram')}
                                value={this.state.fields['instragram']}
                                defaultValue={
                                  this.props.player.player.data ? this.props.player.player.data.instagram : ''
                                }
                                ref={this.instragram}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <span className='error-message'>{this.state.errors['instragram']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['twitter'] ? ' error' : '')}>
                            <label>Twitter</label>
                            <div className='input-group mb-3'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text' id='basic-addon1'>
                                  <i className='ti-twitter' />
                                </span>
                              </div>
                              <input
                                onChange={this.handleChange.bind(this, 'twitter')}
                                value={this.state.fields['twitter']}
                                defaultValue={
                                  this.props.player.player.data ? this.props.player.player.data.twitter : ''
                                }
                                ref={this.twitter}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <span className='error-message'>{this.state.errors['twitter']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['youtube'] ? ' error' : '')}>
                            <label>Youtube</label>
                            <div className='input-group mb-3'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text' id='basic-addon1'>
                                  <i className='ti-youtube' />
                                </span>
                              </div>
                              <input
                                onChange={this.handleChange.bind(this, 'youtube')}
                                value={this.state.fields['youtube']}
                                defaultValue={
                                  this.props.player.player.data ? this.props.player.player.data.youtube : ''
                                }
                                ref={this.youtube}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <span className='error-message'>{this.state.errors['youtube']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['facebook'] ? ' error' : '')}>
                            <label>Facebook</label>
                            <div className='input-group mb-3'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text' id='basic-addon1'>
                                  <i className='ti-facebook' />
                                </span>
                              </div>
                              <input
                                onChange={this.handleChange.bind(this, 'facebook')}
                                value={this.state.fields['facebook']}
                                defaultValue={
                                  this.props.player.player.data ? this.props.player.player.data.facebook : ''
                                }
                                ref={this.facebook}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <span className='error-message'>{this.state.errors['facebook']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['linkedin'] ? ' error' : '')}>
                            <label>Linkedin</label>
                            <div className='input-group mb-3'>
                              <div className='input-group-prepend'>
                                <span className='input-group-text' id='basic-addon1'>
                                  <i className='ti-linkedin' />
                                </span>
                              </div>
                              <input
                                onChange={this.handleChange.bind(this, 'linkedin')}
                                value={this.state.fields['linkedin']}
                                defaultValue={
                                  this.props.player.player.data ? this.props.player.player.data.linkedin : ''
                                }
                                ref={this.linkedin}
                                type='text'
                                className='form-control'
                              />
                            </div>
                            <span className='error-message'>{this.state.errors['linkedin']}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12'>
                      <h3 className='box-title m-t-40'>Media info</h3>
                      <hr />
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['shirtMedia'] ? ' error' : '')}>
                            <label>Avatar</label>
                            <FileDropzone
                              value={this.state.fields['shirtMedia']}
                              accept='image/*'
                              preview={
                                !('shirtMedia' in this.state.fields) &&
                                this.props.player.player.data &&
                                this.props.player.player.data.shirtMedia
                                  ? this.props.player.player.data.shirtMedia.mediaStorage
                                  : undefined
                              }
                              onFileChange={(file: FormMedia[]) => {
                                this.shirtMedia = file[0];
                                this.handleDropzoneChange('shirtMedia', file[0] ? file[0].previewUrl : '');
                              }}
                            />
                            <span className='error-message'>{this.state.errors['shirtMedia']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div
                            className={'form-group' + (this.state.errors['playerIdleMedia'] ? ' error' : '')}
                          >
                            <label>Player idle</label>
                            <FileDropzone
                              value={this.state.fields['playerIdleMedia']}
                              accept='image/*'
                              preview={
                                !('playerIdleMedia' in this.state.fields) &&
                                this.props.player.player.data &&
                                this.props.player.player.data.playerIdleMedia
                                  ? this.props.player.player.data.playerIdleMedia.mediaStorage
                                  : undefined
                              }
                              onFileChange={(file: FormMedia[]) => {
                                this.playerIdleMedia = file[0];
                                this.backgroundMedia = file[0];
                                this.emblemMedia = file[0];
                                this.playerIntroMedia = file[0];
                                this.handleDropzoneChange(
                                  'playerIdleMedia',
                                  file[0] ? file[0].previewUrl : ''
                                );
                              }}
                            />
                            <span className='error-message'>{this.state.errors['playerIdleMedia']}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className='form-actions text-right'>
                <Link to='/players'>
                  <button type='button' className='btn btn-info m-r-10'>
                    Cancel
                  </button>
                </Link>

                <button
                  disabled={this.props.user.userList.isFetching}
                  className='btn btn-success'
                  type='submit'
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getPlayer: (id: number) => dispatch(requestPlayer(id)),
    getClubs: (limit: number, offset: number, query: string) => dispatch(requestClubs(limit, offset, query)),
    createPlayer: (request: PlayerForm) => dispatch(createPlayerAction(request)),
    editPlayer: (id: number, request: PlayerForm) => dispatch(editPlayerAction(id, request)),
    getUsers: (limit: number, offset: number, query: string) => dispatch(requestUsers(limit, offset, query)),
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps
)(AddEditPlayers);
