import { NewsAction } from '../actions';
import * as NewsActionType from '../actions/types/news';
import { getInitialState, News } from '../state/news';

export const news = (state: News = getInitialState(), action: NewsAction): News => {
  switch (action.type) {
    case NewsActionType.CREATE_NEWS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true
        }
      };
    case NewsActionType.CREATE_NEWS_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false
        }
      };
    case NewsActionType.CREATE_NEWS_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error
        }
      };
    case NewsActionType.EDIT_NEWS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case NewsActionType.EDIT_NEWS_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case NewsActionType.EDIT_NEWS_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case NewsActionType.REQUEST_NEWS:
      return {
        ...state,
        newsList: {
          ...state.newsList,
          isFetching: true
        }
      };
    case NewsActionType.REQUEST_NEWS_FAILED:
      return {
        ...state,
        newsList: {
          ...state.newsList,
          isFetching: false,
          error: action.error
        }
      };
    case NewsActionType.RECEIVE_NEWS:
      return {
        ...state,
        newsList: {
          ...state.newsList,
          isFetching: false,
          data: action.news
        }
      };
    case NewsActionType.REQUEST_NEWS_BY_ID:
      return {
        ...state,
        news: {
          ...state.news,
          isFetching: true
        }
      };
    case NewsActionType.RECEIVE_NEWS_BY_ID_SUCCESS:
      return {
        ...state,
        news: {
          ...state.news,
          isFetching: false,
          data: action.news
        },
      };
    case NewsActionType.RECEIVE_NEWS_BY_ID_FAILED:
      return {
        ...state,
        news: {
          ...state.news,
          isFetching: false,
          error: action.error
        }
      };
    default:
      return state;
  }
}
