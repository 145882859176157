import { push } from 'connected-react-router';
import { GetPresaleList, NewPresale, NewPresaleSchema } from 'crypthum-sdk';
import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { NotificationManager } from 'react-notifications';

import { ListPresale } from 'crypthum-sdk/dist/schemas/ListPresale';
import { NewPack } from 'crypthum-sdk/dist/schemas/NewPack';
import { SDKProvider } from '../../components/providers/SDKProvider';
import { FormMedia } from '../state/entities/FormMedia';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getPresales = async (limit: number, offset: number, query: string): Promise<ListPresale> =>
  await cryptoMuseumsSDK.endpoint<GetPresaleList>(GetPresaleList).run({ ended: 0 });

const getEndedPresales = async (limit: number, offset: number, query: string): Promise<ListPresale> =>
  await cryptoMuseumsSDK.endpoint<GetPresaleList>(GetPresaleList).run({ ended: 1 });

const createPresale = async (body: NewPresaleSchema.NewPresale) =>
  await cryptoMuseumsSDK.endpoint<NewPresale>(NewPresale).run({
    body: body,
  });

export interface CreatePresale {
  type: types.CREATE_PRESALE;
}

export interface CreatePresaleFailed {
  type: types.CREATE_PRESALE_FAILED;
  error: string;
}

export interface CreatePresaleSuccess {
  type: types.CREATE_PRESALE_SUCCESS;
}

export interface RequestPresales {
  type: types.REQUEST_PRESALES;
}

export interface RequestEndedPresales {
  type: types.REQUEST_ENDED_PRESALES;
}

export interface ReceiveEndedPresales {
  type: types.RECEIVE_ENDED_PRESALES;
  endedPresales: ListPresale;
}

export interface RequestEndedPresalesFailed {
  type: types.REQUEST_ENDED_PRESALES_FAILED;
  error: string;
}

export interface RequestPresalesFailed {
  type: types.REQUEST_PRESALES_FAILED;
  error: string;
}

export interface RequestPresalesSuccess {
  type: types.REQUEST_PRESALES_SUCCESS;
}

export interface ReceivePresales {
  type: types.RECEIVE_PRESALES;
  presales: ListPresale;
}

export type PresaleAction =
  | RequestPresales
  | RequestPresalesSuccess
  | RequestPresalesFailed
  | ReceivePresales
  | CreatePresale
  | CreatePresaleFailed
  | CreatePresaleSuccess
  | RequestEndedPresales
  | ReceiveEndedPresales
  | RequestEndedPresalesFailed;

export const receivePresales = (presales: ListPresale) => (dispatch) => {
  dispatch({ type: types.RECEIVE_PRESALES, presales: presales });
};

export const createPresaleSuccess = () => (dispatch) => {
  dispatch(receivePresales(new ListPresale(0, [])));
  dispatch({ type: types.CREATE_PRESALE_SUCCESS });
  dispatch(push('/presales'));
  NotificationManager.success('La presale ha sido creado correctamente.');
};

export const createPresaleFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_PRESALE_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear la presale.');
};

export const createPresaleAction = (
  items: number[],
  price: number,
  quantityLimit: number,
  start: Date,
  end: Date,
  users: number[],
  percentages: number[],
  discounts: number[],
  discountTimes: number[],
  image: FormMedia,
  imageLandscape: FormMedia
) => async (dispatch) => {
  dispatch({ type: types.CREATE_PRESALE });
  dispatch(startLoading());

  if (!image.file) {
    throw new Error('media is null');
  }

  const file: File = image.file;
  const newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;

  if (!imageLandscape.file) {
    throw new Error('media is null');
  }

  const fileLandscape: File = imageLandscape.file;
  const newMediaLandscape = (await cryptoMuseumsSDK.media.upload(
    new CreateMedia(fileLandscape.name, false, fileLandscape)
  )).id;

  createPresale(
    new NewPresaleSchema.NewPresale(
      new NewPack(items),
      'FIXED_WITH_LIMIT',
      price,
      null,
      quantityLimit,
      start.toISOString(),
      end.toISOString(),
      users,
      percentages,
      discounts,
      discountTimes,
      new Map<any, any>([['en', 'foo']]),
      newMedia,
      newMediaLandscape
    )
  )
    .then(() => {
      dispatch(createPresaleSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createPresaleFailed(e));
      dispatch(endLoading());
    });
};

export const requestPresalesFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_PRESALES_FAILED, error: errorKey });
};

export const requestPresales = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_PRESALES });
  getPresales(limit, offset, query)
    .then((sales) => {
      dispatch(receivePresales(sales));
    })
    .catch((e) => {
      dispatch(requestPresalesFailed(e));
    });
};

export const requestPresalesSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_PRESALES_SUCCESS });
};

export const requestEndedPresales = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_ENDED_PRESALES });
  getEndedPresales(limit, offset, query)
    .then((sales) => {
      dispatch(receiveEndedPresales(sales));
    })
    .catch((e) => {
      dispatch(requestEndedPresalesFailed(e));
    });
};

export const receiveEndedPresales = (presales: ListPresale) => (dispatch) => {
  dispatch({ type: types.RECEIVE_ENDED_PRESALES, endedPresales: presales });
};

export const requestEndedPresalesFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_ENDED_PRESALES_FAILED, error: errorKey });
};
