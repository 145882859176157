import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'title',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Title cannot be empty'
      }
    ]
  },
  {
    name: 'content',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Content cannot be empty'
      }
    ]
  },
  {
    name: 'status',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a status option'
      }
    ]
  },
  {
    name: 'newsKeywords',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'News keywords cannot be empty'
      }
    ]
  }
]);
