import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ItemForm from 'src/entities/ItemForm';
import {
  createItemAction,
  editItemAction,
  receiveFoundation,
  receiveItem,
  receiveUserSuccess,
  requestCategories,
  requestCategoriesSuccess,
  requestClubById,
  requestClubs,
  requestFoundation,
  requestFoundations,
  requestItemById,
  requestPlayers,
  requestUser,
} from 'src/redux/actions';
import { StoreState } from 'src/redux/state';
import { FormMedia } from 'src/redux/state/entities/FormMedia';
import { ValidationService } from 'src/services/validation/validation.service';

import { addEditValidations } from '../items/validations/add-edit';
import FileDropzone from '../utils/FileDropzone';
import * as InputFormat from '../utils/HTMLInputValuesFormatter';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';

interface DispatchProps {
  createItem: (request: ItemForm) => void;
  editItem: (id: number, request: ItemForm) => void;
  getUserById: (userId: number) => void;
  getClubById: (clubId: number) => void;
  getFoundationById: (foundationId: number) => void;
  getClubs: (limit: number, offset: number, query: string) => void;
  getUsers: (limit: number, offset: number, query: string) => void;
  getCategories: () => void;
  getFoundations: (limit: number, offset: number, query: string) => void;
  getItemById: (userId: number) => void;
  resetUserData: () => void;
  resetCategoryData: () => void;
  resetFoundationData: () => void;
  resetItemData: () => void;
  resetClubData: () => void;
}

interface AddEditItemProps extends StoreState, DispatchProps {}

class AddEditItems extends React.Component<AddEditItemProps, RouteProps> {
  private itemId: number = this.props['match']['params']['id'];

  private playerIdSelector: React.RefObject<Select> = React.createRef();
  private userIdSelector: React.RefObject<Select> = React.createRef();
  private categoryIdSelector: React.RefObject<Select> = React.createRef();
  private clubIdSelector: React.RefObject<Select> = React.createRef();
  private foundationIdSelector: React.RefObject<Select> = React.createRef();
  private visitorClubIdSelector: React.RefObject<Select> = React.createRef();
  private leagueName: React.RefObject<HTMLInputElement> = React.createRef();
  private name: React.RefObject<HTMLInputElement> = React.createRef();
  private clubGoals: React.RefObject<HTMLInputElement> = React.createRef();
  private visitorClubGoals: React.RefObject<HTMLInputElement> = React.createRef();
  private created: React.RefObject<HTMLInputElement> = React.createRef();

  private media: FormMedia;
  private mediaVideo: FormMedia;
  private leagueMedia: FormMedia;

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddEditItemProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };

    this.validationService = addEditValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        playerId: this.validationService.getLastError('playerId'),
        foundationId: this.validationService.getLastError('foundationId'),
        media: this.validationService.getLastError('media'),
        mediaVideo: this.validationService.getLastError('mediaVideo'),
        leagueName: this.validationService.getLastError('leagueName'),
        name: this.validationService.getLastError('name'),
        leagueMedia: this.validationService.getLastError('leagueMedia'),
        clubId: this.validationService.getLastError('clubId'),
        clubGoals: this.validationService.getLastError('clubGoals'),
        visitorClubGoals: this.validationService.getLastError('visitorClubGoals'),
        userId: this.validationService.getLastError('userId'),
        created: this.validationService.getLastError('created'),
      },
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  submit(e) {
    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('leagueName', GetReactRef.asString(this.leagueName));
    this.validationService.updateValue('name', GetReactRef.asString(this.name));
    this.validationService.updateValue('clubGoals', GetReactRef.asString(this.clubGoals));
    this.validationService.updateValue('visitorClubGoals', GetReactRef.asString(this.visitorClubGoals));
    this.validationService.updateValue('created', GetReactRef.asString(this.created));
    this.validationService.updateValue(
      'playerId',
      this.playerIdSelector.current.state.value ? this.playerIdSelector.current.state.value.value : null
    );
    this.validationService.updateValue(
      'userId',
      this.userIdSelector.current.state.value ? this.userIdSelector.current.state.value.value : null
    );
    this.validationService.updateValue(
      'clubId',
      this.clubIdSelector.current.state.value ? this.clubIdSelector.current.state.value.value : null
    );
    this.validationService.updateValue(
      'foundationId',
      this.foundationIdSelector.current.state.value
        ? this.foundationIdSelector.current.state.value.value
        : null
    );
    this.validationService.updateValue('media', this.getFileDropzoneData('media'));
    this.validationService.updateValue('mediaVideo', this.getFileDropzoneData('mediaVideo'));
    this.validationService.updateValue('leagueMedia', this.getFileDropzoneData('leagueMedia'));

    if (this.handleValidation()) {
      this.sendItemForm();
    }
  }

  private isEdditing(): boolean {
    return this.itemId !== undefined;
  }

  private sendItemForm() {
    const category = this.categoryIdSelector.current.state.value
      ? this.categoryIdSelector.current.state.value.value
      : null;

    const visitorClub = this.visitorClubIdSelector.current.state.value
    ? this.visitorClubIdSelector.current.state.value.value
    : null;

    const playerForm = new ItemForm(
      'FOOTBALL_SHIRT',
      this.playerIdSelector.current.state.value.value,
      this.foundationIdSelector.current.state.value.value,
      new Map<any, any>([['en', GetReactRef.asString(this.name)]]),
      new Map<any, any>([['en', 'foo']]),
      category,
      this.media,
      this.media,
      this.mediaVideo,
      GetReactRef.asString(this.leagueName),
      this.leagueMedia,
      this.clubIdSelector.current.state.value.value,
      GetReactRef.asNumber(this.clubGoals),
      visitorClub,
      GetReactRef.asNumber(this.visitorClubGoals),
      this.userIdSelector.current.state.value.value,
      '{}',
      GetReactRef.asDate(this.created).toISOString()
    );

    if (this.isEdditing()) {
      this.props.editItem(this.itemId, playerForm);
    } else {
      this.props.createItem(playerForm);
    }
  }

  getClubsOptions = () =>
    this.props.club.clubList.data
      ? this.props.club.clubList.data.data.map((val) => ({ value: val.id, label: val.name }))
      : []

  getVisitorClubOptions = () => {
    if (this.props.club.clubList.data) {
      const visitorClubList = this.props.club.clubList.data.data.map((val) => ({
        value: val.id,
        label: val.name,
      }));

      visitorClubList.unshift({ value: null, label: '-- None --' });

      return visitorClubList;
    }

    return [];
  }

  getUsersOptions = () =>
    this.props.player.playerList.data
      ? this.props.player.playerList.data.data.map((val) => ({ value: val.user.id, label: val.fullName }))
      : []

  getCategoriesOptions = () => {
    if (this.props.category.categoryList.data) {
      const categoryList = this.props.category.categoryList.data.map((val) => ({
        value: val.id,
        label: val.name,
      }));

      categoryList.unshift({ value: null, label: '-- None --' });

      return categoryList;
    }

    return [];
  }

  getPlayersOptions = () =>
    this.props.player.playerList.data
      ? this.props.player.playerList.data.data.map((val) => ({ value: val.id, label: val.fullName }))
      : []

  getFoundationsOptions = () =>
    this.props.foundation.foundationList.data
      ? this.props.foundation.foundationList.data.data.map((val) => ({ value: val.id, label: val.name }))
      : []

  handleSelectChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  getFileDropzoneData(type: string) {
    if (this.props.item.item.data && !(type in this.state.fields)) {
      return this.props.item.item.data[type].mediaStorage;
    } else if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  componentDidMount() {
    if (this.isEdditing()) {
      this.props.getItemById(this.itemId);
      this.props.resetClubData();
      this.props.resetFoundationData();
      this.props.resetUserData();
      this.props.resetCategoryData();
      this.props.getClubs(10, 0, '');
      this.props.getFoundations(10, 0, '');
      this.props.getUsers(10, 0, '');
      this.props.getCategories();
    } else {
      this.props.getClubs(10, 0, '');
      this.props.getFoundations(10, 0, '');
      this.props.getUsers(10, 0, '');
      this.props.resetItemData();
    }

    this.props.getCategories();

    if (!this.props['match']['params']['id']) {
      this.props.item.itemList.data = null;
    }
  }

  inputHandleChangeUsers = (inputText: string) => {
    this.props.getUsers(10, 0, inputText);
  }

  inputHandleChangeClubs = (inputText: string) => {
    this.props.getClubs(10, 0, inputText);
  }

  inputHandleChangeFoundations = (inputText: string) => {
    this.props.getFoundations(10, 0, inputText);
  }

  render() {
    if (this.props.item.item.isFetching) {
      return (
        <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
          <span className='sr-only'>...</span>
        </div>
      );
    }

    let selectShirt = null;

    if (this.props.item.item.data && this.isEdditing()) {
      selectShirt = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.playerIdSelector}
          defaultValue={{
            label: this.props.item.item.data.player.fullName,
            value: this.props.item.item.data.player.id,
          }}
          style={{ width: '100%' }}
          options={this.getPlayersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    } else if (!this.isEdditing() || (this.isEdditing() && !this.props.item.item.data)) {
      selectShirt = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.playerIdSelector}
          style={{ width: '100%' }}
          options={this.getPlayersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    }

    let selectCategory = null;

    if (this.props.item.item.data && this.props.item.item.data.category && this.isEdditing()) {
      selectCategory = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.categoryIdSelector}
          defaultValue={{
            label: this.props.item.item.data.category.name,
            value: this.props.item.item.data.category.id,
          }}
          style={{ width: '100%' }}
          options={this.getCategoriesOptions()}
        />
      );
    } else if (
      !this.isEdditing() ||
      (this.isEdditing() && this.props.item.item.data && !this.props.item.item.data.category)
    ) {
      selectCategory = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.categoryIdSelector}
          style={{ width: '100%' }}
          options={this.getCategoriesOptions()}
        />
      );
    }

    let selectUser = null;

    if (this.props.item.item.data && this.isEdditing()) {
      selectUser = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.userIdSelector}
          defaultValue={{
            label: this.props.item.item.data.user.name + ' ' + this.props.item.item.data.user.surname,
            value: this.props.item.item.data.user.id,
          }}
          style={{ width: '100%' }}
          options={this.getUsersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    } else if (!this.isEdditing() || (this.isEdditing() && !this.props.item.item.data)) {
      selectUser = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.userIdSelector}
          style={{ width: '100%' }}
          options={this.getUsersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    }

    let selectFoundation = null;

    if (this.props.item.item.data && this.isEdditing()) {
      selectFoundation = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.foundationIdSelector}
          defaultValue={{
            label: this.props.item.item.data.foundation.name,
            value: this.props.item.item.data.foundation.id,
          }}
          style={{ width: '100%' }}
          options={this.getFoundationsOptions()}
          onInputChange={this.inputHandleChangeFoundations}
        />
      );
    } else if (!this.isEdditing() || (this.isEdditing() && !this.props.item.item.data)) {
      selectFoundation = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.foundationIdSelector}
          style={{ width: '100%' }}
          options={this.getFoundationsOptions()}
          onInputChange={this.inputHandleChangeFoundations}
        />
      );
    }

    let selectClub = null;

    if (this.props.item.item.data && this.isEdditing()) {
      selectClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.clubIdSelector}
          defaultValue={{
            label: this.props.item.item.data.club.name,
            value: this.props.item.item.data.club.id,
          }}
          style={{ width: '100%' }}
          options={this.getClubsOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    } else if (!this.isEdditing() || (this.isEdditing() && !this.props.item.item.data)) {
      selectClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.clubIdSelector}
          style={{ width: '100%' }}
          options={this.getClubsOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    }

    let selectVisitorClub = null;

    if (this.props.item.item.data && this.props.item.item.data.visitorClub && this.isEdditing()) {
      selectVisitorClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.visitorClubIdSelector}
          defaultValue={{
            label: this.props.item.item.data.visitorClub.name,
            value: this.props.item.item.data.visitorClub.id,
          }}
          style={{ width: '100%' }}
          options={this.getVisitorClubOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    } else if (
      !this.isEdditing() ||
      (this.isEdditing() && (!this.props.item.item.data || !this.props.item.item.data.visitorClub))
    ) {
      selectVisitorClub = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.visitorClubIdSelector}
          style={{ width: '100%' }}
          options={this.getVisitorClubOptions()}
          onInputChange={this.inputHandleChangeClubs}
        />
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='items.items' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='items.items' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id='items.add_edit_item' />
              </li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>{this.isEdditing() ? 'Edit' : 'Create'} Item</h3>

                    <hr />
                    <div className='row p-t-20'>
                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['name'] ? ' error' : '')}>
                          <label>NFT Name</label>
                          <input
                            onChange={this.handleChange.bind(this, 'name')}
                            value={this.state.fields['name']}
                            ref={this.name}
                            defaultValue={
                              this.props.item.item.data && this.isEdditing()
                                ? this.props.item.item.data.name
                                : ''
                            }
                            type='text'
                            className='form-control'
                          />
                          <span className='error-message'>{this.state.errors['leagueName']}</span>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['playerId'] ? ' error' : '')}>
                          <label className='control-label'>Shirt</label>
                          {selectShirt}
                          <span className='error-message'>{this.state.errors['playerId']}</span>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['foundationId'] ? ' error' : '')}>
                          <label className='control-label'>Foundation/Cause</label>
                          {selectFoundation}
                          <span className='error-message'>{this.state.errors['foundationId']}</span>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['leagueName'] ? ' error' : '')}>
                          <label>League Name</label>
                          <input
                            onChange={this.handleChange.bind(this, 'leagueName')}
                            value={this.state.fields['leagueName']}
                            ref={this.leagueName}
                            defaultValue={
                              this.props.item.item.data && this.isEdditing()
                                ? this.props.item.item.data.leagueName
                                : ''
                            }
                            type='text'
                            className='form-control'
                          />
                          <span className='error-message'>{this.state.errors['leagueName']}</span>
                        </div>
                      </div>
                    </div>

                    <div className='row p-t-20'>
                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['clubId'] ? ' error' : '')}>
                          <label className='control-label'>Club</label>
                          {selectClub}
                          <span className='error-message'>{this.state.errors['clubId']}</span>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='control-label'>Rival Club</label>
                          {selectVisitorClub}
                        </div>
                      </div>

                      <div className='col-md-2'>
                        <div className={'form-group' + (this.state.errors['clubGoals'] ? ' error' : '')}>
                          <label>Club Goals</label>
                          <input
                            onChange={this.handleChange.bind(this, 'clubGoals')}
                            value={this.state.fields['clubGoals']}
                            ref={this.clubGoals}
                            defaultValue={
                              this.props.item.item.data && this.isEdditing()
                                ? this.props.item.item.data.clubGoals.toString()
                                : ''
                            }
                            type='number'
                            className='form-control'
                          />
                          <span className='error-message'>{this.state.errors['clubGoals']}</span>
                        </div>
                      </div>

                      <div className='col-md-2'>
                        <div
                          className={'form-group' + (this.state.errors['visitorClubGoals'] ? ' error' : '')}
                        >
                          <label>Rival Club Goals</label>
                          <input
                            onChange={this.handleChange.bind(this, 'visitorClubGoals')}
                            value={this.state.fields['visitorClubGoals']}
                            ref={this.visitorClubGoals}
                            defaultValue={
                              this.props.item.item.data && this.isEdditing()
                                ? this.props.item.item.data.visitorClubGoals.toString()
                                : ''
                            }
                            type='number'
                            className='form-control'
                          />
                          <span className='error-message'>{this.state.errors['visitorClubGoals']}</span>
                        </div>
                      </div>
                    </div>

                    <div className='row p-t-20'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label>Category</label>
                          {selectCategory}
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['userId'] ? ' error' : '')}>
                          <label>User</label>
                          {selectUser}
                          <span className='error-message'>{this.state.errors['userId']}</span>
                        </div>
                      </div>

                      <div className='col-md-4'>
                        <div className={'form-group' + (this.state.errors['clubGoals'] ? ' error' : '')}>
                          <label>Date</label>
                          <input
                            onChange={this.handleChange.bind(this, 'created')}
                            value={this.state.fields['created']}
                            ref={this.created}
                            defaultValue={
                              this.props.item.item.data && this.isEdditing()
                                ? InputFormat.getDate(new Date(this.props.item.item.data.created))
                                : ''
                            }
                            type='date'
                            placeholder='YYYY-MM-DD'
                            className='form-control'
                          />
                          <span className='error-message'>{this.state.errors['created']}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-4'>
                      <div className={'form-group' + (this.state.errors['media'] ? ' error' : '')}>
                        <label>Shirt Image</label>
                        <FileDropzone
                          value={this.state.fields['media']}
                          accept='image/*'
                          preview={
                            !('media' in this.state.fields) && this.props.item.item.data
                              ? this.props.item.item.data.media.mediaStorage
                              : undefined
                          }
                          onFileChange={(file: FormMedia[]) => {
                            this.media = file[0];
                            this.handleDropzoneChange('media', file[0] ? file[0].previewUrl : '');
                          }}
                        />
                        <span className='error-message'>{this.state.errors['media']}</span>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className={'form-group' + (this.state.errors['mediaVideo'] ? ' error' : '')}>
                        <label>Video</label>
                        <FileDropzone
                          value={this.state.fields['mediaVideo']}
                          accept='video/*'
                          preview={
                            !('mediaVideo' in this.state.fields) &&
                            this.props.item.item.data &&
                            this.props.item.item.data
                              ? this.props.item.item.data.mediaVideo.mediaStorage
                              : undefined
                          }
                          onFileChange={(file: FormMedia[]) => {
                            this.mediaVideo = file[0];
                            this.handleDropzoneChange('mediaVideo', file[0] ? file[0].previewUrl : '');
                          }}
                        />
                        <span className='error-message'>{this.state.errors['mediaVideo']}</span>
                      </div>
                    </div>

                    <div className='col-md-4'>
                      <div className={'form-group' + (this.state.errors['leagueMedia'] ? ' error' : '')}>
                        <label>League Logo</label>
                        <FileDropzone
                          value={this.state.fields['leagueMedia']}
                          accept='image/*'
                          preview={
                            !('leagueMedia' in this.state.fields) &&
                            this.props.item.item.data &&
                            this.props.item.item.data
                              ? this.props.item.item.data.leagueMedia.mediaStorage
                              : undefined
                          }
                          onFileChange={(file: FormMedia[]) => {
                            this.leagueMedia = file[0];
                            this.handleDropzoneChange('leagueMedia', file[0] ? file[0].previewUrl : '');
                          }}
                        />
                        <span className='error-message'>{this.state.errors['leagueMedia']}</span>
                      </div>
                    </div>
                  </div>

                  <div className='form-actions text-right p-t-30'>
                    <Link to='/items'>
                      <button type='button' className='btn btn-info m-r-10'>
                        Cancel
                      </button>
                    </Link>

                    <button
                      disabled={this.props.item.create.isFetching}
                      className='btn btn-success'
                      type='submit'
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    createItem: (request: ItemForm) => dispatch(createItemAction(request)),
    editItem: (id: number, request: ItemForm) => dispatch(editItemAction(id, request)),
    getUserById: (userId: number) => dispatch(requestUser(userId)),
    getClubById: (clubId: number) => dispatch(requestClubById(clubId)),
    getFoundationById: (foundationId: number) => dispatch(requestFoundation(foundationId)),
    getClubs: (limit: number, offset: number, query: string) => dispatch(requestClubs(limit, offset, query)),
    getUsers: (limit: number, offset: number, query: string) =>
      dispatch(requestPlayers(limit, offset, query)),
    getCategories: () => dispatch(requestCategories()),
    getFoundations: (limit: number, offset: number, query: string) =>
      dispatch(requestFoundations(limit, offset, query)),
    getItemById: (userId: number) => dispatch(requestItemById(userId)),
    resetUserData: () => dispatch(receiveUserSuccess(null)),
    resetCategoryData: () => dispatch(requestCategoriesSuccess(null)),
    resetFoundationData: () => dispatch(receiveFoundation(null)),
    resetItemData: () => dispatch(receiveItem(null)),
    resetClubData: () => dispatch(receiveFoundation(null)),
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps
)(AddEditItems);
