import { NewRolSchema, RolSchema } from 'crypthum-sdk';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import Select from 'react-select';
import { WithContext as ReactTags } from 'react-tag-input';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  requestPlayerRate,
} from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Player } from '../../redux/state/players';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';


interface DispatchProps {
  getRate: (instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string) => void;
}

interface AllRateProps extends Player, DispatchProps { }

class Rate extends React.Component<AllRateProps> {
  rssRef: React.RefObject<HTMLInputElement> = React.createRef();
  fifaRef: React.RefObject<HTMLInputElement> = React.createRef();
  marketRef: React.RefObject<HTMLInputElement> = React.createRef();


  instagramRef: React.RefObject<HTMLInputElement> = React.createRef();
  transferMarketRef: React.RefObject<HTMLInputElement> = React.createRef();
  sofifaRef: React.RefObject<HTMLInputElement> = React.createRef();
  private instagramClubs: { id: string, text: string }[] = [];

  constructor(public readonly props: AllRateProps) {
    super(props);
  }


  private submit(e) {
    e.preventDefault();
    this.props.getRate(
      GetReactRef.asString(this.instagramRef),
      this.instagramClubs.map((value) => value.text),
      GetReactRef.asString(this.sofifaRef),
      GetReactRef.asString(this.transferMarketRef),
    );
  }

  handleAddIG(tagName: string) {
    this.instagramClubs.push({ id: tagName, text: tagName });
  }

  handleDeleteIG(tagIndex: number) {
    this.instagramClubs.splice(tagIndex, 1);
  }

  render() {
    let pt = '-';
    if (this.props.rate.data) {
      pt = (
        ((this.props.rate.data.rss * GetReactRef.asNumber(this.rssRef)) / 10)
        + ((this.props.rate.data.fifa * GetReactRef.asNumber(this.fifaRef)) / 10)
        + ((this.props.rate.data.market * GetReactRef.asNumber(this.marketRef)) / 10)
      ).toFixed(0);
    }

    let body;

    let head;
    if (this.props.rate.error) {
      head = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching rate</h3>
          <p className='card-text'>There was an error downloading the rate. Please, try again in a few minutes.</p>
        </div>
      </div>;
    }
    body = <div className='row'>
      <div className='col-12 card' style={{ paddingTop: 30 }}>
      { head }
      <form onSubmit={this.submit.bind(this)}>
          <div className='form-body'>

            <fieldset>

              <div className='row p-t-20' style={{display: 'none'}}>

                <div className='col-md-8'>
                  <div className='form-group'>
                    <label className='control-label'>Busca aquí al jugador</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-search'></i>
                        </span>
                      </div>
                      <input type='text' className='form-control' />
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className={'form-group'}>
                    <label className='control-label'>Selector de partido</label>
                    <input type='date' placeholder='YYYY-MM-DD' className='form-control' />
                  </div>
                </div>

              </div>

              <div className='row'>
                <div className='col-md-4'>
                  <div className={'form-group'}>
                    <label className='control-label'>Rss</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-widget'></i>
                        </span>
                      </div>
                      <input ref={this.rssRef} type='text' defaultValue='50' className='form-control' />
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className={'form-group'}>
                    <label className='control-label'>Transfermarkt</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-widget'></i>
                        </span>
                      </div>
                      <input ref={this.marketRef} type='text' defaultValue='10' className='form-control' />
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className={'form-group'}>
                    <label className='control-label'>Fifa</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-widget'></i>
                        </span>
                      </div>
                      <input ref={this.fifaRef} type='text' defaultValue='40' className='form-control' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className={'form-group'}>
                    <label className='control-label'>Instagram Player</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-instagram'></i>
                        </span>
                      </div>
                      <input ref={this.instagramRef} type='text' className='form-control' />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className={'form-group'}>
                    <label className='control-label'>Transfermarkt URL</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-money'></i>
                        </span>
                      </div>
                      <input ref={this.transferMarketRef} type='text' className='form-control' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className={'form-group'}>
                    <label className='control-label'>Instagram Clubs</label>
                      <ReactTags
                            className='form-control'
                            inputFieldPosition='inline'
                            handleAddition={(tag: { id: string, text: string }) => this.handleAddIG(tag.text)}
                            handleDelete={(tagIndex: number) => this.handleDeleteIG(tagIndex)}
                            allowDragDrop={false}
                            tags={this.instagramClubs} />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className={'form-group'}>
                    <label className='control-label'>SoFIFA URL</label>
                    <div className='input-group mb-3'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='basic-addon1'>
                          <i className='ti-dribbble'></i>
                        </span>
                      </div>
                      <input ref={this.sofifaRef} type='text' className='form-control' />
                    </div>
                  </div>
                </div>
              </div>

            </fieldset>

          </div>
          <br/>
          <div className='form-actions text-center'>

            <button disabled={this.props.rate.isFetching} className='btn btn-success' type='submit'>Generar tasación</button>

          </div>

        </form>
        <br/>
      </div>
      <div className='col-12'>
        <h5 className='box-title'><strong>Resultado de la tasación</strong></h5>
      </div>
      <div className='col-12'>
        <div className='card'>
            <div className='col-12 text-center align-middle'>
              <p className='card-text p-l-120' style={{ padding: 20 }}>JUGADOR:
              <b> { pt }pt</b>
              </p>
          </div>
        </div>
      </div>
    </div>;

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Tasación</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Tasación</li>
              <li className='breadcrumb-item active'>Valorar jugador</li>
            </ol>
          </div>
        </div>

        {body}

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getRate: (
      instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string
    ) => dispatch(requestPlayerRate(instagram, clubInstagrams, sofifaUrl, transfermarktUrl)),
  };
};

export default connect<Player, DispatchProps>(
  (store: StoreState) => store.player,
  mapDispatchToProps,
)(Rate);
