import { CategoryAction } from '../actions';
import * as CategoryActionType from '../actions/types/categories';
import { Category, getInitialState } from '../state/categories';

export const categories = (state: Category = getInitialState(), action: CategoryAction): Category => {
  switch (action.type) {
    case CategoryActionType.CREATE_CATEGORY:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true
        }
      };
    case CategoryActionType.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false
        }
      };
    case CategoryActionType.CREATE_CATEGORY_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error
        }
      };
    case CategoryActionType.EDIT_CATEGORY:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case CategoryActionType.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case CategoryActionType.EDIT_CATEGORY_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case CategoryActionType.REQUEST_CATEGORIES:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          isFetching: true
        }
      };
    case CategoryActionType.REQUEST_CATEGORIES_FAILED:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          isFetching: false,
          error: action.error

        }
      };
    case CategoryActionType.RECEIVE_CATEGORIES:
      return {
        ...state,
        categoryList: {
          ...state.categoryList,
          isFetching: false,
          data: action.categories
        }
      };
    default:
      return state;
  }
}