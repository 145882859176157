import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestI18nKeys, translateKeyAction } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Language } from '../../redux/state/languages';
import { dataTableOptions } from '../ui/DataTableOptions';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';

interface DispatchProps {
  getAllkeys: () => void;
  updateTranslation: (key: string, request: Map<any, any>) => void;
}

interface TranslateKeysProps extends Language, DispatchProps { }

interface MyState { show: boolean; key: string; value: string; }

class TranslateKeys extends React.Component<TranslateKeysProps, MyState> {

  i18nKeyRef: React.RefObject<HTMLInputElement> = React.createRef();
  i18nValueRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  constructor(public readonly props: TranslateKeysProps) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      key: '',
      value: ''
    };
  }

  handleClose() {
    this.setState({
      show: false,
      key: '',
      value: ''
    });
  }

  handleSaveChanges(langCode: any) {
    this.props.updateTranslation(
      GetReactRef.asString(this.i18nKeyRef),
      new Map<any, any>([[langCode, GetReactRef.asString(this.i18nValueRef)]])
    );

    this.handleClose();
  }

  handleShow(key: string, value: string) {
    this.setState({
      show: true,
      key: key,
      value: value
    });
  }

  componentDidMount() {
    this.props.getAllkeys();
  }

  render() {

    const columns = [
      {
        name: 'i18nKey',
        label: 'I18n Key'
      }, {
        name: 'i18nValue',
        label: 'I18n Value'
      }, {
        name: 'status',
        label: 'Status'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const langCode: string = this.props['match']['params']['code'];
    const i18nKeyList = this.props.i18nKeyList.data;

    if (i18nKeyList == null || langCode == null) {
      return null;
    }

    const keys = Object.keys(i18nKeyList);
    const data: any[] = [];

    let keyPending: JSX.Element[] = [];

    for (let i = 0; i < keys.length; i++) {

      keyPending = [];

      if (i18nKeyList[keys[i]][langCode]['pending']) {
        keyPending.push(
          <span key={i} className='label label-warning label-rounded'>Pending</span>
        );
      }

      data.push({
        i18nKey: keys[i],
        i18nValue: i18nKeyList[keys[i]][langCode]['value'],
        status: keyPending,
        options: <Button variant='success'
          onClick={() => this.handleShow(keys[i], i18nKeyList[keys[i]][langCode]['value'])}>
          <i className='fa fa-pencil-alt'></i>
        </Button>
      });
    }

    const options = {
      ...dataTableOptions,
      customSearch: undefined,
    };

    let body;

    if (this.props.i18nKeyList.isFetching) {

      body = <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;

    } else if (this.props.i18nKeyList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching languages</h3>
          <p className='card-text'>There was an error downloading the language list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getAllkeys(); }}>Try again</button>
        </div>
      </div>;

    } else {

      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='languages.language_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='languages.language_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='languages.languages' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='languages.languages' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='languages.list_languages' /></li>
            </ol>
          </div>
        </div>

        {body}

        <NotificationContainer />

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Translation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='control-label'>I18n Key</label>
                  <input ref={this.i18nKeyRef} type='text' defaultValue={this.state.key} className='form-control' disabled />
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label className='control-label'>I18n Value</label>
                  <textarea ref={this.i18nValueRef} defaultValue={this.state.value} className='form-control' rows={5 as number}></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={this.handleClose}>
              Close
            </Button>
            <Button variant='info' onClick={() => { this.handleSaveChanges(langCode); }}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getAllkeys: () => dispatch(requestI18nKeys()),
    updateTranslation: (key: string, request: Map<any, any>) => dispatch(translateKeyAction(key, request))
  };
};

export default connect<Language, DispatchProps>(
  (store: StoreState) => store.language,
  mapDispatchToProps,
)(TranslateKeys);
