import { GlobalAction } from '../actions/global';
import * as GlobalActionType from '../actions/types/global';
import { getInitialState, Global } from '../state/global';

export const global = (state: Global = getInitialState(), action: GlobalAction): Global => {
  switch (action.type) {
    case GlobalActionType.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GlobalActionType.END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
