import { ItemAction } from "../actions";
import * as ItemActionType from "../actions/types/items";
import { getInitialState, Item } from "../state/items";

export const items = (state: Item = getInitialState(), action: ItemAction): Item => {
  switch (action.type) {
    case ItemActionType.CREATE_ITEM:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true,
        },
      };
    case ItemActionType.CREATE_ITEM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
        },
      };
    case ItemActionType.CREATE_ITEM_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error,
        },
      };
    case ItemActionType.EDIT_ITEM:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true,
        },
      };
    case ItemActionType.EDIT_ITEM_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
        },
      };
    case ItemActionType.EDIT_ITEM_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error,
        },
      };
    case ItemActionType.REQUEST_ITEMS:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          isFetching: true,
        },
      };
    case ItemActionType.REQUEST_ITEMS_FAILED:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          isFetching: false,
          error: action.error,
        },
      };
    case ItemActionType.RECEIVE_ITEMS:
      return {
        ...state,
        itemList: {
          ...state.itemList,
          isFetching: false,
          data: action.items,
        },
      };
    case ItemActionType.REQUEST_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          isFetching: true,
        },
      };
    case ItemActionType.REQUEST_ITEM_FAILED:
      return {
        ...state,
        item: {
          ...state.item,
          isFetching: false,
          error: action.error,
        },
      };
    case ItemActionType.REQUEST_ITEM_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          isFetching: false,
        },
      };
    case ItemActionType.RECEIVE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          isFetching: false,
          data: action.item,
        },
      };
    default:
      return state;
  }
};
