export interface Login {
    isFetching: boolean;
    logged: boolean;
    wrongCredentials: boolean;
}

export function getInitialState(): Login {
    return {
        isFetching: false,
        logged: false,
        wrongCredentials: false,
    };
}
