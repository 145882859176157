import { PlayerAction } from '../actions';
import * as PlayerActionType from '../actions/types/players';
import { getInitialState, Player } from '../state/players';

export const players = (state: Player = getInitialState(), action: PlayerAction): Player => {
  switch (action.type) {
    case PlayerActionType.CREATE_PLAYER:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true
        }
      };
    case PlayerActionType.CREATE_PLAYER_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false
        }
      };
    case PlayerActionType.CREATE_PLAYER_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error
        }
      };
    case PlayerActionType.EDIT_PLAYER:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case PlayerActionType.EDIT_PLAYER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case PlayerActionType.EDIT_PLAYER_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case PlayerActionType.REQUEST_PLAYERS:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          isFetching: true
        }
      };
    case PlayerActionType.REQUEST_PLAYERS_FAILED:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          isFetching: false,
          error: action.error
        }
      };
    case PlayerActionType.RECEIVE_PLAYERS:
      return {
        ...state,
        playerList: {
          ...state.playerList,
          isFetching: false,
          data: action.players
        }
      };
    case PlayerActionType.REQUEST_PLAYER:
      return {
        ...state,
        player: {
          ...state.player,
          isFetching: true
        }
      };
    case PlayerActionType.RECEIVE_PLAYER_SUCCESS:
      return {
        ...state,
        player: {
          ...state.player,
          isFetching: false,
          data: action.player
        },
      };
    case PlayerActionType.RECEIVE_PLAYER_FAILED:
      return {
        ...state,
        player: {
          ...state.player,
          isFetching: false,
          error: action.error
        }
      };
      case PlayerActionType.REQUEST_PLAYER_RATE:
      return {
        ...state,
        rate: {
          ...state.rate,
          isFetching: true
        }
      };
    case PlayerActionType.REQUEST_PLAYER_RATE_SUCCESS:
      return {
        ...state,
        rate: {
          ...state.rate,
          isFetching: false,
          data: action.data,
          error: null
        },
      };
    case PlayerActionType.REQUEST_PLAYER_RATE_FAILED:
      return {
        ...state,
        rate: {
          ...state.rate,
          isFetching: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
