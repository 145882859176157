import { push } from 'connected-react-router';
import {
  CreatePlayer as CreatePlayerEndpoint,
  EditPlayer as EditPlayerEndpoint,
  GetPlayerById,
  GetPlayerRate as GetPlayerRateEndoint,
  GetPlayers,
  NewPlayerSchema,
  PlayerSchema,
} from 'crypthum-sdk';
import { ListPlayer } from 'crypthum-sdk/dist/schemas/ListPlayer';
import { PlayerRate } from 'crypthum-sdk/dist/schemas/PlayerRate';
import { PlayerWithItems } from 'crypthum-sdk/src/schemas/PlayerWithItems';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import PlayerForm from '../../entities/PlayerForm';
import { getNewPlayerAndUploadMedia } from '../../factories/Player';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getPlayers = async (limit: number, offset: number, query: string): Promise<ListPlayer> =>
  await cryptoMuseumsSDK.endpoint<GetPlayers>(GetPlayers).run({
    limit: limit,
    offset: offset,
    query: query,
  });

const getPlayer = async (id: number): Promise<PlayerWithItems> =>
  await cryptoMuseumsSDK.endpoint<GetPlayerById>(GetPlayerById).run({
    player_id: id,
  });

const getPlayerRate = async (
  instagram: string,
  clubInstagrams: string[],
  sofifaUrl: string,
  transfermarktUrl: string
): Promise<PlayerRate> =>
  await cryptoMuseumsSDK.endpoint<GetPlayerRateEndoint>(GetPlayerRateEndoint).run({
    instagram,
    club_instagrams: clubInstagrams,
    sofifa_url: sofifaUrl,
    transfermarkt_url: transfermarktUrl,
  });

const createPlayer = async (body: NewPlayerSchema.NewPlayer) =>
  await cryptoMuseumsSDK.endpoint<CreatePlayerEndpoint>(CreatePlayerEndpoint).run({
    body: body,
  });

const editPlayer = async (id: number, body: NewPlayerSchema.NewPlayer) => {
  return await cryptoMuseumsSDK.endpoint<EditPlayerEndpoint>(EditPlayerEndpoint).run({
    player_id: id,
    body: body,
  });
};

export interface CreatePlayer {
  type: types.CREATE_PLAYER;
}

export interface RequestPlayerRate {
  type: types.REQUEST_PLAYER_RATE;
}

export interface RequestPlayerRateFailed {
  type: types.REQUEST_PLAYER_RATE_FAILED;
  error: string;
}

export interface RequestPlayerRateSuccess {
  type: types.REQUEST_PLAYER_RATE_SUCCESS;
  data: PlayerRate;
}

export interface CreatePlayerFailed {
  type: types.CREATE_PLAYER_FAILED;
  error: string;
}

export interface CreatePlayerSuccess {
  type: types.CREATE_PLAYER_SUCCESS;
}

export interface EditPlayer {
  type: types.EDIT_PLAYER;
}

export interface EditPlayerFailed {
  type: types.EDIT_PLAYER_FAILED;
  error: string;
}

export interface EditPlayerSuccess {
  type: types.EDIT_PLAYER_SUCCESS;
}

export interface RequestPlayers {
  type: types.REQUEST_PLAYERS;
}

export interface RequestPlayersFailed {
  type: types.REQUEST_PLAYERS_FAILED;
  error: string;
}

export interface RequestPlayersSuccess {
  type: types.REQUEST_PLAYERS_SUCCESS;
}

export interface ReceivePlayers {
  type: types.RECEIVE_PLAYERS;
  players: ListPlayer;
}

export interface RequestPlayer {
  type: types.REQUEST_PLAYER;
}

export interface ReceivePlayerSuccess {
  type: types.RECEIVE_PLAYER_SUCCESS;
  player: PlayerSchema.Player;
}

export interface ReceivePlayerFailed {
  type: types.RECEIVE_PLAYER_FAILED;
  error: string;
}

export type PlayerAction =
  | RequestPlayers
  | RequestPlayersSuccess
  | RequestPlayersFailed
  | RequestPlayer
  | ReceivePlayerSuccess
  | ReceivePlayerFailed
  | CreatePlayer
  | ReceivePlayers
  | CreatePlayerFailed
  | CreatePlayerSuccess
  | EditPlayer
  | EditPlayerFailed
  | EditPlayerSuccess
  | RequestPlayerRate
  | RequestPlayerRateFailed
  | RequestPlayerRateSuccess;

export const receivePlayerRateSuccess = (rate: PlayerRate) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYER_RATE_SUCCESS, data: rate });
};

export const receivePlayerRateFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYER_RATE_FAILED, error: errorKey });
};

export const requestPlayerRate = (
  instagram: string,
  clubInstagrams: string[],
  sofifaUrl: string,
  transfermarktUrl: string
) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYER_RATE });
  dispatch(startLoading());
  getPlayerRate(instagram, clubInstagrams, sofifaUrl, transfermarktUrl)
    .then((rate) => {
      dispatch(receivePlayerRateSuccess(rate));
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(receivePlayerRateFailed(e));
      dispatch(endLoading());
    });
};

export const receivePlayerSuccess = (player: PlayerWithItems) => (dispatch) => {
  dispatch({ type: types.RECEIVE_PLAYER_SUCCESS, player: player });
};

export const receivePlayerFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.RECEIVE_PLAYER_FAILED, error: errorKey });
};

export const requestPlayer = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYER, id: id });
  getPlayer(id)
    .then((player) => {
      dispatch(receivePlayerSuccess(player));
    })
    .catch((e) => {
      dispatch(receivePlayerFailed(e));
    });
};

export const requestPlayersSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYERS_SUCCESS });
};

export const receivePlayers = (players: ListPlayer) => (dispatch) => {
  dispatch({ type: types.RECEIVE_PLAYERS, players: players });
  dispatch(requestPlayersSuccess());
};

export const requestPlayersFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYERS_FAILED, error: errorKey });
};

export const requestPlayers = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_PLAYERS });
  getPlayers(limit, offset, query)
    .then((players) => {
      dispatch(receivePlayers(players));
    })
    .catch((e) => {
      dispatch(requestPlayersFailed(e));
    });
};

export const createPlayerSuccess = () => (dispatch) => {
  dispatch({ type: types.CREATE_PLAYER_SUCCESS });
  dispatch(push('/players'));
  NotificationManager.success('El jugador ha sido creado correctamente.');
};

export const createPlayerFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_PLAYER_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear el jugador.');
};

export const createPlayerAction = (request: PlayerForm) => async (dispatch) => {
  dispatch({ type: types.START_LOADING });
  dispatch(startLoading());
  createPlayer(await getNewPlayerAndUploadMedia(request))
    .then(() => {
      dispatch(createPlayerSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createPlayerFailed(e));
      dispatch(endLoading());
    });
};

export const editPlayerSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_PLAYER_SUCCESS });
  dispatch(push('/players'));
  NotificationManager.success('El jugador ha sido editado correctamente.');
};

export const editPlayerFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_PLAYER_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al editar el jugador.');
};

export const editPlayerAction = (id: number, request: PlayerForm) => async (dispatch) => {
  dispatch({ type: types.EDIT_PLAYER });
  dispatch(startLoading());
  editPlayer(id, await getNewPlayerAndUploadMedia(request))
    .then(() => {
      dispatch(editPlayerSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editPlayerFailed(e));
      dispatch(endLoading());
    });
};
