import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestLanguages } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Language } from '../../redux/state/languages';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getLanguages: () => void;
}

interface ListLanguagesProps extends Language, DispatchProps { }

class ListLanguages extends React.Component<ListLanguagesProps> {

  constructor(public readonly props: ListLanguagesProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getLanguages();
  }

  render() {

    const columns = [
      {
        name: 'languageCode',
        label: 'Language Code'
      }, {
        name: 'description',
        label: 'Description'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const data: any[] = [];

    const languangeList = this.props.languageList.data;

    if (languangeList == null) {
      return null;
    }

    if (languangeList) {
      Object.keys(languangeList).map((language: string) => {
        data.push({
          languageCode: language,
          description: languangeList[language],
          options: <Link to={`/language/edit/${language}`}>
            <button className='btn btn-success waves-effect waves-light' type='button'>
              View Keys
          </button>
          </Link>
        });
      });
    }

    const options = {
      ...dataTableOptions,
      customSearch: undefined,
    };

    let body;

    if (this.props.languageList.isFetching) {

      body = <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;

    } else if (this.props.languageList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching languages</h3>
          <p className='card-text'>There was an error downloading the language list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getLanguages(); }}>Try again</button>
        </div>
      </div>;

    } else {

      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='languages.language_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='languages.language_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='languages.languages' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='languages.languages' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='languages.list_languages' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/language/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle'></i> <FormattedMessage id='languages.create_language' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getLanguages: () => dispatch(requestLanguages()),
  };
};

export default connect<Language, DispatchProps>(
  (store: StoreState) => store.language,
  mapDispatchToProps,
)(ListLanguages);
