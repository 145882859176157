import { ValidationService } from 'src/services/validation/validation.service';

export const editValidations = new ValidationService([
  {
    name: 'firstName',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'First name cannot be empty'
      }
    ]
  },
  {
    name: 'lastName',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Last name cannot be empty'
      }
    ]
  },
  {
    name: 'email',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Email cannot be empty'
      }
    ]
  },
  {
    name: 'alias',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Alias cannot be empty'
      }
    ]
  },
  {
    name: 'nationality',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Nationality cannot be empty'
      }
    ]
  },
  {
    name: 'birthdate',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to specify a birthdate'
      }
    ]
  },
  {
    name: 'gender',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a gender option'
      }
    ]
  },
  {
    name: 'favoriteClubId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a favorite club option'
      }
    ]
  }
]);
