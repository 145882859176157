import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestItems } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Item } from '../../redux/state/items';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getItems: (limit: number, offset: number, query: string) => void;
}

interface ListItemsProps extends Item, DispatchProps {}

class ListItems extends React.Component<ListItemsProps> {
  constructor(public readonly props: ListItemsProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getItems(150, 0, '');
  }

  render() {
    const columns = [
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'shirt',
        label: 'Shirt',
      },
      {
        name: 'player',
        label: 'Player',
      },
      {
        name: 'league',
        label: 'League',
      },
      {
        name: 'club',
        label: 'Club',
      },
      {
        name: 'rival_club',
        label: 'Club Rival',
      },
      {
        name: 'goals',
        label: 'Goles',
      },
      {
        name: 'date',
        label: 'Date',
      },
      {
        name: 'options',
        label: 'Options',
      },
    ];

    const data: any[] = [];

    if (this.props.itemList.data) {
      for (const item of this.props.itemList.data.data) {
        data.push({
          id: item.id,
          shirt: item.player.fullName,
          player: item.user.name + ' ' + item.user.surname,
          league: item.leagueName,
          club: item.club.name,
          rival_club: item.visitorClub === null ? '' : item.visitorClub.name,
          goals: item.visitorClub === null ? '' : item.clubGoals + ' - ' + item.visitorClubGoals,
          date: moment(item.created).format('YYYY-MM-DD'),
          options: (
            <Link to={`/item/edit/${item.id}`}>
              <button className='btn btn-success waves-effect waves-light' type='button'>
                <i className='fa fa-pencil-alt' />
              </button>
            </Link>
          ),
        });
      }
    }

    const options = {
      ...dataTableOptions,
      rowsPerPage: 150,
      rowsPerPageOptions: [10, 15, 50, 100, 150],
      count: this.props.itemList.data ? this.props.itemList.data.count : 0,
      serverSide: true,
      responsive: 'stackedFullWidth',
      isLoading: this.props.itemList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getItems(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      },
    };

    let body;

    if (this.props.itemList.error) {
      body = (
        <div className='card card-inverse card-danger'>
          <div className='card-body'>
            <h3 className='card-title'>Error fetching items</h3>
            <p className='card-text'>
              There was an error downloading the item list. Please, try again in a few minutes.
            </p>
            <button
              className='btn btn-inverse'
              onClick={() => {
                this.props.getItems(10, 0, '');
              }}
            >
              Try again
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>
                  <FormattedMessage id='items.item_table.title' />
                </h4>
                <h6 className='card-subtitle'>
                  <FormattedMessage id='items.item_table.subtitle' />
                </h6>
                <div className='table-responsive'>
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='items.items' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='items.items' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id='items.list_items' />
              </li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/item/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle' /> <FormattedMessage id='items.create_item' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getItems: (limit: number, offset: number, query: string) => dispatch(requestItems(limit, offset, query)),
  };
};

export default connect<Item, DispatchProps>(
  (store: StoreState) => store.item,
  mapDispatchToProps
)(ListItems);
