import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import { StoreState } from '../../redux/state';
import { Player } from '../../redux/state/players';
import {dataTableOptions} from '../ui/DataTableOptions';

const RatesQuery = () => {
    return (
        <Query
            query={gql`
                query {
                    rates {
                        id,
                        name,
                        transferURL,
                        sofifaURL,
                        instagram,
                        facebookFollowers,
                        instagramFollowers,
                        twitterFollowers,
                        totalFollowers,
                        age,
                        rssRate,
                        ageRate,
                        marketRate,
                        marketValue,
                        fifaRate,
                        totalRate,
                    }
                }
            `}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <p>Loading...</p>;
                }

                const fixedData: any = [];

                for (const rate of data.rates) {
                    delete rate.id;
                    fixedData.push(Object.values(rate));
                }

                const columns = [
                    'Nombre',
                    'Transfer URL',
                    'Fifa URL',
                    'IG',
                    'FB followers',
                    'IG Followers',
                    'TW Followers',
                    'Total Followers',
                    'Age',
                    'RSS Rate',
                    'AGE Rate',
                    'Market Rate',
                    'Value',
                    'Fifa Rate',
                    'TotalRate',
                ];

                const options = {
                    ...dataTableOptions,
                    sort: true,
                    filter: true,
                };

                if (error) {

                    return <div className='card card-inverse card-danger'>
                        <div className='card-body'>
                            <h3 className='card-title'>Error fetching data</h3>
                            <p className='card-text'>There was an error downloading the data. Please, try again in a few minutes.</p>
                        </div>
                    </div>;

                } else {
                    return <div className='row'>
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h4 className='card-title'><FormattedMessage id='users.licenses_table.title'/></h4>
                                    <h6 className='card-subtitle'><FormattedMessage id='users.licenses_table.subtitle'/></h6>
                                    <div className='table-responsive'>
                                        <MUIDataTable
                                            data={fixedData}
                                            columns={columns}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>;
                }
            }}
        </Query>
    );
};




interface DispatchProps {
}
//
// interface AllRateProps extends Player, DispatchProps { }

class Rates extends React.Component {

    render() {

        return (
            <div className='container-fluid'>
                <div className='row page-titles'>
                    <div className='col-md-12 align-self-center'>
                        <h3 className='text-themecolor'><FormattedMessage id='users.users' /></h3>
                        <ol className='breadcrumb'>
                            <li className='breadcrumb-item'><FormattedMessage id='users.users' /></li>
                            <li className='breadcrumb-item active'><FormattedMessage id='users.licenses' /></li>
                        </ol>
                    </div>
                </div>
                <RatesQuery />
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
    return {};
};

export default connect<Player, DispatchProps>(
    (store: StoreState) => store.player,
    mapDispatchToProps,
)(Rates);
