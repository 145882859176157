import { ListClubs } from 'crypthum-sdk/dist/schemas/ListClubs';
import { ClubSchema } from 'crypthum-sdk/src';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Club {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  club: StateModel<ClubSchema.Club>;
  clubList: StateModel<ListClubs>;
}

export function getInitialState(): Club {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    club: EmptyStateModel,
    clubList: EmptyStateModel,
  };
}
