export const REQUEST_PLAYERS = 'REQUEST_PLAYERS';
export type REQUEST_PLAYERS = typeof REQUEST_PLAYERS;

export const REQUEST_PLAYERS_FAILED = 'REQUEST_PLAYERS_FAILED';
export type REQUEST_PLAYERS_FAILED = typeof REQUEST_PLAYERS_FAILED;

export const REQUEST_PLAYERS_SUCCESS = 'REQUEST_PLAYERS_SUCCESS';
export type REQUEST_PLAYERS_SUCCESS = typeof REQUEST_PLAYERS_SUCCESS;

export const RECEIVE_PLAYERS = 'RECEIVE_PLAYERS';
export type RECEIVE_PLAYERS = typeof RECEIVE_PLAYERS;

export const REQUEST_PLAYER = 'REQUEST_PLAYER';
export type REQUEST_PLAYER = typeof REQUEST_PLAYER;

export const RECEIVE_PLAYER_FAILED = 'RECEIVE_PLAYER_FAILED';
export type RECEIVE_PLAYER_FAILED = typeof RECEIVE_PLAYER_FAILED;

export const RECEIVE_PLAYER_SUCCESS = 'RECEIVE_PLAYER_SUCCESS';
export type RECEIVE_PLAYER_SUCCESS = typeof RECEIVE_PLAYER_SUCCESS;

export const CREATE_PLAYER = 'CREATE_PLAYER';
export type CREATE_PLAYER = typeof CREATE_PLAYER;

export const CREATE_PLAYER_FAILED = 'CREATE_PLAYER_FAILED';
export type CREATE_PLAYER_FAILED = typeof CREATE_PLAYER_FAILED;

export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export type CREATE_PLAYER_SUCCESS = typeof CREATE_PLAYER_SUCCESS;

export const EDIT_PLAYER = 'EDIT_PLAYER';
export type EDIT_PLAYER = typeof EDIT_PLAYER;

export const EDIT_PLAYER_FAILED = 'EDIT_PLAYER_FAILED';
export type EDIT_PLAYER_FAILED = typeof EDIT_PLAYER_FAILED;

export const EDIT_PLAYER_SUCCESS = 'EDIT_PLAYER_SUCCESS';
export type EDIT_PLAYER_SUCCESS = typeof EDIT_PLAYER_SUCCESS;

export const REQUEST_PLAYER_RATE = 'REQUEST_PLAYER_RATE';
export type REQUEST_PLAYER_RATE = typeof REQUEST_PLAYER_RATE;

export const REQUEST_PLAYER_RATE_FAILED = 'REQUEST_PLAYER_RATE_FAILED';
export type REQUEST_PLAYER_RATE_FAILED = typeof REQUEST_PLAYER_RATE_FAILED;

export const REQUEST_PLAYER_RATE_SUCCESS = 'REQUEST_PLAYER_RATE_SUCCESS';
export type REQUEST_PLAYER_RATE_SUCCESS = typeof REQUEST_PLAYER_RATE_SUCCESS;
