import { CategorySchema } from 'crypthum-sdk';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Category {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  categoryList: StateModel<CategorySchema.Category[]>;
}

export function getInitialState(): Category {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    categoryList: EmptyStateModel
  };
}
