import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestTransactions } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Transaction } from '../../redux/state/transactions';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getTransactions: (limit: number, offset: number) => void;
}

interface ListTransactionProps extends Transaction, DispatchProps {}

class ListTransactions extends React.Component<ListTransactionProps> {
  constructor(public readonly props: ListTransactionProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getTransactions(100, 0);
  }

  render() {
    const columns = [
      {
        name: 'txId',
        label: 'Transaction ID',
      },
      {
        name: 'amount',
        label: 'Amount',
      },
      {
        name: 'description',
        label: 'Description',
      },
      {
        name: 'created',
        label: 'Created',
      },
    ];

    const data: any[] = [];

    if (this.props.transactionList.data) {
      for (const transaction of this.props.transactionList.data.data) {
        data.push({
          txId: transaction.txId,
          amount: transaction.senderId ? '+' + transaction.amount + ' €' : '-' + transaction.amount + ' €',
          description: transaction.description,
          created: transaction.created,
        });
      }
    }

    const count = this.props.transactionList.data ? this.props.transactionList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      sort: true,
      isLoading: this.props.transactionList.isFetching,
      onTableChange: (action, tableState) => {
        this.props.getTransactions(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page);
      },
    };

    let body;

    if (this.props.transactionList.error) {
      body = (
        <div className='card card-inverse card-danger'>
          <div className='card-body'>
            <h3 className='card-title'>Error fetching transactions</h3>
            <p className='card-text'>
              There was an error downloading the transaction list. Please, try again in a few minutes.
            </p>
            <button
              className='btn btn-inverse'
              onClick={() => {
                this.props.getTransactions(100, 0);
              }}
            >
              Try again
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>Transactions</h4>
                <h6 className='card-subtitle'>List transactions</h6>
                <div className='table-responsive'>
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Transactions</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Transactions</li>
              <li className='breadcrumb-item active'>List transactions</li>
            </ol>
          </div>
        </div>

        {body}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getTransactions: (limit: number, offset: number) => dispatch(requestTransactions(limit, offset)),
  };
};

export default connect<Transaction, DispatchProps>(
  (store: StoreState) => store.transaction,
  mapDispatchToProps
)(ListTransactions);
