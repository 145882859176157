import moment from 'moment';

export const asString = (
  reference: React.RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
) => (reference.current as HTMLInputElement).value;

export const asNumber = (reference: React.RefObject<HTMLInputElement>) =>
  parseInt((reference.current as HTMLInputElement).value, 10);

export const asBoolean = (reference: React.RefObject<HTMLInputElement | HTMLSelectElement>) =>
  (reference.current as HTMLInputElement).value === 'true' ? true : false;

export const asDate = (reference: React.RefObject<HTMLInputElement>) =>
  moment((reference.current as HTMLInputElement).value).toDate();

export const asGender = (reference: React.RefObject<HTMLSelectElement>) => {
  const gender = (reference.current as HTMLSelectElement).value;

  switch (gender) {
    case 'MALE':
      return 'MALE';
    case 'FEMALE':
      return 'FEMALE';
    default:
      return 'OTHER';
  }
};

export const asLaterality = (reference: React.RefObject<HTMLSelectElement>) => {
  const laterality = (reference.current as HTMLSelectElement).value;

  switch (laterality) {
    case 'LEFT':
      return 'LEFT';
    case 'RIGHT':
      return 'RIGHT';
    default:
      return 'BOTH';
  }
};

export const asType = (reference: React.RefObject<HTMLSelectElement>) => {
  const type = (reference.current as HTMLSelectElement).value;

  switch (type) {
    case 'PLAYER':
      return 'PLAYER';
    case 'CLUB':
      return 'CLUB';
    default:
      return 'OTHER';
  }
};

export const asStatus = (reference: React.RefObject<HTMLSelectElement>) =>
  (reference.current as HTMLSelectElement).value === 'ENABLED' ? 'ENABLED' : 'DISABLED';

export const asFoundationType = (reference: React.RefObject<HTMLSelectElement>) =>
  (reference.current as HTMLSelectElement).value === 'FOUNDATION' ? 'FOUNDATION' : 'CAUSE';

export const asNewsType = (reference: React.RefObject<HTMLSelectElement>) => {
  const news = (reference.current as HTMLSelectElement).value;

  switch (news) {
    case 'DRAFT':
      return 'DRAFT';
    case 'PUBLISHED':
      return 'PUBLISHED';
    default:
      return 'DELETED';
  }
};
