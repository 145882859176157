import { PresaleAction } from "../actions";
import * as PresaleActionType from "../actions/types/presales";
import { getInitialState, Presale } from "../state/presales";

export const presales = (state: Presale = getInitialState(), action: PresaleAction): Presale => {
  switch (action.type) {
    case PresaleActionType.CREATE_PRESALE:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true,
        },
      };
    case PresaleActionType.CREATE_PRESALE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
        },
      };
    case PresaleActionType.CREATE_PRESALE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error,
        },
      };
    case PresaleActionType.REQUEST_PRESALES:
      return {
        ...state,
        presaleList: {
          ...state.presaleList,
          isFetching: true,
        },
      };
      case PresaleActionType.RECEIVE_PRESALES:
        return {
          ...state,
          presaleList: {
            ...state.presaleList,
            isFetching: false,
            data: action.presales,
          },
        };
    case PresaleActionType.REQUEST_PRESALES_FAILED:
      return {
        ...state,
        presaleList: {
          ...state.presaleList,
          isFetching: false,
          error: action.error,
        },
      };
    case PresaleActionType.REQUEST_ENDED_PRESALES:
      return {
        ...state,
        endedPresaleList: {
          ...state.endedPresaleList,
          isFetching: true,
        },
      };
    case PresaleActionType.RECEIVE_ENDED_PRESALES:
      return {
        ...state,
        endedPresaleList: {
          ...state.endedPresaleList,
          isFetching: false,
          data: action.endedPresales,
        },
      };
    case PresaleActionType.REQUEST_ENDED_PRESALES_FAILED:
      return {
        ...state,
        endedPresaleList: {
          ...state.endedPresaleList,
          isFetching: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
