import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import '../../css/login-register-lock.css';
import * as Router from 'react-router-dom';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {StoreState} from '../../redux/state';
import {Login as LoginState} from '../../redux/state/login';
import {loginAction} from '../../redux/actions/login';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';


interface LoginProps extends LoginState, DispatchProps { }

interface DispatchProps {
  login: (email: string, password: string) => void;
}

class Login extends React.Component<LoginProps> {

  emailRef: React.RefObject<HTMLInputElement> = React.createRef();
  passwordRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(public readonly props: LoginProps) {
    super(props);
  }

  login() {
    this.props.login(GetReactRef.asString(this.emailRef), GetReactRef.asString(this.passwordRef));
  }

  button() {
    if (!this.props.isFetching) {
      return (<FormattedMessage id="login.log_in" />);
    }
    return '. . .';
  }

  error() {
    if (this.props.wrongCredentials) {
      return (
          <div className='card card-inverse card-danger'>
            <div className='card-body'>
              <p className='card-text'>Wrong credentials.</p>
            </div>
          </div>
      );
    }
    return null;
  }

  render() {
    if (this.props.logged) {
      /*
       TODO
       Cambiar a:
       return (<Router.Redirect to='/dashboard' />);
       cuando no dependamos de jquery
      */
      (window as any)['location'] = '/';
      return (<div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>);
    }
    return (
      <section id="wrapper">
        <div className="login-register" style={{ backgroundImage: 'url(' + require("../../images/background/login-register.jpg") + ')' }}>
          <div className="login-box card">
            <div className="card-body">
              <form className="form-horizontal form-material" onSubmit={(e) => {
                e.preventDefault(); this.login();
              }}>
                { this.error() }
                <h3 className="box-title m-b-20"><FormattedMessage id="login.sign_in" /></h3>
                <div className="form-group ">
                  <div className="col-xs-12">
                    <FormattedMessage id="login.email">
                      {(id) => <input ref={this.emailRef} className="form-control" type="email" required placeholder={id as string} />}
                    </FormattedMessage>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <FormattedMessage id="login.password">
                      {(id) => <input ref={this.passwordRef} className="form-control" type="password" required placeholder={id as string} />}
                    </FormattedMessage>
                  </div>
                </div>
                <div className="form-group text-center">
                  <div className="col-xs-12">
                    <button type='submit' className='btn btn-block btn-lg btn-info btn-rounded'>
                      { this.button() }
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    login: (email: string, password: string) => dispatch(loginAction(email, password)),
  };
};

export default connect<LoginState, DispatchProps>(
    (store: StoreState) => store.login,
    mapDispatchToProps,
)(Login);
