import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestPresales } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Presale } from '../../redux/state/presales';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getPresales: (limit: number, offset: number, query: string) => void;
}

interface ListPresaleProps extends Presale, DispatchProps {}

class ListPresales extends React.Component<ListPresaleProps> {
  constructor(public readonly props: ListPresaleProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getPresales(100, 0, '');
  }

  render() {
    const columns = [
      {
        name: 'id',
        label: 'ID',
      },
      {
        name: 'player',
        label: 'Player',
      },
      {
        name: 'price',
        label: 'Price (€)',
      },
      {
        name: 'total',
        label: 'Total (€)',
      },
      {
        name: 'total24',
        label: 'Total 24h (€)',
      },
      {
        name: 'start',
        label: 'Start',
      },
      {
        name: 'end',
        label: 'End',
      },
      {
        name: 'solidary',
        label: 'Solidary',
      },
    ];

    const data: any[] = [];

    if (this.props.presaleList.data) {
      for (const item of this.props.presaleList.data.data) {
        data.push({
          id: item.id,
          player: item.pack.items[0].user.name + ' ' + item.pack.items[0].user.surname,
          price: item.price,
          total: item.price * item.pack.items[0].total,
          total24: item.price * item['last24'],
          start: moment(item.start).format('YYYY-MM-DD HH:mm'),
          end: moment(item.end).format('YYYY-MM-DD HH:mm'),
          solidary: item.foundationBeneficiaryPercentage + '%',
        });
      }
    }

    const count = this.props.presaleList.data ? this.props.presaleList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      sort: true,
      isLoading: this.props.presaleList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getPresales(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      },
    };

    let body;

    if (this.props.presaleList.error) {
      body = (
        <div className='card card-inverse card-danger'>
          <div className='card-body'>
            <h3 className='card-title'>Error fetching presales</h3>
            <p className='card-text'>
              There was an error downloading the presale list. Please, try again in a few minutes.
            </p>
            <button
              className='btn btn-inverse'
              onClick={() => {
                this.props.getPresales(10, 0, '');
              }}
            >
              Try again
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>Presales</h4>
                <h6 className='card-subtitle'>List presales</h6>
                <div className='table-responsive'>
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Presales</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Presales</li>
              <li className='breadcrumb-item active'>List presales</li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/presales/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle' /> Create Presale
              </button>
            </Link>
          </div>
        </div>

        {body}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getPresales: (limit: number, offset: number, query: string) =>
      dispatch(requestPresales(limit, offset, query)),
  };
};

export default connect<Presale, DispatchProps>(
  (store: StoreState) => store.presale,
  mapDispatchToProps
)(ListPresales);
