import { CategorySchema, NewCategorySchema } from 'crypthum-sdk';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';
import { createCategoryAction, editCategoryAction, requestCategories } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Category } from '../../redux/state/categories';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';

interface DispatchProps {
  createCategory: (request: NewCategorySchema.NewCategory) => void;
  editCategory: (id: number, request: NewCategorySchema.NewCategory) => void;
  getCategories: () => void;
}

interface AddEditCategoryProps extends Category, DispatchProps { }

class AddEditCategory extends React.Component<AddEditCategoryProps, RouteProps> {

  parentCategorySelector: React.RefObject<Select> = React.createRef();
  status: React.RefObject<HTMLSelectElement> = React.createRef();
  categoryName: React.RefObject<HTMLInputElement> = React.createRef();

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddEditCategoryProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {}
    };

    this.validationService = addEditValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        status: this.validationService.getLastError('status'),
        categoryName: this.validationService.getLastError('categoryName')
      }
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  private sendCategoryForm() {

    const parentCategorySelectorValues = this.parentCategorySelector.current.state.value.map((option) => option.value);
    const lang = new Map();

    lang.set('en', GetReactRef.asString(this.categoryName));

    const categoryContent = new NewCategorySchema.NewCategory(
      GetReactRef.asBoolean(this.status),
      parentCategorySelectorValues,
      lang,
    );

    if (this.props['match']['params']['id']) {
      this.props.editCategory(
        this.props['match']['params']['id'],
        categoryContent
      );
    } else {
      this.props.createCategory(
        categoryContent
      );
    }
  }

  getCategoriesOptions = () => this.props.categoryList.data
    ? this.props.categoryList.data.map(
      val => ({ value: val.id, label: val.name })
    )
    : []

  private retrieveCategoryToEdit(id: number) {

    const categoryList = this.props.categoryList.data ? this.props.categoryList.data : [];

    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].id === id) {
        return categoryList[i];
      }
    }

    return null;
  }

  submit(e) {

    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('status', GetReactRef.asString(this.status));
    this.validationService.updateValue('categoryName', GetReactRef.asString(this.categoryName));

    if (this.handleValidation()) {
      this.sendCategoryForm();
    }
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  private retrieveCategoryIds(categories: number[]) {
    const categoryIds: any[] = [];
    let i: number;

    for (i = 0; i < categories.length; i++) {
      const category = this.retrieveCategoryToEdit(categories[i]);
      if (category !== null) {
        categoryIds.push({ value: category.id, label: category.name });
      }
    }

    return categoryIds;
  }

  render() {

    if (this.props.categoryList.data === null && !this.props.categoryList.isFetching) {
      this.props.getCategories();
    }

    if (this.props.categoryList.isFetching) {
      return <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;
    }

    const idToEdit: number = this.props['match']['params']['id'];
    let categoryData: CategorySchema.Category | null = null;

    if (idToEdit) {
      categoryData = this.retrieveCategoryToEdit(idToEdit);
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='categories.categories' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='categories.categories' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='categories.add_edit_category' /></li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>{(idToEdit) ? 'Edit' : 'Create'} Category</h3>

                    <hr />

                    <fieldset>

                      <div className='row p-t-20'>

                        <div className='col-md-8'>
                          <div className='form-group'>
                            <label className='control-label'>Parent Categories <small>(if want a root category, leave empty)</small></label>
                            <Select className='select2 m-b-10 select2-multiple' ref={this.parentCategorySelector} style={{ width: '100%' }}
                              defaultValue={(categoryData) ? this.retrieveCategoryIds(categoryData.parents) : []} isMulti
                              options={this.getCategoriesOptions()} />
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['status'] ? ' error' : '')}>
                            <label className='control-label'>Status</label>
                            <select onChange={this.handleChange.bind(this, 'status')} value={this.state.fields['status']}
                              ref={this.status} defaultValue={(categoryData) ? categoryData.enabled.toString() : ''}
                              className='form-control custom-select'>
                              <option value='' disabled>-- Select status --</option>
                              <option value='true'>Enabled</option>
                              <option value='false'>Disabled</option>
                            </select>
                            <span className='error-message'>{this.state.errors['status']}</span>
                          </div>
                        </div>

                      </div>

                      <div className='row'>
                        <div className='col-md-8'>
                          <div className={'form-group' + (this.state.errors['categoryName'] ? ' error' : '')}>
                            <label className='control-label'>Category Name</label>
                            <input onChange={this.handleChange.bind(this, 'categoryName')} value={this.state.fields['categoryName']}
                              ref={this.categoryName} defaultValue={(categoryData) ? categoryData.name : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['categoryName']}</span>
                          </div>
                        </div>
                      </div>

                    </fieldset>

                  </div>

                  <div className='form-actions text-right'>

                    <Link to='/categories'>
                      <button type='button' className='btn btn-info m-r-10'>Cancel</button>
                    </Link>

                    <button disabled={this.props.categoryList.isFetching} className='btn btn-success' type='submit'>Save Changes</button>

                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    createCategory: (request: NewCategorySchema.NewCategory) => dispatch(
      createCategoryAction(request)
    ),
    editCategory: (id: number, request: NewCategorySchema.NewCategory) => dispatch(
      editCategoryAction(id, request)
    ),
    getCategories: () => dispatch(requestCategories()),
  };
};

export default connect<Category, DispatchProps>(
  (store: StoreState) => store.category,
  mapDispatchToProps,
)(AddEditCategory);
