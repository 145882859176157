import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'fullName',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Full name cannot be empty'
      }
    ]
  },
  {
    name: 'birthdate',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Birthdate cannot be empty'
      }
    ]
  },
  {
    name: 'birthPlace',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Birthplace cannot be empty'
      }
    ]
  },
  {
    name: 'nationality',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Nationality cannot be empty'
      }
    ]
  },
  {
    name: 'position',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Position cannot be empty'
      }
    ]
  },
  {
    name: 'laterality',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a laterality option'
      }
    ]
  },
  {
    name: 'type',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a type option'
      }
    ]
  },
  {
    name: 'actualClub',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Actual club cannot be empty'
      }
    ]
  },
  {
    name: 'contractUntil',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Contract until cannot be empty'
      }
    ]
  },
  {
    name: 'lastRenovation',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Last renovation cannot be empty'
      }
    ]
  },
  {
    name: 'instragram',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Instragram cannot be empty'
      }
    ]
  },
  {
    name: 'twitter',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Twitter cannot be empty'
      }
    ]
  },
  {
    name: 'youtube',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Youtube cannot be empty'
      }
    ]
  },
  {
    name: 'facebook',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Facebook cannot be empty'
      }
    ]
  },
  {
    name: 'linkedin',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Linkedin cannot be empty'
      }
    ]
  },
  {
    name: 'shirtMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Shirt cannot be empty'
      }
    ]
  },
  {
    name: 'backgroundMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Background cannot be empty'
      }
    ]
  },
  {
    name: 'emblemMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Emblem cannot be empty'
      }
    ]
  },
  {
    name: 'playerIntroMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Player intro cannot be empty'
      }
    ]
  },
  {
    name: 'playerIdleMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Player idle cannot be empty'
      }
    ]
  }
]);
