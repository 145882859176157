import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { StoreState } from '../state';
import { categories } from './categories';
import { clubs } from './clubs';
import { fanzone } from './fanzone';
import { foundations } from './foundations';
import { global } from './global';
import { items } from './items';
import { languages } from './languages';
import { login } from './login';
import { news } from './news';
import { players } from './players';
import { presales } from './presales';
import { roles } from './roles';
import { transactions } from './transactions';
import { users } from './users';

const rootReducer = (history: History) =>
  combineReducers<StoreState>({
    router: connectRouter(history),
    club: clubs,
    player: players,
    user: users,
    rol: roles,
    foundation: foundations,
    category: categories,
    item: items,
    language: languages,
    fanzone: fanzone,
    login: login,
    news: news,
    presale: presales,
    transaction: transactions,
    global: global,
  });

export default rootReducer;
