import { ClubAction } from '../actions';
import * as ClubActionType from '../actions/types/clubs';
import { Club, getInitialState } from '../state/clubs';

export const clubs = (state: Club = getInitialState(), action: ClubAction): Club => {
  switch (action.type) {
    case ClubActionType.CREATE_CLUB:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true,
        },
      };
    case ClubActionType.CREATE_CLUB_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
        },
      };
    case ClubActionType.CREATE_CLUB_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error,
        },
      };
    case ClubActionType.EDIT_CLUB:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true,
        },
      };
    case ClubActionType.EDIT_CLUB_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
        },
      };
    case ClubActionType.EDIT_CLUB_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error,
        },
      };
    case ClubActionType.REQUEST_CLUBS:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          isFetching: true,
        },
      };
    case ClubActionType.REQUEST_CLUBS_FAILED:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          isFetching: false,
          error: action.error,
        },
      };
    case ClubActionType.RECEIVE_CLUBS:
      return {
        ...state,
        clubList: {
          ...state.clubList,
          isFetching: false,
          data: action.clubs,
        },
      };
    case ClubActionType.REQUEST_CLUB:
      return {
        ...state,
        club: {
          ...state.club,
          isFetching: true,
        },
      };
    case ClubActionType.REQUEST_CLUB_FAILED:
      return {
        ...state,
        club: {
          ...state.club,
          isFetching: false,
          error: action.error,
        },
      };
    case ClubActionType.REQUEST_CLUB_SUCCESS:
      return {
        ...state,
        club: {
          ...state.club,
          isFetching: false,
        },
      };
    case ClubActionType.RECEIVE_CLUB:
      return {
        ...state,
        club: {
          ...state.club,
          isFetching: false,
          data: action.club,
        },
      };
    default:
      return state;
  }
};
