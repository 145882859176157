import {LoginAction} from '../actions/login';
import * as LoginActionType from '../actions/types/login';
import {getInitialState, Login} from '../state/login';

export const login = (state: Login = getInitialState(), action: LoginAction): Login => {
  switch (action.type) {
    case LoginActionType.LOGIN:
      return {
        ...state,
        isFetching: true,
        wrongCredentials: false,
        logged: false,
      };
    case LoginActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        wrongCredentials: false,
        logged: true,
      };
    case LoginActionType.LOGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        wrongCredentials: true,
        logged: false,
      };
    default:
      return state;
  }
};
