import { EmptyStateModel, StateModel } from './types/state.model';

export interface Language {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  languageList: StateModel<Map<any, any>>;
  i18nKeyList: StateModel<Map<any, any>>;
}

export function getInitialState(): Language {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    languageList: EmptyStateModel,
    i18nKeyList: EmptyStateModel
  };
}
