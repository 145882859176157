export const REQUEST_FOUNDATIONS = 'REQUEST_FOUNDATIONS';
export type REQUEST_FOUNDATIONS = typeof REQUEST_FOUNDATIONS;

export const REQUEST_FOUNDATIONS_FAILED = 'REQUEST_FOUNDATIONS_FAILED';
export type REQUEST_FOUNDATIONS_FAILED = typeof REQUEST_FOUNDATIONS_FAILED;

export const REQUEST_FOUNDATIONS_SUCCESS = 'REQUEST_FOUNDATIONS_SUCCESS';
export type REQUEST_FOUNDATIONS_SUCCESS = typeof REQUEST_FOUNDATIONS_SUCCESS;

export const RECEIVE_FOUNDATIONS = 'RECEIVE_FOUNDATIONS';
export type RECEIVE_FOUNDATIONS = typeof RECEIVE_FOUNDATIONS;

export const REQUEST_FOUNDATION = 'REQUEST_FOUNDATION';
export type REQUEST_FOUNDATION = typeof REQUEST_FOUNDATION;

export const REQUEST_FOUNDATION_FAILED = 'REQUEST_FOUNDATION_FAILED';
export type REQUEST_FOUNDATION_FAILED = typeof REQUEST_FOUNDATION_FAILED;

export const REQUEST_FOUNDATION_SUCCESS = 'REQUEST_FOUNDATION_SUCCESS';
export type REQUEST_FOUNDATION_SUCCESS = typeof REQUEST_FOUNDATION_SUCCESS;

export const RECEIVE_FOUNDATION = 'RECEIVE_FOUNDATION';
export type RECEIVE_FOUNDATION = typeof RECEIVE_FOUNDATION;

export const CREATE_FOUNDATION = 'CREATE_FOUNDATION';
export type CREATE_FOUNDATION = typeof CREATE_FOUNDATION;

export const CREATE_FOUNDATION_FAILED = 'CREATE_FOUNDATION_FAILED';
export type CREATE_FOUNDATION_FAILED = typeof CREATE_FOUNDATION_FAILED;

export const CREATE_FOUNDATION_SUCCESS = 'CREATE_FOUNDATION_SUCCESS';
export type CREATE_FOUNDATION_SUCCESS = typeof CREATE_FOUNDATION_SUCCESS;

export const EDIT_FOUNDATION = 'EDIT_FOUNDATION';
export type EDIT_FOUNDATION = typeof EDIT_FOUNDATION;

export const EDIT_FOUNDATION_FAILED = 'EDIT_FOUNDATION_FAILED';
export type EDIT_FOUNDATION_FAILED = typeof EDIT_FOUNDATION_FAILED;

export const EDIT_FOUNDATION_SUCCESS = 'EDIT_FOUNDATION_SUCCESS';
export type EDIT_FOUNDATION_SUCCESS = typeof EDIT_FOUNDATION_SUCCESS;
