import { Field, isEmptyText, ValidationError } from './validation.tools';

interface Errors {
  globalStatus: boolean;
  list: ValidationError[];
}

export class ValidationService {

  private fields: Field[];
  private fieldsIndexes: string[];
  private errors: Errors;

  constructor(
    fields: Field[]
  ) {
    this.fields = fields;
    this.fieldsIndexes = fields.map(field => field.name);
    this.errors = {
      globalStatus: true,
      list: this.fields.map(field => ({
        field: field.name,
        status: true,
        errors: field.validations.map(validation => ({
          type: validation.type.name,
          status: true,
          message: validation.message
        }))
      }))
    };
  }

  validate(fieldName?: string): void {
    if (fieldName) {
      this.validateField(this.getField(fieldName));
    } else {
      this.fields.map(field => this.validateField(field));
    }
  }

  updateValue(fieldName: string, value: string) {
    const field = this.fields[this.fieldsIndexes.indexOf(fieldName)];

    if (!field) {
      throw new Error(`field '${fieldName}' not found`);
    }

    field.value = value;
  }

  getErrors(fieldName: string): ValidationError {
    return this.getError(fieldName);
  }

  getAllErrors(): Errors {
    return this.errors;
  }

  getLastError(fieldName: string): string | null {
    const validationError = this.getError(fieldName);

    if (validationError.status) {
      return null;
    } else {
      let lastError: string | null = null;
      validationError.errors.forEach(error => {
        if (!error.status) {
          lastError = error.message;
        }
      });

      return lastError;
    }
  }

  private getField(name: string): Field {
    const field = this.fields[this.fieldsIndexes.indexOf(name)];

    if (!field) {
      throw new Error(`field '${name}' not found`);
    }

    return field;
  }

  private getError(name: string): ValidationError {
    const error = this.errors.list[this.fieldsIndexes.indexOf(name)];

    if (!error) {
      throw new Error(`error '${name}' not found`);
    }

    return error;
  }

  private validateField(field: Field): void {
    switch (field.type) {
      case 'text':
        this.validateTextField(field);
        break;
      case 'select':
        this.validateSelectField(field);
        break;
      default:
        throw new Error(`field type '${field.type}' not exists`);
    }
  }

  private validateTextField(field: Field): void {
    this.validateFieldValue(field.name, field.value, field.type);
  }

  private validateSelectField(field: Field): void {
    this.validateFieldValue(field.name, field.value ? field.value['value'].toString() : undefined, field.type);
  }

  private validateFieldValue(name: string, value: string | undefined, type: string): void {
    const validationError = this.getError(name);

    validationError.errors.forEach(error => {
      switch (error.type) {
        case 'notEmpty':
          error.status = this.validateNotEmptyText(value);
          break;
        default:
          throw new Error(`validation type '${type}' not exists`);
      }
    });

    validationError.status = this.getValidationErrorStatus(validationError);
    this.errors.globalStatus = this.getValidationErrorsStatus(this.errors.list);
  }

  private getValidationErrorStatus(field: ValidationError) {
    let status = true;
    field.errors.forEach(error => {
      if (!error.status) {
        status = false;
      }
    });

    return status;
  }

  private getValidationErrorsStatus(field: ValidationError[]) {
    let status = true;
    field.forEach(fieldName => {
      if (!fieldName.status) {
        status = false;
      }
    });

    return status;
  }

  private validateNotEmptyText(value: string | undefined): boolean {
    return !isEmptyText(value);
  }
}
