import { NewLang } from 'crypthum-sdk/dist/schemas/NewLang';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { createLanguageAction } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Language } from '../../redux/state/languages';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';

interface DispatchProps {
  createLanguage: (request: NewLang) => void;
}

interface AddEditLanguageProps extends Language, DispatchProps { }

class AddEditLanguages extends React.Component<AddEditLanguageProps, RouteProps> {
  codeRef: React.RefObject<HTMLInputElement> = React.createRef();
  nameRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(public readonly props: AddEditLanguageProps) {
    super(props);
  }

  private sendLanguageForm() {
    this.props.createLanguage(
      new NewLang(
        GetReactRef.asString(this.codeRef),
        GetReactRef.asString(this.nameRef)
      )
    );
  }

  render() {
    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='languages.languages' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='languages.languages' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='languages.add_edit_language' /></li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form action='#'>
                  <div className='form-body'>
                    <h3 className='card-title'>Create Language</h3>
                    <hr />
                    <div className='row p-t-20'>
                      <div className='col-md-3'>
                        <div className='form-group'>
                          <label className='control-label'>Language Code</label>
                          <input type='text' ref={this.codeRef} className='form-control' />
                        </div>
                      </div>
                      <div className='col-md-9'>
                        <div className='form-group'>
                          <label className='control-label'>Language Name</label>
                          <input type='text' ref={this.nameRef} className='form-control' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-actions text-right'>
                    <Link to='/languages'>
                      <button type='button' className='btn btn-info m-r-10'>Cancel</button>
                    </Link>
                    <button type='submit' onClick={() => { this.sendLanguageForm(); }} className='btn btn-success'>Create</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    createLanguage: (request: NewLang) => dispatch(
      createLanguageAction(request)
    )
  };
};

export default connect<Language, DispatchProps>(
  (store: StoreState) => store.language,
  mapDispatchToProps,
)(AddEditLanguages);
