import { NewRolSchema, RolSchema } from 'crypthum-sdk';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import Select from 'react-select';
import { WithContext as ReactTags } from 'react-tag-input';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  addUserToRol,
  CloseAddModal as closeAddModal,
  closeEditModal,
  createRolAction,
  editRolAction,
  openAddModal,
  openEditModal,
  removeUserToRol,
  requestActions,
  requestRoles,
} from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Rol } from '../../redux/state/roles';
import { dataTableOptions } from '../ui/DataTableOptions';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';

const $ = require('jquery');
$.select2 = require('select2');

interface DispatchProps {
  getRoles: () => void;
  getActions: () => void;
  editRole: (id: number, request: NewRolSchema.NewRol) => void;
  createRol: (request: NewRolSchema.NewRol) => void;
  openAddModal: () => void;
  closeAddEditModal: () => void;
  openEditModal: (rol: RolSchema.Rol) => void;
  addUserToRol: (email: string) => void;
  removeUserToRol: (emails: string[]) => void;
}

interface AllRolesProps extends Rol, DispatchProps { }

class AllRoles extends React.Component<AllRolesProps> {
  roleNameRef: React.RefObject<HTMLInputElement> = React.createRef();
  roleUsersRef: React.RefObject<HTMLInputElement> = React.createRef();
  rolActionsCheckRef: React.RefObject<Select> = React.createRef();
  rolesTableRef: React.RefObject<HTMLTableElement> = React.createRef();
  addEditModalRef: React.RefObject<HTMLInputElement> = React.createRef();
  modalError = '';

  constructor(public readonly props: AllRolesProps) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  componentDidMount() {
    this.props.getRoles();
    this.props.getActions();

    $(this.addEditModalRef.current).on('hidden.bs.modal', () => {
      this.props.closeAddEditModal();
      this.modalError = '';
      (this.roleNameRef.current as HTMLInputElement).value = '';
    });
  }

  componentDidUpdate() {
    if (this.props.addModal || this.props.editModal !== null) {
      $(this.addEditModalRef.current).modal('show');
    } else if ($(this.addEditModalRef.current).hasClass('show')) {
      $(this.addEditModalRef.current).modal('hide');
    }
  }

  private sendRolForm() {
    const roleData = new NewRolSchema.NewRol(
      GetReactRef.asString(this.roleNameRef),
      this.rolActionsCheckRef.current.state.value.map((option) => option.value),
      this.props.roleUsers.map(tag => tag.text),
    );

    if (this.props.editModal) {
      this.props.editRole(this.props.editModal.id, roleData);
    } else {
      this.props.createRol(roleData);
    }
  }

  private handleAddition(tag: { id: string, text: string }) {
    this.props.roleUsers.push(tag);
  }

  private handleDelete(tagIndex: number) {
    this.props.roleUsers.splice(tagIndex, 1);
  }

  private renderAddEditModal() {

    const actionsList = this.props.actionList.data ? this.props.actionList.data.map(val =>
      ({ value: val, label: val })
    ) : [];

    const editActionsList = this.props.editModal ? this.props.editModal.actions.map(val =>
      ({ value: val, label: val })
    ) : [];

    if (this.roleNameRef.current) {
      (this.roleNameRef.current as HTMLInputElement).value = this.props.editModal
        ? this.props.editModal.name.valueOf() : (this.roleNameRef.current as HTMLInputElement).value;
    }

    let actions: ReactTags;

    if (this.props.editModal === null && this.props.addModal || this.props.editModal !== null && this.props.editModal.actions.length > 0) {
      actions = <Select id='editRolePermissions' className='basic-multi-select' isMulti ref={this.rolActionsCheckRef}
        style={{ width: '100%' }} options={actionsList} defaultValue={editActionsList} />;
    }

    return (
      <form>
        <div className='form-group'>
          <div className='row m-b-10'>
            <div className='col-md-12'>
              <label htmlFor='roleName' className='control-label'>Name</label>
              <input type='text' className='form-control' id='roleName' ref={this.roleNameRef} />
            </div>
          </div>

          <div className='row m-b-10'>
            <div className='col-md-12'>
              <label htmlFor='roleUsers' className='control-label'>Users</label>
              <ReactTags className='form-control' inputFieldPosition='inline' id='roleUsers' ref={this.roleUsersRef}
                handleAddition={(tag: { id: string, text: string }) => this.handleAddition(tag)}
                handleDelete={(tagIndex: number) => this.handleDelete(tagIndex)}
                allowDragDrop={false} tags={this.props.roleUsers} />
              <div className='text-error'>{this.modalError}</div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <label htmlFor='editRolePermissions' className='control-label'>Permissions</label>
              {actions}
            </div>
          </div>
        </div>
      </form>
    );
  }

  render() {

    if (this.props.create.error || this.props.edit.error) {
      let errorObject;
      if (this.props.create.error) {
        errorObject = JSON.parse(this.props.create.error);
      }
      if (this.props.edit.error) {
        errorObject = JSON.parse(this.props.edit.error);
      }
      if (errorObject.statusCode == 404) {
        this.modalError = 'Emails not found: ' + errorObject.message;
      }
    } else {
      this.modalError = '';
    }

    const columns = [
      {
        name: 'roleName',
        label: 'Role Name'
      }, {
        name: 'users',
        label: 'Users'
      }, {
        name: 'permissions',
        label: 'Permissions'
      }, {
        name: 'actions',
        label: 'Actions'
      }
    ];

    const data: any[] = [];

    if (this.props.rolList.data) {
      for (const rol of this.props.rolList.data) {
        data.push({
          roleName: rol.name,
          users: rol.users.length,
          permissions: rol.actions.length,
          actions: <div>
            <button className='btn btn-success waves-effect waves-light'
              onClick={() => this.props.openEditModal(rol)} type='button'>
              <i className='fa fa-pencil-alt'></i>
            </button>
            <button className='btn btn-googleplus waves-effect waves-light m-l-10' data-id={`${rol.id}`}
              type='button' data-toggle='modal' data-target='#deleteRoleModal'>
              <i className='fa fa-trash-alt'></i>
            </button>
          </div>
        });
      }
    }

    const options = {
      ...dataTableOptions,
      customSearch: undefined,
    };

    let body;

    if (this.props.rolList.isFetching) {

      body = <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;

    } else if (this.props.rolList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching roles</h3>
          <p className='card-text'>There was an error downloading the rol list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getRoles(); }}>Try again</button>
        </div>
      </div>;

    } else {

      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='roles.rol_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='roles.rol_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    const modalAction = this.props.addModal ? 'Create' : 'Edit';

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='roles.roles' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='roles.roles' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='roles.list_roles' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <button type='button' className='btn btn-info' onClick={() => this.props.openAddModal()}>
              <i className='fa fa-plus-circle'></i> <FormattedMessage id='roles.create_rol' />
            </button>
          </div>
        </div>

        <h1>{this.props.actionList !== null ? this.props.actionList[0] : 'no hay nada'}</h1>

        {body}

        <div ref={this.addEditModalRef} className='modal fade' data-backdrop='static'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title' id='myModalLabel'>{modalAction} role</h4>
                <button type='button' className='close' data-dismiss='modal' aria-hidden='true'>×
                </button>
              </div>
              <div className='modal-body'>
                {this.renderAddEditModal()}
              </div>
              <div className='modal-footer'>
                <button type='button' onClick={() => this.props.closeAddEditModal()}
                  className='zindex0 btn btn-danger waves-effect' data-dismiss='modal'>Close
                </button>
                <button type='button' onClick={() => this.sendRolForm()}
                  className='zindex0 btn btn-info waves-effect'>{modalAction}</button>
              </div>
            </div>
          </div>
        </div>

        <div id='deleteRoleModal' className='modal fade' tabIndex={-1} role='dialog'
          aria-labelledby='myModalLabel' aria-hidden='true'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title' id='myModalLabel'>Delete role</h4>
                <button type='button' className='close' data-dismiss='modal' aria-hidden='true'>×
                </button>
              </div>
              <div className='modal-body'>
                Se va a eliminar definitivamente el rol <b>"Admin"</b> del sistema. Si haces esto
                tendrás que asignar un nuevo rol a los usuarios que lo ultizan.
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-info waves-effect' data-dismiss='modal'>Close
                </button>
                <button type='button' className='btn btn-danger waves-effect'
                  data-dismiss='modal'>Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getRoles: () => dispatch(requestRoles()),
    getActions: () => dispatch(requestActions()),
    createRol: (request: NewRolSchema.NewRol) => dispatch(createRolAction(request)),
    editRole: (id: number, request: NewRolSchema.NewRol) => dispatch(editRolAction(id, request)),
    openAddModal: () => dispatch(openAddModal()),
    openEditModal: (rol: RolSchema.Rol) => dispatch(openEditModal(rol)),
    closeAddEditModal: () => {
      dispatch(closeAddModal());
      dispatch(closeEditModal());
    },
    addUserToRol: (email: string) => dispatch(addUserToRol(email)),
    removeUserToRol: (emails: string[]) => dispatch(removeUserToRol(emails))
  };
};

export default connect<Rol, DispatchProps>(
  (store: StoreState) => store.rol,
  mapDispatchToProps,
)(AllRoles);
