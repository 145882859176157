import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { NewItem } from 'crypthum-sdk/dist/schemas/NewItem';
import { NotificationManager } from 'react-notifications';
import ItemForm from 'src/entities/ItemForm';

import { SDKProvider } from '../components/providers/SDKProvider';

declare var imageCompression: any;

export const getNewItemAndUploadMedia = async (itemForm: ItemForm) => {
  const cryptoMuseumsSDK = SDKProvider.get();

  let media;

  if (itemForm.media && itemForm.media.file) {

    try {
      media = (await cryptoMuseumsSDK.media.upload(new CreateMedia(itemForm.media.file.name, false, itemForm.media.file))).id;
    } catch (e) {
      NotificationManager.error('Error al subir el media');
    }
  } else {
    media = itemForm.media;
  }


  let mediaVideo;

  if (itemForm.mediaVideo && itemForm.mediaVideo.file) {
    try {
      mediaVideo = (await cryptoMuseumsSDK.media.upload(
        new CreateMedia(itemForm.mediaVideo.file.name, false, itemForm.mediaVideo.file)
      )).id;
    } catch (e) {
      NotificationManager.error('Error al subir el media video');
    }
  } else {
    mediaVideo = itemForm.mediaVideo;
  }

  let mediaLeague;

  if (itemForm.leagueMedia && itemForm.leagueMedia.file) {
    try {
      mediaLeague = (await cryptoMuseumsSDK.media.upload(
        new CreateMedia(itemForm.leagueMedia.file.name, false, itemForm.leagueMedia.file)
      )).id;
    } catch (e) {
      NotificationManager.error('Error al subir el media league');
    }
  } else {
    mediaLeague = itemForm.leagueMedia;
  }

  return new NewItem(
    itemForm.type,
    itemForm.playerId,
    itemForm.foundationId,
    itemForm.name,
    itemForm.description,
    itemForm.categoryId,
    media,
    media,
    mediaVideo,
    itemForm.leagueName,
    mediaLeague,
    itemForm.clubId,
    itemForm.clubGoals,
    itemForm.visitorClubId,
    itemForm.visitorClubGoals,
    itemForm.userId,
    itemForm.additionalInformation,
    itemForm.created
  );
};
