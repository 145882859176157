export interface Field {
  name: string;
  type: fieldType;
  value?: any;
  validations: {
    type: validationType,
    message: string
  }[];
}

export interface ValidationError {
  field: string;
  status: boolean;
  errors: {
    type: string;
    status: boolean;
    message: string;
  }[];
}

interface ValidationType {
  name: string;
  args?: {
    min?: number,
    max?: number,
    otherValue?: string
  };
}

interface NotEmptyType extends ValidationType {
  name: 'notEmpty';
}

export const isEmptyText = (value: string | number | undefined) => value === undefined || value === null || value === '';

export type fieldType = 'text' | 'select';

type validationType = NotEmptyType;
