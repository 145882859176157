import { ValidationService } from 'src/services/validation/validation.service';

export const addMomentValidations = new ValidationService([
    {
        name: 'video',
        type: 'text',
        value: '',
        validations: [
          {
            type: { name: 'notEmpty' },
            message: 'You have to upload a video'
          }
        ]
      }, {
        name: 'userId',
        type: 'text',
        value: '',
        validations: [
          {
            type: { name: 'notEmpty' },
            message: 'User cannot be empty'
          }
        ]
      }, {
        name: 'itemId',
        type: 'text',
        value: '',
        validations: []
      },
]);
