import { push } from 'connected-react-router';
import {
  CreateNews as CreateNewsEndpoint,
  CreateNewsSchema,
  EditNews as EditNewsEndpoint,
  EditNewsSchema,
  GetNews,
  GetOneNews,
} from 'crypthum-sdk';
import { ListNews } from 'crypthum-sdk/dist/schemas/ListNews';
import { News } from 'crypthum-sdk/dist/schemas/News';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getNews = async (limit: number, offset: number, query: string): Promise<ListNews> =>
  await cryptoMuseumsSDK.endpoint<GetNews>(GetNews).run({
    limit: limit,
    offset: offset,
    query: query,
  });

const getNewsById = async (id: number): Promise<News> =>
  await cryptoMuseumsSDK.endpoint<GetOneNews>(GetOneNews).run({
    id: id,
  });

const createNews = async (body: CreateNewsSchema.CreateNews) =>
  await cryptoMuseumsSDK.endpoint<CreateNewsEndpoint>(CreateNewsEndpoint).run({
    body: body,
  });

const editNews = async (id: number, body: EditNewsSchema.EditNews) =>
  await cryptoMuseumsSDK.endpoint<EditNewsEndpoint>(EditNewsEndpoint).run({
    id: id,
    body: body,
  });

export interface CreateNews {
  type: types.CREATE_NEWS;
}

export interface CreateNewsFailed {
  type: types.CREATE_NEWS_FAILED;
  error: string;
}

export interface CreateNewsSuccess {
  type: types.CREATE_NEWS_SUCCESS;
}

export interface EditNews {
  type: types.EDIT_NEWS;
}

export interface EditNewsFailed {
  type: types.EDIT_NEWS_FAILED;
  error: string;
}

export interface EditNewsSuccess {
  type: types.EDIT_NEWS_SUCCESS;
}

export interface RequestNews {
  type: types.REQUEST_NEWS;
}

export interface RequestNewsFailed {
  type: types.REQUEST_NEWS_FAILED;
  error: string;
}

export interface RequestNewsSuccess {
  type: types.REQUEST_NEWS_SUCCESS;
}

export interface ReceiveNews {
  type: types.RECEIVE_NEWS;
  news: ListNews;
}

export interface RequestNewsById {
  type: types.REQUEST_NEWS_BY_ID;
}

export interface ReceiveNewsByIdSuccess {
  type: types.RECEIVE_NEWS_BY_ID_SUCCESS;
  news: News;
}

export interface ReceiveNewsByIdFailed {
  type: types.RECEIVE_NEWS_BY_ID_FAILED;
  error: string;
}

export type NewsAction =
  | RequestNews
  | RequestNewsSuccess
  | RequestNewsFailed
  | ReceiveNews
  | CreateNews
  | CreateNewsFailed
  | CreateNewsSuccess
  | EditNews
  | EditNewsFailed
  | EditNewsSuccess
  | ReceiveNewsByIdSuccess
  | ReceiveNewsByIdFailed
  | RequestNewsById;

export const receiveNewsByIdSuccess = (news: News) => (dispatch) => {
  dispatch({ type: types.RECEIVE_NEWS_BY_ID_SUCCESS, news: news });
};

export const receiveNewsByIdFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.RECEIVE_NEWS_BY_ID_FAILED, error: errorKey });
};

export const requestNewsById = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_NEWS_BY_ID, id: id });
  getNewsById(id)
    .then((news) => {
      dispatch(receiveNewsByIdSuccess(news));
    })
    .catch((e) => {
      dispatch(receiveNewsByIdFailed(e));
    });
};

export const receiveNews = (news: ListNews) => (dispatch) => {
  dispatch({ type: types.RECEIVE_NEWS, news: news });
};

export const createNewsSuccess = () => (dispatch) => {
  dispatch(receiveNews(new ListNews(0, [])));
  dispatch({ type: types.CREATE_NEWS_SUCCESS });
  dispatch(push('/fanzone/news'));
  NotificationManager.success('La noticia ha sido creada correctamente.');
};

export const createNewsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_NEWS_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear la noticia.');
};

export const createNewsAction = (request: CreateNewsSchema.CreateNews) => async (dispatch) => {
  dispatch({ type: types.CREATE_NEWS });
  dispatch(startLoading());

  // const file: File = media[0].file;
  // const newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;

  createNews(request)
    .then(() => {
      dispatch(createNewsSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createNewsFailed(e));
      dispatch(endLoading());
    });
};

export const editNewsSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_NEWS_SUCCESS });
  dispatch(push('/fanzone/news'));
  NotificationManager.success('La noticia ha sido editada correctamente.');
};

export const editNewsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_NEWS_FAILED, error: errorKey });
  NotificationManager.success('Ha ocurrido un error al editar la noticia.');
};

export const editNewsAction = (id: number, request: EditNewsSchema.EditNews) => async (dispatch) => {
  dispatch({ type: types.EDIT_NEWS });
  dispatch(startLoading());

  /*
  let newMedia;

  if (media) {
    const file: File = media[0].file;
    newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;
  }
  */

  editNews(id, request)
    .then(() => {
      dispatch(editNewsSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editNewsFailed(e));
      dispatch(endLoading());
    });
};

export const requestNewsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_NEWS_FAILED, error: errorKey });
};

export const requestNews = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_NEWS });
  getNews(limit, offset, query)
    .then((news) => {
      dispatch(receiveNews(news));
    })
    .catch((e) => {
      dispatch(requestNewsFailed(e));
    });
};

export const requestNewsSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_NEWS_SUCCESS });
};
