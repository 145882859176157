import { push } from 'connected-react-router';
import {
  CategorySchema,
  EditCategory as EditCategoryEndpoint,
  GetCategories,
  NewCategory,
  NewCategorySchema,
} from 'crypthum-sdk';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getCategories = async (): Promise<CategorySchema.Category[]> => {
  return await cryptoMuseumsSDK.endpoint<GetCategories>(GetCategories).run();
};

const createCategory = async (body: NewCategorySchema.NewCategory) =>
  await cryptoMuseumsSDK.endpoint<NewCategory>(NewCategory).run({
    body: body,
  });

const editCategory = async (id: number, body: NewCategorySchema.NewCategory) =>
  await cryptoMuseumsSDK.endpoint<EditCategoryEndpoint>(EditCategoryEndpoint).run({
    id: id,
    body: body,
  });

export interface CreateCategory {
  type: types.CREATE_CATEGORY;
}

export interface CreateCategoryFailed {
  type: types.CREATE_CATEGORY_FAILED;
  error: string;
}

export interface CreateCategorySuccess {
  type: types.CREATE_CATEGORY_SUCCESS;
}

export interface EditCategory {
  type: types.EDIT_CATEGORY;
}

export interface EditCategoryFailed {
  type: types.EDIT_CATEGORY_FAILED;
  error: string;
}

export interface EditCategorySuccess {
  type: types.EDIT_CATEGORY_SUCCESS;
}

export interface RequestCategories {
  type: types.REQUEST_CATEGORIES;
}

export interface RequestCategoriesFailed {
  type: types.REQUEST_CATEGORIES_FAILED;
  error: string;
}

export interface RequestCategoriesSuccess {
  type: types.REQUEST_CATEGORIES_SUCCESS;
}

export interface ReceiveCategories {
  type: types.RECEIVE_CATEGORIES;
  categories: CategorySchema.Category[];
}

export type CategoryAction =
  | RequestCategories
  | RequestCategoriesSuccess
  | RequestCategoriesFailed
  | ReceiveCategories
  | CreateCategory
  | CreateCategoryFailed
  | CreateCategorySuccess
  | EditCategory
  | EditCategoryFailed
  | EditCategorySuccess;

export const createCategorySuccess = () => (dispatch) => {
  dispatch({ type: types.CREATE_CATEGORY_SUCCESS });
  dispatch(push('/categories'));
  NotificationManager.success('La categoría ha sido creada correctamente.');
};

export const createCategoryFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_CATEGORY_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear la categoría.');
};

export const createCategoryAction = (request: NewCategorySchema.NewCategory) => (dispatch) => {
  dispatch({ type: types.CREATE_CATEGORY });
  dispatch(startLoading());
  createCategory(request)
    .then(() => {
      dispatch(createCategorySuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createCategoryFailed(e));
      dispatch(endLoading());
    });
};

export const editCategorySuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_CATEGORY_SUCCESS });
  dispatch(push('/categories'));
  NotificationManager.success('La categoría ha sido editado correctamente.');
};

export const editCategoryFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_CATEGORY_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al editar el categoría.');
};

export const editCategoryAction = (id: number, request: NewCategorySchema.NewCategory) => (dispatch) => {
  dispatch({ type: types.EDIT_CATEGORY });
  dispatch(startLoading());
  editCategory(id, request)
    .then(() => {
      dispatch(editCategorySuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editCategoryFailed(e));
      dispatch(endLoading());
    });
};

export const requestCategoriesSuccess = (categories: CategorySchema.Category[]) => (dispatch) => {
  dispatch({ type: types.RECEIVE_CATEGORIES, categories: categories });
};

export const requestCategoriesFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_CATEGORIES_FAILED, error: errorKey });
};

export const requestCategories = () => (dispatch) => {
  dispatch({ type: types.REQUEST_CATEGORIES });
  getCategories()
    .then((categories) => {
      dispatch(requestCategoriesSuccess(categories));
    })
    .catch((e) => {
      dispatch(requestCategoriesFailed(e));
    });
};
