import { push } from 'connected-react-router';
import {
  CreateClub as CreateClubEndpoint,
  EditClub as EditClubEndpoint,
  GetClubData,
  GetClubs,
  NewClubSchema,
} from 'crypthum-sdk';
import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { ListClubs } from 'crypthum-sdk/dist/schemas/ListClubs';
import { Club } from 'crypthum-sdk/src/schemas/Club';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import { FormMedia } from '../state/entities/FormMedia';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getClubs = async (limit: number, offset: number, query: string): Promise<ListClubs> =>
  await cryptoMuseumsSDK.endpoint<GetClubs>(GetClubs).run({
    limit: limit,
    offset: offset,
    query: query,
  });

const createClub = async (body: NewClubSchema.NewClub) =>
  await cryptoMuseumsSDK.endpoint<CreateClubEndpoint>(CreateClubEndpoint).run({
    body: body,
  });

const editClub = async (id: number, body: NewClubSchema.NewClub) =>
  await cryptoMuseumsSDK.endpoint<EditClubEndpoint>(EditClubEndpoint).run({
    club_id: id,
    body: body,
  });

const getClubById = async (id: number) =>
  await cryptoMuseumsSDK.endpoint<GetClubData>(GetClubData).run({
    club_id: id,
  });

export interface CreateClub {
  type: types.CREATE_CLUB;
}

export interface CreateClubFailed {
  type: types.CREATE_CLUB_FAILED;
  error: string;
}

export interface CreateClubSuccess {
  type: types.CREATE_CLUB_SUCCESS;
}

export interface EditClub {
  type: types.EDIT_CLUB;
}

export interface EditClubFailed {
  type: types.EDIT_CLUB_FAILED;
  error: string;
}

export interface EditClubSuccess {
  type: types.EDIT_CLUB_SUCCESS;
}

export interface RequestClubs {
  type: types.REQUEST_CLUBS;
}

export interface RequestClubsFailed {
  type: types.REQUEST_CLUBS_FAILED;
  error: string;
}

export interface RequestClubsSuccess {
  type: types.REQUEST_CLUBS_SUCCESS;
}

export interface ReceiveClubs {
  type: types.RECEIVE_CLUBS;
  clubs: ListClubs;
}

export interface RequestClub {
  type: types.REQUEST_CLUB;
}

export interface RequestClubFailed {
  type: types.REQUEST_CLUB_FAILED;
  error: string;
}

export interface RequestClubSuccess {
  type: types.REQUEST_CLUB_SUCCESS;
}

export interface ReceiveClub {
  type: types.RECEIVE_CLUB;
  club: Club;
}

export type ClubAction =
  | RequestClubs
  | RequestClubsSuccess
  | RequestClubsFailed
  | ReceiveClubs
  | CreateClub
  | CreateClubFailed
  | CreateClubSuccess
  | EditClub
  | EditClubFailed
  | EditClubSuccess
  | RequestClub
  | RequestClubFailed
  | RequestClubSuccess
  | ReceiveClub;

export const receiveClubs = (clubs: ListClubs) => (dispatch) => {
  dispatch({ type: types.RECEIVE_CLUBS, clubs: clubs });
};

export const createClubSuccess = () => (dispatch) => {
  dispatch(receiveClubs(new ListClubs(0, [])));
  dispatch({ type: types.CREATE_CLUB_SUCCESS });
  dispatch(push('/clubs'));
  NotificationManager.success('El club ha sido creado correctamente.');
};

export const createClubFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_CLUB_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear un club.');
};

export const createClubAction = (
  name: string,
  instagram: string,
  twitter: string,
  media: FormMedia
) => async (dispatch) => {
  dispatch({ type: types.CREATE_CLUB });
  dispatch(startLoading());

  if (!media.file) {
    throw new Error('media is null');
  }

  const file: File = media.file;
  const newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;

  createClub(new NewClubSchema.NewClub(name, instagram, twitter, newMedia))
    .then(() => {
      dispatch(createClubSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createClubFailed(e));
      dispatch(endLoading());
    });
};

export const editClubSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_CLUB_SUCCESS });
  dispatch(push('/clubs'));
  NotificationManager.success('El club ha sido editado correctamente.');
};

export const editClubFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_CLUB_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al editar el club.');
};

export const editClubAction = (
  id: number,
  name: string,
  instagram: string,
  twitter: string,
  media: FormMedia
) => async (dispatch) => {
  dispatch({ type: types.EDIT_CLUB });
  dispatch(startLoading());

  let newMedia;

  if (media && media.file) {
    newMedia = (await cryptoMuseumsSDK.media.upload(new CreateMedia(media.file.name, false, media.file))).id;
  }

  editClub(id, new NewClubSchema.NewClub(name, instagram, twitter, newMedia))
    .then(() => {
      dispatch(editClubSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editClubFailed(e));
      dispatch(endLoading());
    });
};

export const requestClubsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUBS_FAILED, error: errorKey });
};

export const requestClubs = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUBS });
  getClubs(limit, offset, query)
    .then((clubs) => {
      dispatch(receiveClubs(clubs));
    })
    .catch((e) => {
      dispatch(requestClubsFailed(e));
    });
};

export const requestClubsSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUBS_SUCCESS });
};

export const receiveClub = (club: Club) => (dispatch) => {
  dispatch({ type: types.RECEIVE_CLUB, club: club });
};

export const requestClubFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUB_FAILED, error: errorKey });
};

export const requestClubById = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUB });

  getClubById(id)
    .then((club) => {
      dispatch(receiveClub(club));
    })
    .catch((e) => {
      dispatch(requestClubFailed(e));
    });
};

export const requestClubSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_CLUB_SUCCESS });
};
