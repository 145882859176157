import { FanzoneAction } from '../actions';
import * as FanzoneActionType from '../actions/types/fanzone';
import { Fanzone, getInitialState } from '../state/fanzone';

export const fanzone = (state: Fanzone = getInitialState(), action: FanzoneAction): Fanzone => {
  switch (action.type) {

    case FanzoneActionType.CREATE_MOMENT:
      return {
        ...state,
        createMoment: {
          ...state.createMoment,
          isFetching: true
        }
      };
    case FanzoneActionType.CREATE_MOMENT_SUCCESS:
      return {
        ...state,
        createMoment: {
          ...state.createMoment,
          isFetching: false
        }
      };
    case FanzoneActionType.CREATE_MOMENT_FAILED:
      return {
        ...state,
        createMoment: {
          ...state.createMoment,
          isFetching: false,
          error: action.error
        }
      };

    case FanzoneActionType.EDIT_FANZONE:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case FanzoneActionType.EDIT_FANZONE_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case FanzoneActionType.EDIT_FANZONE_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case FanzoneActionType.REQUEST_FANZONE:
      return {
        ...state,
        fanzoneList: {
          ...state.fanzoneList,
          isFetching: true
        }
      };
    case FanzoneActionType.RECEIVE_FANZONE_SUCCESS:
      return {
        ...state,
        fanzoneList: {
          ...state.fanzoneList,
          isFetching: false,
          data: action.fanzone
        },
      };
    case FanzoneActionType.RECEIVE_FANZONE_FAILED:
      return {
        ...state,
        fanzoneList: {
          ...state.fanzoneList,
          isFetching: false,
          error: action.error
        }
      };
    default:
      return state;
  }
}
