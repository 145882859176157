import { push } from 'connected-react-router';
import { GetAllKeys, GetLangs, NewLang as CreateLanguageEndpoint, TranslateKey as TranslateKeyEndpoint } from 'crypthum-sdk';
import { NewLang } from 'crypthum-sdk/dist/schemas/NewLang';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import * as types from './types';
import { startLoading, endLoading } from './global';

const cryptoMuseumsSDK = SDKProvider.get();

const getLanguages = async (): Promise<Map<any, any>> =>
  await cryptoMuseumsSDK.endpoint<GetLangs>(GetLangs).run();

const geti18nKeys = async (): Promise<Map<any, any>> =>
  await cryptoMuseumsSDK.endpoint<GetAllKeys>(GetAllKeys).run();

const createLanguage = async (request: NewLang) => {
  return await cryptoMuseumsSDK.endpoint<CreateLanguageEndpoint>(CreateLanguageEndpoint).run({
    body: request
  });
};

const translateKey = async (key: string, request: Map<any, any>) => {
  return await cryptoMuseumsSDK.endpoint<TranslateKeyEndpoint>(TranslateKeyEndpoint).run({
    key: key,
    body: request
  });
};

export interface TranslateKey {
  type: types.TRANSLATE_KEY;
}

export interface TranslateKeyFailed {
  type: types.TRANSLATE_KEY_FAILED;
  error: string;
}

export interface TranslateKeySuccess {
  type: types.TRANSLATE_KEY_SUCCESS;
}

export interface CreateLanguage {
  type: types.CREATE_LANGUAGE;
}

export interface CreateLanguageFailed {
  type: types.CREATE_LANGUAGE_FAILED;
  error: string;
}

export interface CreateLanguageSuccess {
  type: types.CREATE_LANGUAGE_SUCCESS;
}

export interface RequestLanguages {
  type: types.REQUEST_LANGUAGES;
}

export interface RequestLanguagesFailed {
  type: types.REQUEST_LANGUAGES_FAILED;
  error: string;
}

export interface RequestLanguagesSuccess {
  type: types.REQUEST_LANGUAGES_SUCCESS;
}

export interface ReceiveLanguages {
  type: types.RECEIVE_LANGUAGES;
  languages: Map<any, any>;
}

export interface RequestI18nKeys {
  type: types.REQUEST_I18N_KEYS;
}

export interface RequestI18nKeysFailed {
  type: types.REQUEST_I18N_KEYS_FAILED;
  error: string;
}

export interface RequestI18nKeysSuccess {
  type: types.REQUEST_I18N_KEYS_SUCCESS;
}

export interface ReceiveI18nKeys {
  type: types.RECEIVE_I18N_KEYS;
  i18nKeys: Map<any, any>;
}

export type LanguageAction =
  RequestLanguages |
  RequestLanguagesSuccess |
  RequestLanguagesFailed |
  ReceiveLanguages |
  CreateLanguage |
  CreateLanguageFailed |
  CreateLanguageSuccess |
  RequestI18nKeys |
  RequestI18nKeysSuccess |
  RequestI18nKeysFailed |
  ReceiveI18nKeys |
  TranslateKey |
  TranslateKeyFailed |
  TranslateKeySuccess;

export const translateKeyAction = (key: string, request: Map<any, any>) => (dispatch) => {
  dispatch({ type: types.TRANSLATE_KEY });
  dispatch(startLoading());
  translateKey(key, request).then(() => {
    dispatch(translateKeySuccess());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(translateKeyFailed(e));
    dispatch(endLoading());
  });
};

export const translateKeySuccess = () => (dispatch) => {
  dispatch({ type: types.TRANSLATE_KEY_SUCCESS });
  dispatch(push('/languages'));
  NotificationManager.success('La key se ha traducido correctamente.');
};

export const translateKeyFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.TRANSLATE_KEY_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al traducir la key.');
};

export const createLanguageAction = (request: NewLang) => (dispatch) => {
  dispatch({ type: types.CREATE_LANGUAGE });
  dispatch(startLoading());
  createLanguage(request).then(() => {
    dispatch(createLanguageSuccess());
    dispatch(endLoading());
  }).catch((e) => {
    dispatch(createLanguageFailed(e));
    dispatch(endLoading());
  });
};

export const createLanguageSuccess = () => (dispatch) => {
  dispatch({ type: types.CREATE_LANGUAGE_SUCCESS });
  dispatch(push('/languages'));
  NotificationManager.success('El idioma se ha creado correctamente.');
};

export const createLanguageFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_LANGUAGE_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear el idioma.');
};

export const requestLanguages = () => (dispatch) => {
  dispatch({ type: types.REQUEST_LANGUAGES });

  getLanguages().then((languages) => {
    dispatch(receiveLanguages(languages));
  }).catch((e) => {
    dispatch(requestLanguagesFailed(e));
  });
};

export const requestLanguagesFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_LANGUAGES_FAILED, error: errorKey });
};

export const requestLanguagesSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_LANGUAGES_SUCCESS });
};

export const receiveLanguages = (languages: Map<any, any>) => (dispatch) => {
  dispatch({ type: types.RECEIVE_LANGUAGES, languages: languages });
};

export const requestI18nKeys = () => (dispatch) => {
  dispatch({ type: types.REQUEST_LANGUAGES });

  geti18nKeys().then((i18nKeys) => {
    dispatch(receiveI18nKey(i18nKeys));
  }).catch((e) => {
    dispatch(requestI18nKeyFailed(e));
  });
};

export const requestI18nKeyFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_I18N_KEYS_FAILED, error: errorKey });
};

export const requestI18nKeySuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_I18N_KEYS_SUCCESS });
};

export const receiveI18nKey = (i18nKeys: Map<any, any>) => (dispatch) => {
  dispatch({ type: types.RECEIVE_I18N_KEYS, i18nKeys: i18nKeys });
};
