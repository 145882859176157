import { ListUser } from 'crypthum-sdk/dist/schemas/ListUser';
import { UserWithItems } from 'crypthum-sdk/src/schemas/UserWithItems';
import { Wallet } from 'crypthum-sdk/src/schemas/Wallet';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface User {
  edit: StateModel<void>;
  delete: StateModel<void>;
  block: StateModel<void>;
  user: StateModel<UserWithItems>;
  userList: StateModel<ListUser>;
  userWallet: StateModel<Wallet>;
}

export function getInitialState(): User {
  return {
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    block: EmptyStateModel,
    user: EmptyStateModel,
    userList: EmptyStateModel,
    userWallet: EmptyStateModel,
  };
}
