export const REQUEST_FANZONE = 'REQUEST_FANZONE';
export type REQUEST_FANZONE = typeof REQUEST_FANZONE;

export const RECEIVE_FANZONE_FAILED = 'RECEIVE_FANZONE_FAILED';
export type RECEIVE_FANZONE_FAILED = typeof RECEIVE_FANZONE_FAILED;

export const RECEIVE_FANZONE_SUCCESS = 'RECEIVE_FANZONE_SUCCESS';
export type RECEIVE_FANZONE_SUCCESS = typeof RECEIVE_FANZONE_SUCCESS;

export const EDIT_FANZONE = 'EDIT_FANZONE';
export type EDIT_FANZONE = typeof EDIT_FANZONE;

export const EDIT_FANZONE_FAILED = 'EDIT_FANZONE_FAILED';
export type EDIT_FANZONE_FAILED = typeof EDIT_FANZONE_FAILED;

export const EDIT_FANZONE_SUCCESS = 'EDIT_FANZONE_SUCCESS';
export type EDIT_FANZONE_SUCCESS = typeof EDIT_FANZONE_SUCCESS;


export const CREATE_MOMENT = 'CREATE_MOMENT';
export type CREATE_MOMENT = typeof CREATE_MOMENT;

export const CREATE_MOMENT_FAILED = 'CREATE_MOMENT_FAILED';
export type CREATE_MOMENT_FAILED = typeof CREATE_MOMENT_FAILED;

export const CREATE_MOMENT_SUCCESS = 'CREATE_MOMENT_SUCCESS';
export type CREATE_MOMENT_SUCCESS = typeof CREATE_MOMENT_SUCCESS;
