import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestUser, requestUsers, requestUserWallet } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { User } from '../../redux/state/users';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getUsers: (limit: number, offset: number, query: string) => void;
  getUserById: (id: number) => void;
  getUserWalletById: (id: number, limit: number, offset: number) => void;
}

interface ListUsersProps extends User, DispatchProps {}

class ListUsers extends React.Component<ListUsersProps> {
  constructor(public readonly props: ListUsersProps) {
    super(props);
  }

  getUserInfo(id: number) {
    this.props.getUserById(id);
    this.props.getUserWalletById(id, 5, 0);
  }

  componentDidMount() {
    this.props.getUsers(10, 0, "");
  }

  render() {
    const columns = [
      {
        name: "name",
        label: "Full Name",
      },
      {
        name: "email",
        label: "Email",
      },
      {
        name: "nationality",
        label: "Nationality",
      },
      {
        name: "created",
        label: "Created",
      },
      {
        name: "options",
        label: "Options",
      },
    ];

    const data: any[] = [];
    if (this.props.userList.data) {
      for (const user of this.props.userList.data.data) {
        data.push({
          name: (user.name ? user.name : "-") + " " + (user.surname ? user.surname : ""),
          email: user.email,
          nationality: user.nationality,
          created: moment(user.created).format("YYYY-MM-DD HH:mm:ss"),
          options: (
            <div>
              <Link to={`/user/edit/${user.id}`}>
                <button className="btn btn-success waves-effect waves-light" type="button">
                  <i className="fa fa-pencil-alt" />
                </button>
              </Link>
              <button
                className="btn btn-warning waves-effect waves-light m-l-10"
                data-id={`${user.id}`}
                type="button"
                onClick={() => {
                  this.getUserInfo(user.id);
                }}
                data-toggle="modal"
                data-target="#itemsUserModal"
              >
                <i className="fa fa-money-bill-alt" />
              </button>
            </div>
          ),
        });
      }
    }

    const count = this.props.userList.data ? this.props.userList.data.count : 0;
    const options = {
      ...dataTableOptions,
      count: count,
      isLoading: this.props.userList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? "" : tableState.searchText;
        this.props.getUsers(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      },
    };

    let body;

    if (this.props.userList.error) {
      body = (
        <div className="card card-inverse card-danger">
          <div className="card-body">
            <h3 className="card-title">Error fetching users</h3>
            <p className="card-text">
              There was an error downloading the users list. Please, try again in a few minutes.
            </p>
            <button
              className="btn btn-inverse"
              onClick={() => {
                this.props.getUsers(10, 0, "");
              }}
            >
              Try again
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  <FormattedMessage id="users.user_table.title" />
                </h4>
                <h6 className="card-subtitle">
                  <FormattedMessage id="users.user_table.subtitle" />
                </h6>
                <div className="table-responsive">
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h3 className="text-themecolor">
              <FormattedMessage id="users.users" />
            </h3>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <FormattedMessage id="users.users" />
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage id="users.list_users" />
              </li>
            </ol>
          </div>
        </div>

        {body}

        <div
          id="itemsUserModal"
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  User Info
                </h4>
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <p>This user has {this.props.user.data ? this.props.user.data.items.length : "..."} item/s.</p>
                <p>And has {this.props.userWallet.data ? this.props.userWallet.data.balance : "..."}€ in the wallet.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-info waves-effect" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getUsers: (limit: number, offset: number, query: string) => dispatch(requestUsers(limit, offset, query)),
    getUserById: (id: number) => dispatch(requestUser(id)),
    getUserWalletById: (id: number, limit: number, offset: number) =>
      dispatch(requestUserWallet(id, limit, offset)),
  };
};

export default connect<User, DispatchProps>(
  (store: StoreState) => store.user,
  mapDispatchToProps
)(ListUsers);
