import { FindUserTransactionsByIntervalDates, GetUserWallet as GetTransactionsList } from 'crypthum-sdk';
import { Wallet as ListTransaction } from 'crypthum-sdk/dist/schemas/Wallet';
import { UserBalanceAndTransactionsInterval } from 'crypthum-sdk/src/schemas/UserBalanceAndTransactionsInterval';

import { SDKProvider } from '../../components/providers/SDKProvider';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getTransactions = async (limit: number, offset: number): Promise<ListTransaction> =>
  await cryptoMuseumsSDK
    .endpoint<GetTransactionsList>(GetTransactionsList)
    .run({ limit: limit, offset: offset });

const getGraph = async (start: string, end: string): Promise<UserBalanceAndTransactionsInterval> =>
  await cryptoMuseumsSDK
    .endpoint<FindUserTransactionsByIntervalDates>(FindUserTransactionsByIntervalDates)
    .run({ start: start, end: end });

export interface RequestTransactions {
  type: types.REQUEST_TRANSACTIONS;
}

export interface RequestTransactionsFailed {
  type: types.REQUEST_TRANSACTIONS_FAILED;
  error: string;
}

export interface RequestTransactionsSuccess {
  type: types.REQUEST_TRANSACTIONS_SUCCESS;
}

export interface ReceiveTransactions {
  type: types.RECEIVE_TRANSACTIONS;
  transactions: ListTransaction;
}

export interface RequestGraph {
  type: types.REQUEST_GRAPH;
}

export interface RequestGraphFailed {
  type: types.REQUEST_GRAPH_FAILED;
  error: string;
}

export interface RequestGraphSuccess {
  type: types.REQUEST_GRAPH_SUCCESS;
}

export interface ReceiveGraph {
  type: types.RECEIVE_GRAPH;
  graph: UserBalanceAndTransactionsInterval;
}

export type TransactionAction =
  | RequestTransactions
  | RequestTransactionsSuccess
  | RequestTransactionsFailed
  | ReceiveTransactions
  | RequestGraph
  | RequestGraphFailed
  | RequestGraphSuccess
  | ReceiveGraph;

export const receiveTransactions = (transactions: ListTransaction) => (dispatch) => {
  dispatch({ type: types.RECEIVE_TRANSACTIONS, transactions: transactions });
};

export const requestTransactionsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_TRANSACTIONS_FAILED, error: errorKey });
};

export const requestTransactions = (limit: number, offset: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_TRANSACTIONS });
  getTransactions(limit, offset)
    .then((transactions) => {
      dispatch(receiveTransactions(transactions));
    })
    .catch((e) => {
      dispatch(requestTransactionsFailed(e));
    });
};

export const requestTransactionsSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_GRAPH_SUCCESS });
};

export const receiveGraph = (graph: UserBalanceAndTransactionsInterval) => (dispatch) => {
  dispatch({ type: types.RECEIVE_GRAPH, graph: graph });
};

export const requestGraphFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_GRAPH_FAILED, error: errorKey });
};

export const requestGraph = (start: string, end: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_GRAPH });
  getGraph(start, end)
    .then((graph) => {
      dispatch(receiveGraph(graph));
    })
    .catch((e) => {
      dispatch(requestGraphFailed(e));
    });
};

export const requestGraphSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_GRAPH_SUCCESS });
};
