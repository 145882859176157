export const REQUEST_CLUBS = 'REQUEST_CLUBS';
export type REQUEST_CLUBS = typeof REQUEST_CLUBS;

export const REQUEST_CLUBS_FAILED = 'REQUEST_CLUBS_FAILED';
export type REQUEST_CLUBS_FAILED = typeof REQUEST_CLUBS_FAILED;

export const REQUEST_CLUBS_SUCCESS = 'REQUEST_CLUBS_SUCCESS';
export type REQUEST_CLUBS_SUCCESS = typeof REQUEST_CLUBS_SUCCESS;

export const RECEIVE_CLUBS = 'RECEIVE_CLUBS';
export type RECEIVE_CLUBS = typeof RECEIVE_CLUBS;

export const CREATE_CLUB = 'CREATE_CLUB';
export type CREATE_CLUB = typeof CREATE_CLUB;

export const CREATE_CLUB_FAILED = 'CREATE_CLUB_FAILED';
export type CREATE_CLUB_FAILED = typeof CREATE_CLUB_FAILED;

export const CREATE_CLUB_SUCCESS = 'CREATE_CLUB_SUCCESS';
export type CREATE_CLUB_SUCCESS = typeof CREATE_CLUB_SUCCESS;

export const EDIT_CLUB = 'EDIT_CLUB';
export type EDIT_CLUB = typeof EDIT_CLUB;

export const EDIT_CLUB_FAILED = 'EDIT_CLUB_FAILED';
export type EDIT_CLUB_FAILED = typeof EDIT_CLUB_FAILED;

export const EDIT_CLUB_SUCCESS = 'EDIT_CLUB_SUCCESS';
export type EDIT_CLUB_SUCCESS = typeof EDIT_CLUB_SUCCESS;

export const REQUEST_CLUB = 'REQUEST_CLUB';
export type REQUEST_CLUB = typeof REQUEST_CLUB;

export const REQUEST_CLUB_FAILED = 'REQUEST_CLUB_FAILED';
export type REQUEST_CLUB_FAILED = typeof REQUEST_CLUB_FAILED;

export const REQUEST_CLUB_SUCCESS = 'REQUEST_CLUB_SUCCESS';
export type REQUEST_CLUB_SUCCESS = typeof REQUEST_CLUB_SUCCESS;

export const RECEIVE_CLUB = 'RECEIVE_CLUB';
export type RECEIVE_CLUB = typeof RECEIVE_CLUB;
