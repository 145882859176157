import { RolSchema } from 'crypthum-sdk';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Rol {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  rolList: StateModel<RolSchema.Rol[]>;
  actionList: StateModel<string[]>;
  addModal: boolean;
  editModal: RolSchema.Rol | null;
  roleUsers: { id: string, text: string }[];
}

export function getInitialState(): Rol {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    rolList: EmptyStateModel,
    actionList: EmptyStateModel,
    editModal: null,
    addModal: false,
    roleUsers: []
  };
}
