import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export default function FileDropzone(props) {
  const [images, setImages] = useState([]);

  /** ToDo => carencias de no estar escrito en TS
 * añadir el tipo al atributo `props`
 *    multiple (bolean); si adminte más de un archivo
 *    accpet (string); los tipos de archivo que acepta
 *    maxSize (number); tamaño máximo de fichero (bytes)
 *    minSize (number); tamaño mínimo de fichero (bytes)
 *    width (string); regla css para la anchura del contenedor
 *    height (string); regla css para la altura del contenedor
 */

  const accept = props.accept ? { accept: props.accept } : {};
  const maxSize = props.maxSize ? { maxSize: props.maxSize } : {};
  const minSize = props.minSize ? { minSize: props.minSize } : {};

  const userDropzoneProps = {
    multiple: props.multiple ? true : false,
    ...accept,
    ...maxSize,
    ...minSize
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDrop: acceptedFiles => {
      if (acceptedFiles.length !== 0) {
        let files = acceptedFiles.map(file => ({
          file: file, previewUrl: URL.createObjectURL(file)
        }));
        setImages(files);

        props.onFileChange(files);
      }
    },
    ...userDropzoneProps
  });

  const clearState = (event) => {
    event.stopPropagation();
    setImages([]);
    props.onFileChange([]);
   }

  const getFileSize = (size) => {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unity = 0;

    while (size > 1000) {
      unity++;
      size /= 1000;
    }

    return `${size.toFixed(1)} ${sizes[unity]}`;
  }

  const body = images.length > 0 || props.preview
    ? <aside>
      <i className="fa fa-times" onClick={clearState}></i>
      {
        props.preview && props.accept === 'video/*' ? <video src={props.preview} ></video>
          : props.preview && props.accept === 'image/*' ? <img src={props.preview} />
            : null
      }
      {
        images.length === 1 && images[0].previewUrl !== ''
          ? <img key={images[0].previewUrl} src={images[0].previewUrl} />
          : <div className="list"><ul>
            {images.map(file => {
              const size = getFileSize(file.file.size);
              return <li key={file.file.name}>{file.file.name} ({size})</li>
            })}
          </ul></div>
      }
    </aside>
    : <aside>
      <span className='upload-icon'></span>
      {props.message ? <p className="message">{props.message}</p> : ''}
    </aside>;

  const className = useMemo(() => {
    let baseClass = 'dropzone';
    if (!isDragActive) return baseClass;
    if (isDragReject) return baseClass + ' reject';
    return baseClass + ' accept';
  });

  return (
    <section>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        {body}
      </div>
    </section >
  );
}
