export const REQUEST_LANGUAGES = 'REQUEST_LANGUAGES';
export type REQUEST_LANGUAGES = typeof REQUEST_LANGUAGES;

export const REQUEST_LANGUAGES_FAILED = 'REQUEST_LANGUAGES_FAILED';
export type REQUEST_LANGUAGES_FAILED = typeof REQUEST_LANGUAGES_FAILED;

export const REQUEST_LANGUAGES_SUCCESS = 'REQUEST_LANGUAGES_SUCCESS';
export type REQUEST_LANGUAGES_SUCCESS = typeof REQUEST_LANGUAGES_SUCCESS;

export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';
export type RECEIVE_LANGUAGES = typeof RECEIVE_LANGUAGES;

export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export type CREATE_LANGUAGE = typeof CREATE_LANGUAGE;

export const CREATE_LANGUAGE_FAILED = 'CREATE_LANGUAGE_FAILED';
export type CREATE_LANGUAGE_FAILED = typeof CREATE_LANGUAGE_FAILED;

export const CREATE_LANGUAGE_SUCCESS = 'CREATE_LANGUAGE_SUCCESS';
export type CREATE_LANGUAGE_SUCCESS = typeof CREATE_LANGUAGE_SUCCESS;

export const REQUEST_I18N_KEYS = 'REQUEST_I18N_KEYS';
export type REQUEST_I18N_KEYS = typeof REQUEST_I18N_KEYS;

export const REQUEST_I18N_KEYS_FAILED = 'REQUEST_I18N_KEYS_FAILED';
export type REQUEST_I18N_KEYS_FAILED = typeof REQUEST_I18N_KEYS_FAILED;

export const REQUEST_I18N_KEYS_SUCCESS = 'REQUEST_I18N_KEYS_SUCCESS';
export type REQUEST_I18N_KEYS_SUCCESS = typeof REQUEST_I18N_KEYS_SUCCESS;

export const RECEIVE_I18N_KEYS = 'RECEIVE_I18N_KEYS';
export type RECEIVE_I18N_KEYS = typeof RECEIVE_I18N_KEYS;

export const TRANSLATE_KEY = 'TRANSLATE_KEY';
export type TRANSLATE_KEY = typeof TRANSLATE_KEY;

export const TRANSLATE_KEY_FAILED = 'TRANSLATE_KEY_FAILED';
export type TRANSLATE_KEY_FAILED = typeof TRANSLATE_KEY_FAILED;

export const TRANSLATE_KEY_SUCCESS = 'TRANSLATE_KEY_SUCCESS';
export type TRANSLATE_KEY_SUCCESS = typeof TRANSLATE_KEY_SUCCESS;