import * as types from './types';

export interface StartLoading {
  type: types.START_LOADING;
}

export interface EndLoading {
  type: types.END_LOADING;
}

export type GlobalAction =
  StartLoading |
  EndLoading;

export const startLoading = () => (dispatch) => {
  dispatch({ type: types.START_LOADING });
};

export const endLoading = () => (dispatch) => {
  dispatch({ type: types.END_LOADING });
};
