import { UserBalanceAndTransactionsInterval } from 'crypthum-sdk/dist/schemas/UserBalanceAndTransactionsInterval';
import { Wallet as ListTransaction } from 'crypthum-sdk/dist/schemas/Wallet';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Transaction {
  create: StateModel<void>;
  transactionList: StateModel<ListTransaction>;
  endedtransactionList: StateModel<ListTransaction>;
  graphsList: StateModel<UserBalanceAndTransactionsInterval>;
}

export function getInitialState(): Transaction {
  return {
    create: EmptyStateModel,
    transactionList: EmptyStateModel,
    endedtransactionList: EmptyStateModel,
    graphsList: EmptyStateModel,
  };
}
