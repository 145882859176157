import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'name',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Name cannot be empty'
      }
    ]
  },
  {
    name: 'link',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Link cannot be empty'
      }
    ]
  },
  {
    name: 'status',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a status option'
      }
    ]
  },
  {
    name: 'type',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to select a type option'
      }
    ]
  },
  {
    name: 'description',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Description cannot be empty'
      }
    ]
  },
  {
    name: 'logoMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to upload a logo'
      }
    ]
  }, {
    name: 'userId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'User cannot be empty'
      }
    ]
  },
]);
