import * as React from 'react';

class Dashboard extends React.Component {

  render() {

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Dashboard</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Home</li>
              <li className='breadcrumb-item active'>Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
