import moment from 'moment';
import * as React from 'react';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import { createPresaleAction, requestFoundations, requestItems } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { FormMedia } from '../../redux/state/entities/FormMedia';
import FileDropzone from '../utils/FileDropzone';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';

interface DispatchProps {
  createPresale: (
    items: number[], price: number, quantityLimit: number, start: Date, end: Date, users: number[], percentages: number[],
    discounts: number[], discountTimes: number[], image: FormMedia, imageLandscape: FormMedia,
  ) => void;
  getUsers: (limit: number, offset: number, query: string) => void;
  getItems: (limit: number, offset: number, query: string) => void;
}

interface AddEditClubProps extends StoreState, DispatchProps { }

class AddPresale extends React.Component<AddEditClubProps, RouteProps> {

  private price: React.RefObject<HTMLInputElement> = React.createRef();
  private quantityLimit: React.RefObject<HTMLInputElement> = React.createRef();
  private start: React.RefObject<HTMLInputElement> = React.createRef();
  private end: React.RefObject<HTMLInputElement> = React.createRef();

  private itemId: React.RefObject<Select> = React.createRef();

  private media: FormMedia;
  private mediaLandScape: FormMedia;

  private validationService: ValidationService;
  private formSubmitted = false;

  private foundationUserId: number = null;

  constructor(public readonly props: AddEditClubProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {}
    };

    this.validationService = addEditValidations;
  }


  componentDidMount() {
    this.props.getUsers(10, 0, '');
    this.props.getItems(10, 0, '');
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        price: this.validationService.getLastError('price'),
        quantityLimit: this.validationService.getLastError('quantityLimit'),
        start: this.validationService.getLastError('start'),
        end: this.validationService.getLastError('end'),
        itemId: this.validationService.getLastError('itemId'),
        media: this.validationService.getLastError('media'),
        mediaLandScape: this.validationService.getLastError('mediaLandScape')
      }
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  submit(e) {

    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('price', GetReactRef.asString(this.price));
    this.validationService.updateValue('quantityLimit', GetReactRef.asString(this.quantityLimit));
    this.validationService.updateValue('start', GetReactRef.asString(this.start));
    this.validationService.updateValue('end', '2100-01-01T00:00:00.000Z');
    this.validationService.updateValue('itemId', this.itemId.current.state.value.value);
    this.validationService.updateValue('media', this.getFileDropzoneData('media'));
    this.validationService.updateValue('mediaLandScape', this.getFileDropzoneData('mediaLandScape'));


    if (this.handleValidation()) {
      this.sendClubForm();
    }
  }

  getFileDropzoneData(type: string) {
    if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  handleSelectChange(field, value) {
    if (field === 'itemId') {
      for (const item of this.props.item.itemList.data.data) {
        if (item.id === value.value) {
          this.foundationUserId = item.foundation.userId;
          break;
        }
      }
    }
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  inputHandleChangeItems = (inputText: string) => {
    this.props.getItems(10, 0, inputText);
  }

  inputHandleChangeUsers = (inputText: string) => {
    this.props.getUsers(10, 0, inputText);
  }

  getItemsOptions = () => {
    return this.props.item.itemList.data
      ? this.props.item.itemList.data.data.map(
        val => ({
          value: val.id,
          label: val.id + ' | ' + val.user.name + ' ' + val.user.surname +
          ' (' + val.player.fullName + ')' + ' [' + moment(val.created).format('YYYY-MM-DD') + ']'})
      )
      : [];
    }

  private sendClubForm() {
    this.props.createPresale(
      [this.itemId.current.state.value.value],
      GetReactRef.asNumber(this.price),
      GetReactRef.asNumber(this.quantityLimit),
      GetReactRef.asDate(this.start),
      new Date('2100-01-01T00:00:00.000Z'),
      [this.foundationUserId],
      [100],
      [],
      [],
      this.media,
      this.mediaLandScape,
    );
  }

  render() {

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>Presales</h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>Presales</li>
              <li className='breadcrumb-item active'>Add Presale</li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>Create Presale</h3>
                    <hr />
                    <fieldset>

                      <div className='row p-t-20'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['start'] ? ' error' : '')}>
                            <label>Start</label>
                            <input onChange={this.handleChange.bind(this, 'start')} value={this.state.fields['start']}
                              ref={this.start} defaultValue={''} type='datetime-local' className='form-control' />
                            <span className='error-message'>{this.state.errors['start']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['quantityLimit'] ? ' error' : '')}>
                            <label>Quantity Limit</label>
                            <input onChange={this.handleChange.bind(this, 'quantityLimit')} value={this.state.fields['quantityLimit']}
                                   ref={this.quantityLimit} defaultValue={'100'} type='number' className='form-control' />
                            <span className='error-message'>{this.state.errors['quantityLimit']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['price'] ? ' error' : '')}>
                            <label>Price</label>
                            <input onChange={this.handleChange.bind(this, 'price')} value={this.state.fields['price']}
                              ref={this.price} defaultValue={'5'} type='number' className='form-control' />
                            <span className='error-message'>{this.state.errors['price']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['itemId'] ? ' error' : '')}>
                            <label className='control-label'>Asset</label>
                            <Select onChange={this.handleSelectChange.bind(this, 'itemId')} ref={this.itemId}
                              value={this.state.fields['itemId']} defaultValue={''} options={this.getItemsOptions()}
                              onInputChange={this.inputHandleChangeItems} />
                            <span className='error-message'>{this.state.errors['itemId']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['media'] ? ' error' : '')}>
                            <label>Media</label>
                            <FileDropzone value={this.state.fields['media']}
                              accept='image/*' preview={('media' in this.state.fields)}
                              onFileChange={(file: FormMedia[]) => {
                                this.media = file[0];
                                this.mediaLandScape = file[0];
                                this.handleDropzoneChange('media', file[0] ? file[0].previewUrl : '');
                              }} />
                            <span className='error-message'>{this.state.errors['media']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className='form-actions text-right'>
                    <Link to='/clubs'>
                      <button type='button' className='btn btn-info m-r-10'>Cancel</button>
                    </Link>

                    <button disabled={this.props.presale.create.isFetching} className='btn btn-success' type='submit'>
                      CREATE PRESALE!
                    </button>

                  </div>

                </form>
              </div>

            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getUsers: (limit: number, offset: number, query: string) => dispatch(requestFoundations(limit, offset, query)),
    getItems: (limit: number, offset: number, query: string) => dispatch(requestItems(limit, offset, query)),
    createPresale: (
      items: number[], price: number, quantityLimit: number, start: Date, end: Date, users: number[], percentages: number[],
      discounts: number[], discountTimes: number[], image: FormMedia, imageLandscape: FormMedia,
    ) => dispatch(
      createPresaleAction(items, price, quantityLimit, start, end, users, percentages, discounts, discountTimes, image, imageLandscape)
    )
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps,
)(AddPresale);
