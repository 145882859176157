import {CryptoMuseumsSDK, Http, Server } from 'crypthum-sdk';
import {Config} from '../../config';

export class SDKProvider {

    static instance = CryptoMuseumsSDK(Http(new Server(Config.server)));

    static get() {
        return this.instance;
    }
}
