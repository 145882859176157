import { UserAction } from "../actions";
import * as UserActionType from "../actions/types/users";
import { getInitialState, User } from "../state/users";

export const users = (state: User = getInitialState(), action: UserAction): User => {
  switch (action.type) {
    case UserActionType.EDIT_USER:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true,
        },
      };
    case UserActionType.EDIT_USER_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
        },
      };
    case UserActionType.EDIT_USER_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error,
        },
      };
    case UserActionType.REQUEST_USERS:
      return {
        ...state,
        userList: {
          ...state.userList,
          isFetching: true,
        },
      };
    case UserActionType.REQUEST_USERS_FAILED:
      return {
        ...state,
        userList: {
          ...state.userList,
          isFetching: false,
          error: action.error,
        },
      };
    case UserActionType.RECEIVE_USERS:
      return {
        ...state,
        userList: {
          ...state.userList,
          isFetching: false,
          data: action.users,
        },
      };
    case UserActionType.REQUEST_USER:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: true,
        },
      };
    case UserActionType.RECEIVE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: false,
          data: action.user,
        },
      };
    case UserActionType.RECEIVE_USER_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          isFetching: false,
          error: action.error,
        },
      };
    case UserActionType.REQUEST_USER_WALLET:
      return {
        ...state,
        userWallet: {
          ...state.userWallet,
          isFetching: true,
        },
      };
    case UserActionType.RECEIVE_USER_WALLET_SUCCESS:
      return {
        ...state,
        userWallet: {
          ...state.userWallet,
          isFetching: false,
          data: action.userWallet,
        },
      };
    case UserActionType.RECEIVE_USER_WALLET_FAILED:
      return {
        ...state,
        userWallet: {
          ...state.userWallet,
          isFetching: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
