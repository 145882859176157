import { NewsSchema } from 'crypthum-sdk';
import { ListNews } from 'crypthum-sdk/dist/schemas/ListNews';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface News {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  news: StateModel<NewsSchema.News>;
  newsList: StateModel<ListNews>;
}

export function getInitialState(): News {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    news: EmptyStateModel,
    newsList: EmptyStateModel
  };
}
