import { LanguageAction } from '../actions';
import * as LanguageActionType from '../actions/types/languages';
import { getInitialState, Language } from '../state/languages';

export const languages = (state: Language = getInitialState(), action: LanguageAction): Language => {
  switch (action.type) {
    case LanguageActionType.TRANSLATE_KEY:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case LanguageActionType.TRANSLATE_KEY_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case LanguageActionType.TRANSLATE_KEY_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case LanguageActionType.CREATE_LANGUAGE:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true
        }
      };
    case LanguageActionType.CREATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false
        }
      };
    case LanguageActionType.CREATE_LANGUAGE_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error
        }
      };
    case LanguageActionType.REQUEST_LANGUAGES:
      return {
        ...state,
        languageList: {
          ...state.languageList,
          isFetching: true
        }
      };
    case LanguageActionType.REQUEST_LANGUAGES_FAILED:
      return {
        ...state,
        languageList: {
          ...state.languageList,
          isFetching: false,
          error: action.error
        }
      };
    case LanguageActionType.RECEIVE_LANGUAGES:
      return {
        ...state,
        languageList: {
          ...state.languageList,
          isFetching: false,
          data: action.languages
        }
      };
    case LanguageActionType.REQUEST_I18N_KEYS:
      return {
        ...state,
        i18nKeyList: {
          ...state.i18nKeyList,
          isFetching: true
        }
      };
    case LanguageActionType.REQUEST_I18N_KEYS_SUCCESS:
      return {
        ...state,
        i18nKeyList: {
          ...state.i18nKeyList,
          isFetching: false
        }
      };
    case LanguageActionType.REQUEST_I18N_KEYS_FAILED:
      return {
        ...state,
        i18nKeyList: {
          ...state.i18nKeyList,
          isFetching: false,
          error: action.error

        }
      };
    case LanguageActionType.RECEIVE_I18N_KEYS:
      return {
        ...state,
        i18nKeyList: {
          ...state.i18nKeyList,
          isFetching: false,
          data: action.i18nKeys
        }
      };
    default:
      return state;
  }
};
