import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestClubs } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Club } from '../../redux/state/clubs';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getClubs: (limit: number, offset: number, query: string) => void;
}

interface ListClubsProps extends Club, DispatchProps { }

class ListClubs extends React.Component<ListClubsProps> {

  constructor(public readonly props: ListClubsProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getClubs(10, 0, '');
  }

  render() {
    const columns = [
      {
        name: 'name',
        label: 'Name'
      }, {
        name: 'instagram',
        label: 'Instagram'
      }, {
        name: 'twitter',
        label: 'Twitter'
      }, {
        name: 'created',
        label: 'Created'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const data: any[] = [];

    if (this.props.clubList.data) {
      for (const club of this.props.clubList.data.data) {
        data.push({
          name: club.name,
          instagram: club.instagram,
          twitter: club.twitter,
          created: moment(club.created).format('YYYY-MM-DD HH:mm:ss'),
          options: <div>
            <Link to={`/club/edit/${club.id}`}>
              <button className='btn btn-success waves-effect waves-light' type='button'>
                <i className='fa fa-pencil-alt' />
              </button>
            </Link>
            <button className='btn btn-googleplus waves-effect waves-light m-l-10' type='button'
              data-id={`${club.id}`} data-toggle='modal' data-target='#deleteClubModal'>
              <i className='fa fa-trash-alt' />
            </button>
          </div>
        });
      }
    }

    const count = this.props.clubList.data ? this.props.clubList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      isLoading: this.props.clubList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getClubs(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      }
    };

    let body;

    if (this.props.clubList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching clubs</h3>
          <p className='card-text'>There was an error downloading the club list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getClubs(100, 0, ''); }}>Try again</button>
        </div>
      </div>;

    } else {
      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='clubs.club_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='clubs.club_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='clubs.clubs' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='clubs.clubs' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='clubs.list_clubs' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/club/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle'></i> <FormattedMessage id='clubs.create_club' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <div id='deleteClubModal' className='modal fade' tabIndex={-1} role='dialog' aria-labelledby='myModalLabel' aria-hidden='true'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title' id='myModalLabel'>Delete club</h4>
                <button type='button' className='close' data-dismiss='modal' aria-hidden='true'>×</button>
              </div>
              <div className='modal-body'>
                Se va a eliminar definitivamente el club <b>"FC Barcelona"</b> del sistema.
                ¿Estás seguro de que quieres realizar esta acción?
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-info waves-effect' data-dismiss='modal'>Close</button>
                <button type='button' className='btn btn-danger waves-effect' data-dismiss='modal'>Delete</button>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getClubs: (limit: number, offset: number, query: string) => dispatch(requestClubs(limit, offset, query)),
  };
};

export default connect<Club, DispatchProps>(
  (store: StoreState) => store.club,
  mapDispatchToProps,
)(ListClubs);
