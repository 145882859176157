import { FormMedia } from '../redux/state/entities/FormMedia';

export default class ItemForm {
  constructor(
    public type: 'FOOTBALL_SHIRT',
    public playerId: number,
    public foundationId: number,
    public name: Map<any, any>,
    public description: Map<any, any>,
    public categoryId: number,
    public media: FormMedia,
    public media3dId: FormMedia,
    public mediaVideo: FormMedia,
    public leagueName: string,
    public leagueMedia: FormMedia,
    public clubId: number,
    public clubGoals: number,
    public visitorClubId: number,
    public visitorClubGoals: number,
    public userId: number,
    public additionalInformation: string,
    public created: string
  ) {
  }
}
