export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export type REQUEST_CATEGORIES = typeof REQUEST_CATEGORIES;

export const REQUEST_CATEGORIES_FAILED = 'REQUEST_CATEGORIES_FAILED';
export type REQUEST_CATEGORIES_FAILED = typeof REQUEST_CATEGORIES_FAILED;

export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS';
export type REQUEST_CATEGORIES_SUCCESS = typeof REQUEST_CATEGORIES_SUCCESS;

export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export type RECEIVE_CATEGORIES = typeof RECEIVE_CATEGORIES;

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export type CREATE_CATEGORY = typeof CREATE_CATEGORY;

export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';
export type CREATE_CATEGORY_FAILED = typeof CREATE_CATEGORY_FAILED;

export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export type CREATE_CATEGORY_SUCCESS = typeof CREATE_CATEGORY_SUCCESS;

export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export type EDIT_CATEGORY = typeof EDIT_CATEGORY;

export const EDIT_CATEGORY_FAILED = 'EDIT_CATEGORY_FAILED';
export type EDIT_CATEGORY_FAILED = typeof EDIT_CATEGORY_FAILED;

export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export type EDIT_CATEGORY_SUCCESS = typeof EDIT_CATEGORY_SUCCESS;
