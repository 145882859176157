import * as React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { StoreState } from 'src/redux/state';
import { Global } from 'src/redux/state/global';
import { SDKProvider } from '../providers/SDKProvider';
import Sidebar from './Sidebar';

class LayoutBase extends React.Component<Global, RouteProps> {


  render() {
    let loading;
    if (this.props.isLoading) {
      loading = <div className='loading'>
        <div className='loader'>
          <div className='loader__figure'></div>
        </div>
      </div>;
    }
    return (
      <div id='main-wrapper'>
        { loading }
        <header className='topbar'>
          <nav className='navbar top-navbar navbar-expand-md navbar-light'>
            <div className='navbar-header'>
              <Link className='navbar-brand' to='/'>
                <b>
                  <img style={{ maxHeight: 30 }} src={require('../../images/mdm-square.png')} alt='homepage' className='dark-logo' />
                </b>
                <span>
                  <img style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '50%',
                    paddingRight: '10px',
                  }} src={require('../../images/mdm.png')} alt='homepage' className='dark-logo' />
                </span>
              </Link>
            </div>

            <div className='navbar-collapse'>

              <ul className='navbar-nav mr-auto'>
                <li className='nav-item'> <a className='nav-link nav-toggler hidden-md-up waves-effect waves-dark'
                  href='javascript:void(0)'><i className='sl-icon-menu'></i></a> </li>
                <li className='nav-item'> <a className='nav-link sidebartoggler hidden-sm-down waves-effect waves-dark'
                  href='javascript:void(0)'><i className='sl-icon-menu'></i></a> </li>
              </ul>

              <ul className='navbar-nav my-lg-0'>

                <li className='nav-item dropdown'>
                  <a className='nav-link dropdown-toggle waves-effect waves-dark' href='' data-toggle='dropdown'
                    aria-haspopup='true' aria-expanded='false'> <i className='icon-Bell'></i>
                    <div className='notify'> <span className='heartbit'></span> <span className='point'></span> </div>
                  </a>
                  <div className='dropdown-menu dropdown-menu-right mailbox animated bounceInDown'>
                    <ul>
                      <li>
                        <div className='drop-title'>Notifications</div>
                      </li>
                      <li>
                        <div className='message-center'>
                          <a href='javascript:void()'>
                            <div className='btn btn-danger btn-circle'><i className='fa fa-link'></i></div>
                            <div className='mail-contnet'>
                              <h5>Luanch Admin</h5> <span className='mail-desc'>Just see the my new admin!</span> <span
                                className='time'>9:30 AM</span>
                            </div>
                          </a>
                          <a href='javascript:void()'>
                            <div className='btn btn-success btn-circle'><i className='ti-calendar'></i></div>
                            <div className='mail-contnet'>
                              <h5>Event today</h5> <span className='mail-desc'>Just a reminder that you have event</span> <span
                                className='time'>9:10 AM</span>
                            </div>
                          </a>
                          <a href='javascript:void()'>
                            <div className='btn btn-info btn-circle'><i className='ti-settings'></i></div>
                            <div className='mail-contnet'>
                              <h5>Settings</h5> <span className='mail-desc'>You can customize this template as you want</span>
                              <span className='time'>9:08 AM</span>
                            </div>
                          </a>
                          <a href='javascript:void()'>
                            <div className='btn btn-primary btn-circle'><i className='ti-user'></i></div>
                            <div className='mail-contnet'>
                              <h5>Pavan kumar</h5> <span className='mail-desc'>Just see the my admin!</span> <span
                                className='time'>9:02 AM</span>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li>
                        <a className='nav-link text-center' href='javascript:void(0);'> <strong>Check all notifications</strong></a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className='nav-item dropdown'>
                  <a className='nav-link dropdown-toggle waves-effect waves-dark' href='' data-toggle='dropdown'
                    aria-haspopup='true' aria-expanded='false'> <i className='flag-icon flag-icon-us'></i></a>
                  <div className='dropdown-menu dropdown-menu-right animated bounceInDown'> <a className='dropdown-item' href='javascript:void()'><i
                    className='flag-icon flag-icon-in'></i> India</a> <a className='dropdown-item' href='javascript:void()'><i
                      className='flag-icon flag-icon-fr'></i> French</a> <a className='dropdown-item' href='javascript:void()'><i
                        className='flag-icon flag-icon-cn'></i> China</a> <a className='dropdown-item' href='javascript:void()'><i
                          className='flag-icon flag-icon-de'></i> Dutch</a> </div>
                </li>

                <li className='nav-item dropdown u-pro'>
                  <a className='nav-link dropdown-toggle waves-effect waves-dark profile-pic' href='' data-toggle='dropdown'
                    aria-haspopup='true' aria-expanded='false'><img src={require('../../images/icon/staff.png')} alt='user'
                      className='' /> <span className='hidden-md-down'><i className='fa fa-angle-down' /></span>
                  </a>
                  <div className='dropdown-menu dropdown-menu-right animated flipInY'>
                    <ul className='dropdown-user'>
                      <li><a href='javascript:void()'><i className='ti-settings'></i> Account Setting</a></li>
                      <li role='separator' className='divider'></li>
                      <li><Link to={`/login`} onClick={() => SDKProvider.get().oauth.logout()}><i className='fa fa-power-off'></i> Logout</Link></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <Sidebar />

        <div className='page-wrapper'>

          {this.props.children}

          <footer className='footer'>
            © 2020 Shirtum Backend
          </footer>

        </div>

      </div >
    );
  }
}

export default connect<StoreState, {}>(
  (store: StoreState) => store.global,
  {},
)(LayoutBase);
