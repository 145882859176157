import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { addLocaleData, IntlProvider } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import * as Router from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './components/App';
import AddEditCategory from './components/categories/Add_Edit';
import ListCategories from './components/categories/List';
import AddEditClub from './components/clubs/Add_Edit';
import ListClubs from './components/clubs/List';
import Dashboard from './components/dashboard/Dashboard';
import NotFound from './components/errors/NotFound';
import AddEditNews from './components/fanzone/Add_Edit_News';
import Add_Moment from './components/fanzone/Add_Moment';
import ListNews from './components/fanzone/News';
import OrderFanzone from './components/fanzone/Order';
import AddEditFoundation from './components/foundations/Add_Edit';
import ListFoundations from './components/foundations/List';
import AddEditItem from './components/items/Add_Edit';
import ListItems from './components/items/List';
import AddLanguage from './components/languages/Add';
import EditLanguage from './components/languages/Edit';
import ListLanguages from './components/languages/List';
import LayoutBase from './components/layout/LayoutBase';
import License from './components/license/All';
import Licenses from './components/licenses/All';
import Login from './components/login/Login';
import AddEditPlayer from './components/players/Add_Edit';
import ListPlayers from './components/players/List';
import AddPresale from './components/presales/Add';
import ListPresales from './components/presales/List';
import ListEnded from './components/presales/ListEnded';
import { SDKProvider } from './components/providers/SDKProvider';
import Rate from './components/rate/All';
import Rates from './components/rates/All';
import Roles from './components/roles/All';
import Shirts from './components/shirts/All';
import GraphTransactions from './components/transactions/Graph';
import ListTransactions from './components/transactions/List';
import EditUser from './components/users/Edit';
import Users from './components/users/List';
import * as messagesEn from './locales/en.json';
import * as messagesEs from './locales/es.json';
import createRootReducer from './redux/reducers';

const httpLink = createHttpLink({
  uri: 'https://mdm-licenses.arkerlabs.com/graphql',
});

/*
const httpLink = createHttpLink({
  uri: 'http://localhost:3000/graphql',
}); */

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('gql_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const messages = {
  en: messagesEn,
  es: messagesEs,
};

addLocaleData([...locale_en, ...locale_es]);

const language = navigator.language.split(/[-_]/)[0];
const devTool = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];
const composeEnhancers = devTool || compose;

if (!devTool) {
  console.warn(
    'Install Redux DevTools Extension to inspect the app state: ' +
      'https://github.com/zalmoxisus/redux-devtools-extension#installation'
  );
}
export const history = createBrowserHistory();
const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);

const checkAuth = (Component: any, props: any) =>
  SDKProvider.get().oauth.isLogged() ? <Component {...props} /> : <Router.Redirect to='/login' />;

const PrivateRouteWithLayout = ({ component: Component, ...rest }) => (
  <Router.Route {...rest} render={(props) => <LayoutBase>{checkAuth(Component, props)}</LayoutBase>} />
);

const Home = (Component: any, props: any) =>
  SDKProvider.get().oauth.isLogged() ? <Router.Redirect to='/dashboard' /> : <Router.Redirect to='/login' />;

SDKProvider.get().oauth.onExpire(() => {
  (window as any)['location'] = '/login';
});

class AppFlow extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App>
            <Switch>
              <Home exact path='/' />
              <Router.Route path='/license' component={License} />
              <Router.Route path='/login' component={Login} />
              <PrivateRouteWithLayout path='/dashboard' component={Dashboard} />
              <PrivateRouteWithLayout path='/profile' component={Dashboard} />
              <PrivateRouteWithLayout path='/users' component={Users} />
              <PrivateRouteWithLayout path='/user/edit/:id' component={EditUser} />
              <PrivateRouteWithLayout path='/players' component={ListPlayers} />
              <PrivateRouteWithLayout path='/player/edit/:id' component={AddEditPlayer} />
              <PrivateRouteWithLayout path='/player/add' component={AddEditPlayer} />
              <PrivateRouteWithLayout path='/roles' component={Roles} />
              <PrivateRouteWithLayout path='/rate' component={Rate} />
              <PrivateRouteWithLayout path='/foundations' component={ListFoundations} />
              <PrivateRouteWithLayout path='/foundation/edit/:id' component={AddEditFoundation} />
              <PrivateRouteWithLayout path='/foundation/add' component={AddEditFoundation} />
              <PrivateRouteWithLayout path='/categories' component={ListCategories} />
              <PrivateRouteWithLayout path='/category/edit/:id' component={AddEditCategory} />
              <PrivateRouteWithLayout path='/category/add' component={AddEditCategory} />
              <PrivateRouteWithLayout path='/clubs' component={ListClubs} />
              <PrivateRouteWithLayout path='/club/edit/:id' component={AddEditClub} />
              <PrivateRouteWithLayout path='/club/add' component={AddEditClub} />
              <PrivateRouteWithLayout path='/items' component={ListItems} />
              <PrivateRouteWithLayout path='/item/edit/:id' component={AddEditItem} />
              <PrivateRouteWithLayout path='/item/add' component={AddEditItem} />
              <PrivateRouteWithLayout path='/languages' component={ListLanguages} />
              <PrivateRouteWithLayout path='/language/add' component={AddLanguage} />
              <PrivateRouteWithLayout path='/language/edit/:code' component={EditLanguage} />
              <PrivateRouteWithLayout path='/fanzone/order' component={OrderFanzone} />
              <PrivateRouteWithLayout path='/fanzone/moment/add' component={Add_Moment} />
              <PrivateRouteWithLayout path='/fanzone/news/add' component={AddEditNews} />
              <PrivateRouteWithLayout path='/fanzone/news/edit/:id' component={AddEditNews} />
              <PrivateRouteWithLayout path='/fanzone/news' component={ListNews} />
              <PrivateRouteWithLayout path='/player/license' component={License} />
              <PrivateRouteWithLayout path='/licenses' component={Licenses} />
              <PrivateRouteWithLayout path='/shirts' component={Shirts} />
              <PrivateRouteWithLayout path='/rates' component={Rates} />
              <PrivateRouteWithLayout path='/presales/add' component={AddPresale} />
              <PrivateRouteWithLayout path='/presales' component={ListPresales} />
              <PrivateRouteWithLayout path='/ended-presales' component={ListEnded} />
              <PrivateRouteWithLayout path='/transactions' component={ListTransactions} />
              <PrivateRouteWithLayout path='/graphs' component={GraphTransactions} />

              <Router.Route component={NotFound} />
            </Switch>
          </App>
        </ConnectedRouter>
      </Provider>
    );
  }
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <IntlProvider locale={language} messages={messages[language]}>
      <AppFlow />
    </IntlProvider>
  </ApolloProvider>,
  document.getElementById('app')
);
