import { CreateNewsSchema, EditNewsSchema } from 'crypthum-sdk';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import { createNewsAction, editNewsAction, requestNewsById } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { News } from '../../redux/state/news';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';

interface DispatchProps {
  getNewsById: (id: number) => void;
  createNews: (request: CreateNewsSchema.CreateNews) => void;
  editNews: (id: number, request: EditNewsSchema.EditNews) => void;
}

const getValueFromReactTags = (tags: { id: string; text: string }[]) => tags.map((tag) => tag.text).join(',');

interface AddEditNewsProps extends News, DispatchProps {}

class AddEditNews extends React.Component<AddEditNewsProps, RouteProps> {
  private newsId: number = this.props['match']['params']['id'];

  private title: React.RefObject<HTMLInputElement> = React.createRef();
  private content: React.RefObject<HTMLTextAreaElement> = React.createRef();
  private status: React.RefObject<HTMLSelectElement> = React.createRef();

  private newsKeywords: { id: string; text: string }[] = [];

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddEditNewsProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };

    this.props.news.data = null;
    this.validationService = addEditValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        title: this.validationService.getLastError('title'),
        content: this.validationService.getLastError('content'),
        status: this.validationService.getLastError('status'),
        newsKeywords: this.validationService.getLastError('newsKeywords'),
      },
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleAdditionNewsKeywords(tagName: string) {
    this.newsKeywords.push({ id: tagName, text: tagName });
  }

  handleDeleteNewsKeywords(tagIndex: number) {
    this.newsKeywords.splice(tagIndex, 1);
  }

  private isEdditing(): boolean {
    return this.newsId !== undefined;
  }

  componentDidMount() {
    if (this.isEdditing()) {
      this.props.getNewsById(this.newsId);
    }
  }

  submit(e) {
    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('title', GetReactRef.asString(this.title));
    this.validationService.updateValue('content', GetReactRef.asString(this.content));
    this.validationService.updateValue('status', GetReactRef.asNewsType(this.status));
    this.validationService.updateValue('newsKeywords', getValueFromReactTags(this.newsKeywords));

    if (this.handleValidation()) {
      this.sendNewsForm();
    }
  }

  private sendNewsForm() {
    const title = new Map();
    const content = new Map();

    title.set('en', GetReactRef.asString(this.title));
    content.set('en', GetReactRef.asString(this.content));

    if (this.isEdditing()) {
      this.props.editNews(
        this.props['match']['params']['id'],
        new EditNewsSchema.EditNews(
          getValueFromReactTags(this.newsKeywords),
          title,
          content,
          null, // TODO Upload media
          null, // TODO Select club
          GetReactRef.asNewsType(this.status)
        )
      );
    } else {
      this.props.createNews(
        new CreateNewsSchema.CreateNews(
          getValueFromReactTags(this.newsKeywords),
          title,
          content,
          null, // TODO upload media
          null, // TODO Select club
          GetReactRef.asNewsType(this.status)
        )
      );
    }
  }

  render() {
    if (this.props.news.isFetching) {
      return (
        <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
          <span className='sr-only'>...</span>
        </div>
      );
    }

    const hasNewsData = this.props.news.data != null && this.props.news.data.id === this.newsId;

    if (hasNewsData) {
      const newsKeywords = this.props.news.data ? this.props.news.data.keywords : null;

      if (this.newsKeywords.length === 0 && newsKeywords) {
        this.newsKeywords = newsKeywords.split(',').map((word) => ({ id: word, text: word }));
      }
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='news.news' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='news.news' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id={this.isEdditing() ? 'news.edit_news' : 'news.add_news'} />
              </li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>{this.isEdditing() ? 'Edit' : 'Create'} News</h3>

                    <hr />

                    <fieldset>
                      <div className='row p-t-20'>
                        <div className='col-md-9'>
                          <div className={'form-group' + (this.state.errors['title'] ? ' error' : '')}>
                            <label className='control-label'>Title</label>
                            <input
                              onChange={this.handleChange.bind(this, 'title')}
                              value={this.state.fields['title']}
                              ref={this.title}
                              defaultValue={this.props.news.data ? this.props.news.data.i18nTitleKey : ''}
                              type='text'
                              className='form-control'
                            />
                            <span className='error-message'>{this.state.errors['title']}</span>
                          </div>
                        </div>

                        <div className='col-md-3'>
                          <div className={'form-group' + (this.state.errors['status'] ? ' error' : '')}>
                            <label className='control-label'>Status</label>
                            <select
                              onChange={this.handleChange.bind(this, 'status')}
                              value={this.state.fields['status']}
                              ref={this.status}
                              defaultValue={
                                this.props.news.data ? this.props.news.data.status.toString() : ''
                              }
                              className='form-control custom-select'
                            >
                              <option value='' disabled>
                                -- Select status --
                              </option>
                              <option value='DRAFT'>Draft</option>
                              <option value='PUBLISHED'>Published</option>
                              <option value='DELETED'>Deleted</option>
                            </select>
                            <span className='error-message'>{this.state.errors['status']}</span>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['newsKeywords'] ? ' error' : '')}>
                            <label>Keywords</label>
                            <ReactTags
                              onChange={this.handleChange.bind(this, 'newsKeywords')}
                              inputFieldPosition='inline'
                              value={this.state.fields['newsKeywords']}
                              tags={this.newsKeywords}
                              allowDragDrop={false}
                              handleAddition={(tag: { id: string; text: string }) =>
                                this.handleAdditionNewsKeywords(tag.text)
                              }
                              handleDelete={(tagIndex: number) => this.handleDeleteNewsKeywords(tagIndex)}
                            />
                            <span className='error-message'>{this.state.errors['newsKeywords']}</span>
                          </div>
                        </div>

                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['content'] ? ' error' : '')}>
                            <label className='control-label'>Content</label>
                            <textarea
                              onChange={this.handleChange.bind(this, 'content')}
                              value={this.state.fields['content']}
                              ref={this.content}
                              defaultValue={this.props.news.data ? this.props.news.data.i18nContentKeys : ''}
                              className='form-control'
                              rows={5 as number}
                            />
                            <span className='error-message'>{this.state.errors['content']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className='form-actions text-right'>
                    <Link to='/fanzone/news'>
                      <button type='button' className='btn btn-info m-r-10'>
                        Cancel
                      </button>
                    </Link>

                    <button disabled={this.props.news.isFetching} className='btn btn-success' type='submit'>
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getNewsById: (id: number) => dispatch(requestNewsById(id)),
    createNews: (request: CreateNewsSchema.CreateNews) => dispatch(createNewsAction(request)),
    editNews: (id: number, request: EditNewsSchema.EditNews) => dispatch(editNewsAction(id, request)),
  };
};

export default connect<News, DispatchProps>(
  (store: StoreState) => store.news,
  mapDispatchToProps
)(AddEditNews);
