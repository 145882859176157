import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import {
  createFoundationAction,
  editFoundationAction,
  receiveFoundation,
  receiveUserSuccess,
  requestFoundation,
  requestUser,
  requestUsers,
} from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { FormMedia } from '../../redux/state/entities/FormMedia';
import FileDropzone from '../utils/FileDropzone';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';

interface DispatchProps {
  getUsers: (limit: number, offset: number, query: string) => void;
  getUserById: (userId: number) => void;
  getFoundationById: (foundationId: number) => void;
  resetUserData: () => void;
  resetFoundationData: () => void;
  createFoundation: (
    name: string,
    link: string,
    status: 'ENABLED' | 'DISABLED',
    type: 'FOUNDATION' | 'CAUSE',
    description: Map<any, any>,
    logo: FormMedia,
    userId: number
  ) => void;
  editFoundation: (
    id: number,
    name: string,
    link: string,
    status: 'ENABLED' | 'DISABLED',
    type: 'FOUNDATION' | 'CAUSE',
    description: Map<any, any>,
    logo: FormMedia,
    userId: number
  ) => void;
}

interface AddEditFoundationProps extends StoreState, DispatchProps {}

class AddEditFoundations extends React.Component<AddEditFoundationProps, RouteProps> {
  private foundationId: number = this.props['match']['params']['id'];

  private name: React.RefObject<HTMLInputElement> = React.createRef();
  private link: React.RefObject<HTMLInputElement> = React.createRef();
  private status: React.RefObject<HTMLSelectElement> = React.createRef();
  private type: React.RefObject<HTMLSelectElement> = React.createRef();
  private description: React.RefObject<HTMLTextAreaElement> = React.createRef();
  private userIdSelector: React.RefObject<Select> = React.createRef();
  private logoMedia: FormMedia;

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddEditFoundationProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };

    this.validationService = addEditValidations;
  }

  componentDidMount() {
    if (this.isEdditing()) {
      this.props.getFoundationById(this.foundationId);
      this.props.resetUserData();
    } else {
      this.props.getUsers(10, 0, '');
      this.props.resetFoundationData();
    }
    if (!this.props['match']['params']['id']) {
      this.props.foundation.foundationList.data = null;
    }
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        name: this.validationService.getLastError('name'),
        link: this.validationService.getLastError('link'),
        status: this.validationService.getLastError('status'),
        type: this.validationService.getLastError('type'),
        description: this.validationService.getLastError('description'),
        logoMedia: this.validationService.getLastError('logoMedia'),
        userId: this.validationService.getLastError('userId'),
      },
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  private isEdditing(): boolean {
    return this.foundationId !== undefined;
  }

  submit(e) {
    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('name', GetReactRef.asString(this.name));
    this.validationService.updateValue('link', GetReactRef.asString(this.link));
    this.validationService.updateValue('status', GetReactRef.asStatus(this.status));
    this.validationService.updateValue('type', GetReactRef.asFoundationType(this.type));
    this.validationService.updateValue('description', GetReactRef.asString(this.description));
    this.validationService.updateValue('logoMedia', this.getFileDropzoneFoundationData('logoMedia'));
    this.validationService.updateValue(
      'userId',
      this.userIdSelector.current.state.value ? this.userIdSelector.current.state.value.value : null
    );

    if (this.handleValidation()) {
      this.sendFoundationForm();
    }
  }

  getFileDropzoneFoundationData(type: string) {
    if (this.props.foundation.foundation.data && !(type in this.state.fields)) {
      return this.props.foundation.foundation.data[type].mediaStorage;
    } else if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  getUsersOptions = () => {
    if (this.isEdditing() && !this.props.user.userList.data && this.props.user.user.data) {
      return [
        {
          value: this.props.user.user.data.id,
          label:
            this.props.user.user.data.name +
            ' ' +
            this.props.user.user.data.surname +
            ' (' +
            this.props.user.user.data.id +
            ')',
        },
      ];
    }

    return this.props.user.userList.data
      ? this.props.user.userList.data.data.map((val) => ({
          value: val.id,
          label: val.name + ' ' + val.surname + ' (' + val.id + ')',
        }))
      : [];
  }

  inputHandleChangeUsers = (inputText: string) => {
    this.props.getUsers(10, 0, inputText);
  }

  private sendFoundationForm() {
    if (!this.isEdditing()) {
      this.props.createFoundation(
        GetReactRef.asString(this.name),
        GetReactRef.asString(this.link),
        GetReactRef.asStatus(this.status),
        GetReactRef.asFoundationType(this.type),
        new Map<any, any>([['en', GetReactRef.asString(this.description)]]),
        this.logoMedia,
        this.userIdSelector.current.state.value.value
      );
    } else {
      this.props.editFoundation(
        this.props['match']['params']['id'],
        GetReactRef.asString(this.name),
        GetReactRef.asString(this.link),
        GetReactRef.asStatus(this.status),
        GetReactRef.asFoundationType(this.type),
        new Map<any, any>([['en', GetReactRef.asString(this.description)]]),
        this.logoMedia,
        this.userIdSelector.current.state.value.value
      );
    }
  }

  render() {
    if (
      this.props.foundation.foundation.data &&
      !this.props.user.user.data &&
      this.props.user.user.isFetching === false
    ) {
      setTimeout(() => {
        this.props.getUserById(this.props.foundation.foundation.data.userId);
      }, 100);
    }

    if (this.props.foundation.foundation.isFetching) {
      return (
        <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
          <span className='sr-only'>...</span>
        </div>
      );
    }

    let selectUser = null;

    if (this.props.user.user.data && this.isEdditing()) {
      selectUser = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.userIdSelector}
          defaultValue={
            this.props.user.user.data && this.isEdditing()
              ? {
                  label:
                    this.props.user.user.data.name +
                    ' ' +
                    this.props.user.user.data.surname +
                    ' (' +
                    this.props.user.user.data.id +
                    ')',
                  value: this.props.user.user.data.id,
                }
              : ''
          }
          style={{ width: '100%' }}
          options={this.getUsersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    } else if (
      !this.isEdditing() ||
      (this.isEdditing() &&
        this.props.foundation.foundation.data &&
        this.props.foundation.foundation.data.userId == null)
    ) {
      selectUser = (
        <Select
          className='select2 m-b-10 select2-multiple'
          ref={this.userIdSelector}
          style={{ width: '100%' }}
          options={this.getUsersOptions()}
          onInputChange={this.inputHandleChangeUsers}
        />
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='foundations.foundations' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='foundations.foundations' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage
                  id={this.isEdditing() ? 'foundations.edit_foundation' : 'foundations.add_foundation'}
                />
              </li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>{this.isEdditing() ? 'Edit' : 'Create'} Foundation</h3>
                    <hr />
                    <fieldset>
                      <div className='row p-t-20'>
                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['name'] ? ' error' : '')}>
                            <label className='control-label'>Name</label>
                            <input
                              onChange={this.handleChange.bind(this, 'name')}
                              value={this.state.fields['name']}
                              defaultValue={
                                this.props.foundation.foundation.data && this.isEdditing()
                                  ? this.props.foundation.foundation.data.name
                                  : ''
                              }
                              ref={this.name}
                              type='text'
                              className='form-control'
                            />
                            <span className='error-message'>{this.state.errors['name']}</span>
                          </div>
                        </div>

                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['link'] ? ' error' : '')}>
                            <label className='control-label'>Link</label>
                            <input
                              onChange={this.handleChange.bind(this, 'link')}
                              value={this.state.fields['link']}
                              defaultValue={
                                this.props.foundation.foundation.data && this.isEdditing()
                                  ? this.props.foundation.foundation.data.link
                                  : ''
                              }
                              ref={this.link}
                              type='text'
                              className='form-control'
                            />
                            <span className='error-message'>{this.state.errors['link']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['userId'] ? ' error' : '')}>
                            <label className='control-label'>User</label>
                            {selectUser}
                            <span className='error-message'>{this.state.errors['userId']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['status'] ? ' error' : '')}>
                            <label className='control-label'>Status</label>
                            <select
                              onChange={this.handleChange.bind(this, 'status')}
                              value={this.state.fields['status']}
                              ref={this.status}
                              defaultValue={
                                this.props.foundation.foundation.data && this.isEdditing()
                                  ? this.props.foundation.foundation.data.status
                                  : ''
                              }
                              className='form-control custom-select'
                            >
                              <option value='' disabled>
                                -- Select status --
                              </option>
                              <option value='ENABLED'>Enabled</option>
                              <option value='DISABLED'>Disabled</option>
                            </select>
                            <span className='error-message'>{this.state.errors['status']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['type'] ? ' error' : '')}>
                            <label className='control-label'>Type</label>
                            <select
                              onChange={this.handleChange.bind(this, 'type')}
                              value={this.state.fields['type']}
                              ref={this.type}
                              defaultValue={
                                this.props.foundation.foundation.data && this.isEdditing()
                                  ? this.props.foundation.foundation.data.type
                                  : ''
                              }
                              className='form-control custom-select'
                            >
                              <option value='' disabled>
                                -- Select type --
                              </option>
                              <option value='FOUNDATION'>Foundation</option>
                              <option value='CAUSE'>Cause</option>
                            </select>
                            <span className='error-message'>{this.state.errors['type']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['description'] ? ' error' : '')}>
                            <label className='control-label'>Description</label>
                            <textarea
                              onChange={this.handleChange.bind(this, 'description')}
                              value={this.state.fields['description']}
                              ref={this.description}
                              defaultValue={
                                this.props.foundation.foundation.data && this.isEdditing()
                                  ? this.props.foundation.foundation.data.i18nDescriptionKey
                                  : ''
                              }
                              className='form-control'
                              rows={5 as number}
                              disabled={this.props.foundation.foundation.data ? true : false}
                            />
                            <span className='error-message'>{this.state.errors['description']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['logoMedia'] ? ' error' : '')}>
                            <label>Logo</label>
                            <FileDropzone
                              value={this.state.fields['logoMedia']}
                              accept='image/*'
                              preview={
                                !('logoMedia' in this.state.fields) && this.props.foundation.foundation.data
                                  ? this.props.foundation.foundation.data.logoMedia.mediaStorage
                                  : undefined
                              }
                              onFileChange={(file: FormMedia[]) => {
                                this.logoMedia = file[0];
                                this.handleDropzoneChange('logoMedia', file[0] ? file[0].previewUrl : '');
                              }}
                            />
                            <span className='error-message'>{this.state.errors['logoMedia']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className='form-actions text-right'>
                    <Link to='/foundations'>
                      <button type='button' className='btn btn-info m-r-10'>
                        Cancel
                      </button>
                    </Link>

                    <button
                      disabled={this.props.foundation.foundationList.isFetching}
                      className='btn btn-success'
                      type='submit'
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getUsers: (limit: number, offset: number, query: string) => dispatch(requestUsers(limit, offset, query)),
    getUserById: (userId: number) => dispatch(requestUser(userId)),
    getFoundationById: (foundationId: number) => dispatch(requestFoundation(foundationId)),
    resetUserData: () => dispatch(receiveUserSuccess(null)),
    resetFoundationData: () => dispatch(receiveFoundation(null)),
    createFoundation: (
      name: string,
      link: string,
      status: 'ENABLED' | 'DISABLED',
      type: 'FOUNDATION' | 'CAUSE',
      description: Map<any, any>,
      logo: FormMedia,
      userId: number
    ) => dispatch(createFoundationAction(name, link, status, type, description, logo, userId)),
    editFoundation: (
      id: number,
      name: string,
      link: string,
      status: 'ENABLED' | 'DISABLED',
      type: 'FOUNDATION' | 'CAUSE',
      description: Map<any, any>,
      logo: FormMedia,
      userId: number
    ) => dispatch(editFoundationAction(id, name, link, status, type, description, logo, userId)),
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps
)(AddEditFoundations);
