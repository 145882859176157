export const REQUEST_TRANSACTIONS = 'REQUEST_TRANSACTIONS';
export type REQUEST_TRANSACTIONS = typeof REQUEST_TRANSACTIONS;

export const REQUEST_TRANSACTIONS_FAILED = 'REQUEST_TRANSACTIONS_FAILED';
export type REQUEST_TRANSACTIONS_FAILED = typeof REQUEST_TRANSACTIONS_FAILED;

export const REQUEST_TRANSACTIONS_SUCCESS = 'REQUEST_TRANSACTIONS_SUCCESS';
export type REQUEST_TRANSACTIONS_SUCCESS = typeof REQUEST_TRANSACTIONS_SUCCESS;

export const RECEIVE_TRANSACTIONS = 'RECEIVE_TRANSACTIONS';
export type RECEIVE_TRANSACTIONS = typeof RECEIVE_TRANSACTIONS;

export const REQUEST_GRAPH = 'REQUEST_GRAPH';
export type REQUEST_GRAPH = typeof REQUEST_GRAPH;

export const REQUEST_GRAPH_FAILED = 'REQUEST_GRAPH_FAILED';
export type REQUEST_GRAPH_FAILED = typeof REQUEST_GRAPH_FAILED;

export const REQUEST_GRAPH_SUCCESS = 'REQUEST_GRAPH_SUCCESS';
export type REQUEST_GRAPH_SUCCESS = typeof REQUEST_GRAPH_SUCCESS;

export const RECEIVE_GRAPH = 'RECEIVE_GRAPH';
export type RECEIVE_GRAPH = typeof RECEIVE_GRAPH;
