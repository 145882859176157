import { ClubSchema } from 'crypthum-sdk';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import { createClubAction, editClubAction } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Club } from '../../redux/state/clubs';
import { FormMedia } from '../../redux/state/entities/FormMedia';
import FileDropzone from '../utils/FileDropzone';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { addEditValidations } from './validations/add-edit';

interface DispatchProps {
  createClub: (name: string, instagram: string, twitter: string, media: FormMedia) => void;
  editClub: (id: number, name: string, instagram: string, twitter: string, media: FormMedia) => void;
}

interface AddEditClubProps extends Club, DispatchProps { }

class AddEditClubs extends React.Component<AddEditClubProps, RouteProps> {

  private clubId: number = this.props['match']['params']['id'];

  private name: React.RefObject<HTMLInputElement> = React.createRef();
  private instagram: React.RefObject<HTMLInputElement> = React.createRef();
  private twitter: React.RefObject<HTMLInputElement> = React.createRef();

  private media: FormMedia;

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddEditClubProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {}
    };

    if (!this.props['match']['params']['id']) {
      this.props.clubList.data = null;
    }

    this.validationService = addEditValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        name: this.validationService.getLastError('name'),
        instagram: this.validationService.getLastError('instagram'),
        twitter: this.validationService.getLastError('twitter'),
        media: this.validationService.getLastError('media')
      }
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  private isEdditing(): boolean {
    return this.clubId !== undefined;
  }

  private retrieveClubToEdit(id: number) {

    const clubList = this.props.clubList.data ? this.props.clubList.data.data : [];

    for (let i = 0; i < clubList.length; i++) {
      if (clubList[i].id === id) {
        return clubList[i];
      }
    }

    return null;
  }

  submit(e) {

    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('name', GetReactRef.asString(this.name));
    this.validationService.updateValue('instagram', GetReactRef.asString(this.instagram));
    this.validationService.updateValue('twitter', GetReactRef.asString(this.twitter));
    this.validationService.updateValue('media', this.getFileDropzoneData('media'));

    if (this.handleValidation()) {
      this.sendClubForm();
    }
  }

  getFileDropzoneData(type: string) {
    if (this.props.clubList.data && !(type in this.state.fields)) {
      return this.props.clubList.data.data[this.clubId - 1][type].mediaStorage;
    } else if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  private sendClubForm() {

    if (this.props['match']['params']['id']) {
      this.props.editClub(
        this.props['match']['params']['id'],
        GetReactRef.asString(this.name),
        GetReactRef.asString(this.instagram),
        GetReactRef.asString(this.twitter),
        this.media
      );
    } else {
      this.props.createClub(
        GetReactRef.asString(this.name),
        GetReactRef.asString(this.instagram),
        GetReactRef.asString(this.twitter),
        this.media
      );
    }
  }

  render() {

    let clubData: ClubSchema.Club | null = null;

    if (this.clubId) {
      clubData = this.retrieveClubToEdit(this.clubId);
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='clubs.clubs' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='clubs.clubs' /></li>
              <li className='breadcrumb-item active'><FormattedMessage
                id={this.isEdditing() ? 'clubs.edit_club' : 'clubs.add_club'} /></li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>{this.isEdditing() ? 'Edit' : 'Create'} Club</h3>
                    <hr />
                    <fieldset>

                      <div className='row p-t-20'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['name'] ? ' error' : '')}>
                            <label>Name</label>
                            <input onChange={this.handleChange.bind(this, 'name')} value={this.state.fields['name']}
                              ref={this.name} defaultValue={(clubData) ? clubData.name : ''} type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['name']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['instagram'] ? ' error' : '')}>
                            <label>Instagram</label>
                            <input onChange={this.handleChange.bind(this, 'instagram')} value={this.state.fields['instagram']}
                              ref={this.instagram} defaultValue={(clubData) ? clubData.instagram : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['instagram']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['twitter'] ? ' error' : '')}>
                            <label>Twitter</label>
                            <input onChange={this.handleChange.bind(this, 'twitter')} value={this.state.fields['twitter']}
                              ref={this.twitter} defaultValue={(clubData) ? clubData.twitter : ''} type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['twitter']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['media'] ? ' error' : '')}>
                            <label>Media</label>
                            <FileDropzone value={this.state.fields['media']}
                              accept='image/*' preview={!('media' in this.state.fields) &&
                                clubData ? clubData.media.mediaStorage : undefined}
                              onFileChange={(file: FormMedia[]) => {
                                this.media = file[0];
                                this.handleDropzoneChange('media', file[0] ? file[0].previewUrl : '');
                              }} />
                            <span className='error-message'>{this.state.errors['media']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className='form-actions text-right'>
                    <Link to='/clubs'>
                      <button type='button' className='btn btn-info m-r-10'>Cancel</button>
                    </Link>

                    <button disabled={this.props.clubList.isFetching} className='btn btn-success' type='submit'>Save Changes</button>

                  </div>

                </form>
              </div>

            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    createClub: (name: string, instagram: string, twitter: string, media: FormMedia) => dispatch(
      createClubAction(name, instagram, twitter, media)
    ),
    editClub: (id: number, name: string, instagram: string, twitter: string, media: FormMedia) => dispatch(
      editClubAction(id, name, instagram, twitter, media)
    )
  };
};

export default connect<Club, DispatchProps>(
  (store: StoreState) => store.club,
  mapDispatchToProps,
)(AddEditClubs);
