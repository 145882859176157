import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestFoundations } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Foundation } from '../../redux/state/foundations';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getFoundations: (limit: number, offset: number, query: string) => void;
}

interface ListFoundationsProps extends Foundation, DispatchProps {}

class ListFoundations extends React.Component<ListFoundationsProps> {
  constructor(public readonly props: ListFoundationsProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getFoundations(10, 0, '');
  }

  render() {
    const columns = [
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'status',
        label: 'Status',
      },
      {
        name: 'type',
        label: 'Type',
      },
      {
        name: 'link',
        label: 'Link',
      },
      {
        name: 'created',
        label: 'Created',
      },
    ];

    if (localStorage.getItem('isMuseum') !== '1') {
      columns.push({
        name: 'options',
        label: 'Options',
      });
    }

    const data: any[] = [];
    if (this.props.foundationList.data) {
      for (const foundation of this.props.foundationList.data.data) {
        data.push({
          name: foundation.name,
          status: foundation.status,
          type: foundation.type,
          link: foundation.link,
          created: moment(foundation.created).format('YYYY-MM-DD HH:mm:ss'),
          options: (localStorage.getItem('isMuseum') !== '1') ? (
            <Link to={`/foundation/edit/${foundation.id}`}>
              <button className='btn btn-success waves-effect waves-light' type='button'>
                <i className='fa fa-pencil-alt' />
              </button>
            </Link>
          ) : ''
        });
      }
    }

    const count = this.props.foundationList.data ? this.props.foundationList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      isLoading: this.props.foundationList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getFoundations(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      },
    };

    let body;

    if (this.props.foundationList.error) {
      body = (
        <div className='card card-inverse card-danger'>
          <div className='card-body'>
            <h3 className='card-title'>Error fetching foundations</h3>
            <p className='card-text'>
              There was an error downloading the foundation list. Please, try again in a few minutes.
            </p>
            <button
              className='btn btn-inverse'
              onClick={() => {
                this.props.getFoundations(10, 0, '');
              }}
            >
              Try again
            </button>
          </div>
        </div>
      );
    } else {
      body = (
        <div className='row'>
          <div className='col-12'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>
                  <FormattedMessage id='foundations.foundation_table.title' />
                </h4>
                <h6 className='card-subtitle'>
                  <FormattedMessage id='foundations.foundation_table.subtitle' />
                </h6>
                <div className='table-responsive'>
                  <MUIDataTable data={data} columns={columns} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='foundations.foundations' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='foundations.foundations' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id='foundations.list_foundations' />
              </li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/foundation/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle' /> <FormattedMessage id='foundations.create_foundation' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getFoundations: (limit: number, offset: number, query: string) =>
      dispatch(requestFoundations(limit, offset, query)),
  };
};

export default connect<Foundation, DispatchProps>(
  (store: StoreState) => store.foundation,
  mapDispatchToProps
)(ListFoundations);
