export const REQUEST_PRESALES = 'REQUEST_PRESALES';
export type REQUEST_PRESALES = typeof REQUEST_PRESALES;

export const REQUEST_PRESALES_FAILED = 'REQUEST_PRESALES_FAILED';
export type REQUEST_PRESALES_FAILED = typeof REQUEST_PRESALES_FAILED;

export const REQUEST_PRESALES_SUCCESS = 'REQUEST_PRESALES_SUCCESS';
export type REQUEST_PRESALES_SUCCESS = typeof REQUEST_PRESALES_SUCCESS;

export const RECEIVE_PRESALES = 'RECEIVE_PRESALES';
export type RECEIVE_PRESALES = typeof RECEIVE_PRESALES;

export const CREATE_PRESALE = 'CREATE_PRESALE';
export type CREATE_PRESALE = typeof CREATE_PRESALE;

export const CREATE_PRESALE_FAILED = 'CREATE_PRESALE_FAILED';
export type CREATE_PRESALE_FAILED = typeof CREATE_PRESALE_FAILED;

export const REQUEST_ENDED_PRESALES = 'REQUEST_ENDED_PRESALES';
export type REQUEST_ENDED_PRESALES = typeof REQUEST_ENDED_PRESALES;

export const CREATE_PRESALE_SUCCESS = 'CREATE_PRESALE_SUCCESS';
export type CREATE_PRESALE_SUCCESS = typeof CREATE_PRESALE_SUCCESS;

export const RECEIVE_ENDED_PRESALES = 'RECEIVE_ENDED_PRESALES';
export type RECEIVE_ENDED_PRESALES = typeof RECEIVE_ENDED_PRESALES;

export const REQUEST_ENDED_PRESALES_FAILED = 'REQUEST_ENDED_PRESALES_FAILED';
export type REQUEST_ENDED_PRESALES_FAILED = typeof REQUEST_ENDED_PRESALES_FAILED;