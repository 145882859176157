import { GetUser } from 'crypthum-sdk';
import { PasswordRequest } from 'crypthum-sdk/dist/oauth/PasswordRequest';

import { SDKProvider } from '../../components/providers/SDKProvider';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const login = async (email: string, password: string): Promise<void> =>
  await cryptoMuseumsSDK.oauth.loginWithPassword(new PasswordRequest(email, password));

export interface Login {
  type: types.LOGIN;
}

export interface LoginFailed {
  type: types.LOGIN_FAILED;
  error: string;
}

export interface LoginSuccess {
  type: types.LOGIN_SUCCESS;
}

export type LoginAction = Login | LoginFailed | LoginSuccess;

export const loginSuccess = () => (dispatch) => {
  dispatch({ type: types.LOGIN_SUCCESS });
};

export const loginFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.LOGIN_FAILED, error: errorKey });
};

export const loginAction = (email: string, password: string) => (dispatch) => {
  login(email, password)
    .then(async (res) => {
      localStorage.setItem('gql_token', res['gql_token']);

      const user = await cryptoMuseumsSDK.endpoint<GetUser>(GetUser).run();

      localStorage.setItem('userId', user.id + '');

      localStorage.setItem('isMuseum', user.isPlayer ? '1' : '0');
      dispatch(loginSuccess());
    })
    .catch((e) => {
      dispatch(loginFailed(e));
    });
  dispatch({ type: types.LOGIN });
};
