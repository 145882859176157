import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'name',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Name cannot be empty'
      }
    ]
  },
  {
    name: 'instagram',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Instagram nick cannot be empty'
      }
    ]
  },
  {
    name: 'twitter',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Twitter nick cannot be empty'
      }
    ]
  },
  {
    name: 'media',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to upload a media'
      }
    ]
  }
]);
