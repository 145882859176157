import * as React from 'react';

import '../css/bootstrap.min.css';
import '../css/colors.css';
import '../css/datatables.min.css';
import '../css/dropzone.css';
import '../css/jquery.toast.css';
import '../css/pages/card-page.css';
import '../css/perfect-scrollbar.css';
import '../css/spinners.css';
import '../css/style.css';
import '../css/waves.min.css';
// tslint:disable-next-line: ordered-imports
import '../css/custom-style.css';
import '../ui/custom';

import 'jquery-tags-input/dist/jquery.tagsinput.min.css';
import 'jquery-tags-input/dist/jquery.tagsinput.min.js';
import 'react-notifications/lib/notifications.css';
import 'select2/dist/css/select2.min.css';

class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default App;
