import { UpdateUser } from 'crypthum-sdk/dist/schemas/UpdateUser';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import { editUserAction, requestClubs, requestUser } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';
import { editValidations } from './validations/edit';

interface DispatchProps {
  getUser: (id: number) => void;
  editUser: (id: number, request: UpdateUser) => void;
  getClubs: (limit: number, offset: number, query: string) => void;
}

interface EditUserProps extends StoreState, DispatchProps { }

class EditUsers extends React.Component<EditUserProps, RouteProps> {

  userId: number = this.props['match']['params']['id'];

  private firstName: React.RefObject<HTMLInputElement> = React.createRef();
  private lastName: React.RefObject<HTMLInputElement> = React.createRef();
  private email: React.RefObject<HTMLInputElement> = React.createRef();
  private alias: React.RefObject<HTMLInputElement> = React.createRef();
  private nationality: React.RefObject<HTMLInputElement> = React.createRef();
  private birthdate: React.RefObject<HTMLInputElement> = React.createRef();
  private favoriteClubId: React.RefObject<Select> = React.createRef();
  private gender: React.RefObject<HTMLSelectElement> = React.createRef();

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: EditUserProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {}
    };

    this.props.user.user.data = null;
    this.validationService = editValidations;
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        firstName: this.validationService.getLastError('firstName'),
        lastName: this.validationService.getLastError('lastName'),
        email: this.validationService.getLastError('email'),
        alias: this.validationService.getLastError('alias'),
        nationality: this.validationService.getLastError('nationality'),
        gender: this.validationService.getLastError('gender'),
        birthdate: this.validationService.getLastError('birthdate'),
        favoriteClubId: this.validationService.getLastError('favoriteClubId')
      }
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  submit(e) {

    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('firstName', GetReactRef.asString(this.firstName));
    this.validationService.updateValue('lastName', GetReactRef.asString(this.lastName));
    this.validationService.updateValue('email', GetReactRef.asString(this.email));
    this.validationService.updateValue('alias', GetReactRef.asString(this.alias));
    this.validationService.updateValue('nationality', GetReactRef.asString(this.nationality));
    this.validationService.updateValue('gender', GetReactRef.asGender(this.gender));
    this.validationService.updateValue('birthdate', GetReactRef.asString(this.birthdate));
    this.validationService.updateValue('favoriteClubId', this.favoriteClubId.current.state.value.value);

    if (this.handleValidation()) {
      this.sendUserForm();
    }

  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleSelectChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  componentDidMount() {
    this.props.getClubs(10, 0, '');
    this.props.getUser(this.userId);
  }

  inputHandleChange = (inputText: string) => {
    this.props.getClubs(10, 0, inputText);
  }

  getClubsOptions = () => this.props.club.clubList.data
    ? this.props.club.clubList.data.data.map(
      val => ({ value: val.id, label: val.name })
    )
    : []

  private sendUserForm() {
    const userData = new UpdateUser(
      GetReactRef.asString(this.firstName),
      GetReactRef.asString(this.lastName),
      GetReactRef.asString(this.alias),
      GetReactRef.asString(this.birthdate),
      GetReactRef.asGender(this.gender),
      GetReactRef.asString(this.email),
      GetReactRef.asString(this.nationality),
      this.favoriteClubId.current.state.value.value,
      null,
      null,
    );

    this.props.editUser(
      this.userId,
      userData
    );
  }

  render() {

    if (this.props.user.user.isFetching) {
      return <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='users.users' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='users.users' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='users.edit_user' /></li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'><FormattedMessage id='users.user_info' /></h3>
                    <hr />
                    <fieldset>
                      <div className='row p-t-20'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['firstName'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.name' /></label>
                            <input onChange={this.handleChange.bind(this, 'firstName')} value={this.state.fields['firstName']}
                              ref={this.firstName} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.name : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['firstName']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['lastName'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.surname' /></label>
                            <input onChange={this.handleChange.bind(this, 'lastName')} value={this.state.fields['lastName']}
                              ref={this.lastName} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.surname : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['lastName']}</span>
                          </div>
                        </div>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['email'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.email' /></label>
                            <input onChange={this.handleChange.bind(this, 'email')} value={this.state.fields['email']}
                              ref={this.email} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.email : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['email']}</span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className={'form-group' + (this.state.errors['gender'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.gender' /></label>
                            <select onChange={this.handleChange.bind(this, 'gender')} value={this.state.fields['gender']}
                              ref={this.gender} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.gender : ''}
                              className='form-control custom-select'>
                              <option value='' disabled>-- Select type --</option>
                              <option value='MALE'>Male</option>
                              <option value='FEMALE'>Female</option>
                              <option value='OTHER'>Other</option>
                            </select>
                            <span className='error-message'>{this.state.errors['gender']}</span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className={'form-group' + (this.state.errors['alias'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.alias' /></label>
                            <input onChange={this.handleChange.bind(this, 'alias')} value={this.state.fields['alias']}
                              ref={this.alias} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.alias : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['alias']}</span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className={'form-group' + (this.state.errors['nationality'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.nationality' /></label>
                            <input onChange={this.handleChange.bind(this, 'nationality')} value={this.state.fields['nationality']}
                              ref={this.nationality} defaultValue={(this.props.user.user.data) ? this.props.user.user.data.nationality : ''}
                              type='text' className='form-control' />
                            <span className='error-message'>{this.state.errors['nationality']}</span>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className={'form-group' + (this.state.errors['birthdate'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.date_of_birth' /></label>
                            <input onChange={this.handleChange.bind(this, 'birthdate')} value={this.state.fields['birthdate']}
                              ref={this.birthdate} type='text' className='form-control' defaultValue={(this.props.user.user.data) ?
                                moment(this.props.user.user.data.birthdate).format('YYYY-MM-DD') : ''} />
                            <span className='error-message'>{this.state.errors['birthdate']}</span>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className={'form-group' + (this.state.errors['favoriteClubId'] ? ' error' : '')}>
                            <label className='control-label'><FormattedMessage id='users.favorite_club' /></label>
                            <Select onChange={this.handleSelectChange.bind(this, 'favoriteClubId')} ref={this.favoriteClubId}
                              value={this.state.fields['favoriteClubId']} defaultValue={(this.props.user.user.data) ?
                                {
                                  //value: this.props.user.user.data.favoriteClub.id,
                                  //label: this.props.user.user.data.favoriteClub.name
                                }
                                : undefined} options={this.getClubsOptions()} onInputChange={this.inputHandleChange} />
                            <span className='error-message'>{this.state.errors['favoriteClubId']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className='form-actions text-right'>
                    <Link to='/users'>
                      <button type='button' className='btn btn-info m-r-10'>
                        <FormattedMessage id='users.cancel' />
                      </button>
                    </Link>

                    <button disabled={this.props.user.userList.isFetching} className='btn btn-success' type='submit'>
                      <FormattedMessage id='users.save_changes' />
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }

}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getUser: (id: number) => dispatch(requestUser(id)),
    editUser: (id: number, request: UpdateUser) => dispatch(editUserAction(id, request)),
    getClubs: (limit: number, offset: number, query: string) => dispatch(requestClubs(limit, offset, query))
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps,
)(EditUsers);
