import { ValidationService } from 'src/services/validation/validation.service';

export const addEditValidations = new ValidationService([
  {
    name: 'playerId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Player shirt cannot be empty',
      },
    ],
  },
  {
    name: 'foundationId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Foundation/Cause cannot be empty',
      },
    ],
  },
  {
    name: 'media',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to upload a media',
      },
    ],
  },
  {
    name: 'mediaVideo',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to upload a video',
      },
    ],
  },
  {
    name: 'leagueName',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'League name cannot be empty',
      },
    ],
  },
  {
    name: 'name',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Name cannot be empty',
      },
    ],
  },
  {
    name: 'leagueMedia',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'You have to upload a league media',
      },
    ],
  },
  {
    name: 'clubId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Club cannot be empty',
      },
    ],
  },
  {
    name: 'clubGoals',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Club goals required',
      },
    ],
  },
  {
    name: 'visitorClubGoals',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Rival club goals required',
      },
    ],
  },
  {
    name: 'userId',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'User cannot be empty',
      },
    ],
  },
  {
    name: 'created',
    type: 'text',
    value: '',
    validations: [
      {
        type: { name: 'notEmpty' },
        message: 'Date cannot be empty',
      },
    ],
  },
]);
