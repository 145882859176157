import { ListFoundation } from 'crypthum-sdk/dist/schemas/ListFoundation';
import { FoundationSchema } from 'crypthum-sdk/src';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Foundation {
  create: StateModel<void>;
  edit: StateModel<void>;
  delete: StateModel<void>;
  foundation: StateModel<FoundationSchema.Foundation>;
  foundationList: StateModel<ListFoundation>;
}

export function getInitialState(): Foundation {
  return {
    create: EmptyStateModel,
    edit: EmptyStateModel,
    delete: EmptyStateModel,
    foundation: EmptyStateModel,
    foundationList: EmptyStateModel,
  };
}
