import { FormMedia } from '../redux/state/entities/FormMedia';

export default class PlayerForm {
  constructor(
    public fullName: string,
    public hashtags: {id: string, text: string}[],
    public birthDate: Date | null,
    public birthPlace: string,
    public nationality: string,
    public position: string,
    public laterality: 'LEFT' | 'RIGHT' | 'BOTH' | '',
    public actualClub: string,
    public contractUntil: Date | null,
    public lastRenovation: Date | null,
    public clubHistory: { id: string, text: string }[],
    public instragram: string,
    public twitter: string,
    public youtube: string,
    public facebook: string,
    public linkedin: string,
    public shirtMedia: FormMedia,
    public backgroundMedia: FormMedia,
    public emblemMedia: FormMedia,
    public playerIntroMedia: FormMedia,
    public playerIdleMedia: FormMedia,
    public avatarMedia: FormMedia,
    public clubId: number,
    public type: 'PLAYER' | 'CLUB' | 'OTHER',
    public additionalInformation: string
  ) {
  }
}
