import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class Sidebar extends React.Component {
  state = {
    url: window.location.pathname,
  };

  private isMuseum = localStorage.getItem('isMuseum') === '1';

  private isActive(url) {
    return url === window.location.pathname;
  }

  private menuClicked() {
    this.state.url = window.location.pathname;
  }

  render() {
    return (
      <aside className='left-sidebar'>
        <div className='scroll-sidebar'>
          <nav className='sidebar-nav'>
            <ul id='sidebarnav'>
              {this.isMuseum ? (
                ''
              ) : (
                <li
                  className={
                    this.isActive('/users') || this.isActive('/players') || this.isActive('/roles')
                      ? 'active'
                      : ''
                  }
                >
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-User' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.users' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <Link
                        className={this.isActive('/players') ? 'active' : ''}
                        to='/players'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.players' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/users') ? 'active' : ''}
                        to='/users'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.users_sub' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/roles') ? 'active' : ''}
                        to='/roles'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.roles' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/rate') ? 'active' : ''}
                        to='/rate'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.rate' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/player/license') ? 'active' : ''}
                        to='/player/license'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.license' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/licenses') ? 'active' : ''}
                        to='/licenses'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.licenses' />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={this.isActive('/rates') ? 'active' : ''}
                        to='/rates'
                        onClick={() => this.menuClicked()}
                      >
                        <FormattedMessage id='sidebar.rates' />
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              <li className={this.isActive('/foundations') ? 'active' : ''}>
                <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                  <i className='icon-Double-Circle' />
                  <span className='hide-menu'>
                    <FormattedMessage id='sidebar.digitization' />
                  </span>
                </a>
                <ul aria-expanded='false' className='collapse'>
                  {this.isMuseum ? (
                    ''
                  ) : (
                    <li>
                      <Link className={this.isActive('/shirts') ? 'active' : ''} to='/shirts'>
                        <FormattedMessage id='sidebar.shirts' />
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link className={this.isActive('/items') ? 'active' : ''} to='/items'>
                      <FormattedMessage id='sidebar.assets' />
                    </Link>
                  </li>

                  {this.isMuseum ? (
                    ''
                  ) : (
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.pending_assets_revision' />
                      </a>
                    </li>
                  )}

                  <li>
                    <Link className={this.isActive('/foundations') ? 'active' : ''} to='/foundations'>
                      <FormattedMessage id='sidebar.foundations' />
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                  <i className='icon-Shop-2' />
                  <span className='hide-menu'>
                    <FormattedMessage id='sidebar.presale' />
                  </span>
                </a>
                <ul aria-expanded='false' className='collapse'>
                  <Link className={this.isActive('/presales') ? 'active' : ''} to='/presales'>
                    <FormattedMessage id='sidebar.active_presales' />
                  </Link>

                  <Link className={this.isActive('/ended-presales') ? 'active' : ''} to='/ended-presales'>
                    <FormattedMessage id='sidebar.ended_presales' />
                  </Link>

                  <Link className={this.isActive('/presales/add') ? 'active' : ''} to='/presales/add'>
                    <FormattedMessage id='sidebar.new_presale' />
                  </Link>
                </ul>
              </li>

              <li>
                <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                  <i className='icon-Rock-andRoll' />
                  <span className='hide-menu'>
                    <FormattedMessage id='sidebar.fanzone' />
                  </span>
                </a>
                <ul aria-expanded='false' className='collapse'>
                  <Link
                    className={this.isActive('/fanzone/moment/add') ? 'active' : ''}
                    to='/fanzone/moment/add'
                  >
                    <FormattedMessage id='sidebar.add_moment' />
                  </Link>
                </ul>
              </li>

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Speach-BubbleDialog' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.comments' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.list_comments' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.notices_reported_comments' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Shopping-Basket' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.store' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.weekly_calendar' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.create_edit_store_campaigns' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.configure_store_order' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Ticket' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.tickets' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.list_tickets' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.tickets_answered' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Speach-Bubble' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.notifications' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.create_notifications' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.list_notifications' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Email' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.mailings' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.create_campaign' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.edit_campaign_list' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              {this.isMuseum ? (
                ''
              ) : (
                <li className={this.isActive('/categories') || this.isActive('/clubs') ? 'active' : ''}>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className='icon-Network-Window' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.static_modules' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <Link className={this.isActive('/categories') ? 'active' : ''} to='/categories'>
                        <FormattedMessage id='sidebar.categories' />
                      </Link>
                    </li>
                    <li>
                      <Link className={this.isActive('/clubs') ? 'active' : ''} to='/clubs'>
                        <FormattedMessage id='sidebar.clubs' />
                      </Link>
                    </li>
                    <li>
                      <Link className={this.isActive('/languages') ? 'active' : ''} to='/languages'>
                        <FormattedMessage id='sidebar.translates' />
                      </Link>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.onboarding_tutorial' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.terms_and_conditions' />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.faq' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}

              <li>
                <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                  <i className='icon-Digital-Drawing' />
                  <span className='hide-menu'>
                    <FormattedMessage id='sidebar.stats_marketing' />
                  </span>
                </a>
                <ul aria-expanded='false' className='collapse'>
                  <li>
                    <Link className={this.isActive('/transactions') ? 'active' : ''} to='/transactions'>
                      <FormattedMessage id='sidebar.transactions' />
                    </Link>
                  </li>
                  <li>
                    <Link className={this.isActive('/graphs') ? 'active' : ''} to='/graphs'>
                      <FormattedMessage id='sidebar.graphs' />
                    </Link>
                  </li>
                </ul>
              </li>

              {this.isMuseum ? (
                ''
              ) : (
                <li>
                  <a className='has-arrow waves-effect waves-dark' aria-expanded='false'>
                    <i className=' icon-Next-Music' />
                    <span className='hide-menu'>
                      <FormattedMessage id='sidebar.media_library' />
                    </span>
                  </a>
                  <ul aria-expanded='false' className='collapse'>
                    <li>
                      <a href='#'>
                        <FormattedMessage id='sidebar.videos_photos' />
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
