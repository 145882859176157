import { push } from "connected-react-router";
import { AdminEditUser, GetUserById, GetUsers, GetUserWalletById, UserSchema } from "crypthum-sdk";
import { ListUser } from "crypthum-sdk/dist/schemas/ListUser";
import { UpdateUser } from "crypthum-sdk/dist/schemas/UpdateUser";
import { User } from "crypthum-sdk/dist/schemas/User";
import { UserWithItems } from "crypthum-sdk/src/schemas/UserWithItems";
import { Wallet } from "crypthum-sdk/src/schemas/Wallet";
import { NotificationManager } from "react-notifications";

import { SDKProvider } from "../../components/providers/SDKProvider";
import { endLoading, startLoading } from "./global";
import * as types from "./types";

const cryptoMuseumsSDK = SDKProvider.get();

const getUser = async (id: number): Promise<User> => {
  return await cryptoMuseumsSDK.endpoint<GetUserById>(GetUserById).run({ user_id: id });
};

const getUsers = async (limit: number, offset: number, query: string): Promise<ListUser> => {
  return await cryptoMuseumsSDK
    .endpoint<GetUsers>(GetUsers)
    .run({ limit: limit, offset: offset, query: query });
};

const editUser = async (id: number, body: UpdateUser) => {
  return await cryptoMuseumsSDK.endpoint<AdminEditUser>(AdminEditUser).run({
    user_id: id,
    body: body,
  });
};

const getUserWallet = async (id: number, limit: number, offset: number): Promise<Wallet> => {
  return await cryptoMuseumsSDK.endpoint<GetUserWalletById>(GetUserWalletById).run({
    id: id,
    limit: limit,
    offset: offset,
  });
};

export interface EditUser {
  type: types.EDIT_USER;
}

export interface EditUserFailed {
  type: types.EDIT_USER_FAILED;
  error: string;
}

export interface EditUserSuccess {
  type: types.EDIT_USER_SUCCESS;
}

export interface RequestUsersFailed {
  type: types.REQUEST_USERS_FAILED;
  error: string;
}

export interface RequestUsersSuccess {
  type: types.REQUEST_USERS_SUCCESS;
}

export interface RequestUsers {
  type: types.REQUEST_USERS;
}

export interface ReceiveUsers {
  type: types.RECEIVE_USERS;
  users: ListUser;
}

export interface RequestUser {
  type: types.REQUEST_USER;
}

export interface ReceiveUserSuccess {
  type: types.RECEIVE_USER_SUCCESS;
  user: UserWithItems;
}

export interface ReceiveUserFailed {
  type: types.RECEIVE_USER_FAILED;
  error: string;
}

export interface RequestUserWallet {
  type: types.REQUEST_USER_WALLET;
}

export interface ReceiveUserWalletSuccess {
  type: types.RECEIVE_USER_WALLET_SUCCESS;
  userWallet: Wallet;
}

export interface ReceiveUserWalletFailed {
  type: types.RECEIVE_USER_WALLET_FAILED;
  error: string;
}

export type UserAction =
  | RequestUserWallet
  | ReceiveUserWalletSuccess
  | ReceiveUserWalletFailed
  | RequestUsers
  | RequestUsersSuccess
  | RequestUsersFailed
  | ReceiveUsers
  | EditUser
  | EditUserFailed
  | EditUserSuccess
  | RequestUser
  | ReceiveUserSuccess
  | ReceiveUserFailed;

export const receiveUserSuccess = (user: UserSchema.User) => (dispatch) => {
  dispatch({ type: types.RECEIVE_USER_SUCCESS, user: user });
};

export const receiveUserFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.RECEIVE_USER_FAILED, error: errorKey });
};

export const requestUser = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_USER, id: id });
  getUser(id)
    .then((user) => {
      dispatch(receiveUserSuccess(user));
    })
    .catch((e) => {
      dispatch(receiveUserFailed(e));
    });
};

export const editUserSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_USER_SUCCESS });
  dispatch(push("/users"));
  NotificationManager.success("El usuario ha sido editado correctamente.");
};

export const editUserFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_USER_FAILED, error: errorKey });
  NotificationManager.error("Ha ocurrido un error al editar el usuario.");
};

export const editUserAction = (id: number, request: UpdateUser) => (dispatch) => {
  dispatch({ type: types.EDIT_USER });
  dispatch(startLoading());
  editUser(id, request)
    .then(() => {
      dispatch(editUserSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editUserFailed(e));
      dispatch(endLoading());
    });
};

export const receiveUsers = (users: ListUser) => (dispatch) => {
  dispatch({ type: types.RECEIVE_USERS, users: users });
};

export const requestUsersFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_USERS_FAILED, error: errorKey });
};

export const requestUsers = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_USERS });
  getUsers(limit, offset, query)
    .then((users) => {
      dispatch(receiveUsers(users));
    })
    .catch((e) => {
      dispatch(requestUsersFailed(e));
    });
};

export const requestUsersSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_USERS_SUCCESS });
};

export const receiveUserWalletSuccess = (userWallet: Wallet) => (dispatch) => {
  dispatch({ type: types.RECEIVE_USER_WALLET_SUCCESS, userWallet: userWallet });
};

export const receiveUserWalletFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.RECEIVE_USER_WALLET_FAILED, error: errorKey });
};

export const requestUserWallet = (id: number, limit: number, offset: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_USER_WALLET });
  getUserWallet(id, limit, offset)
    .then((userWallet) => {
      dispatch(receiveUserWalletSuccess(userWallet));
    })
    .catch((e) => {
      dispatch(receiveUserWalletFailed(e));
    });
};
