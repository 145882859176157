export const REQUEST_USERS = 'REQUEST_USERS';
export type REQUEST_USERS = typeof REQUEST_USERS;

export const REQUEST_USERS_FAILED = 'REQUEST_USERS_FAILED';
export type REQUEST_USERS_FAILED = typeof REQUEST_USERS_FAILED;

export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS';
export type REQUEST_USERS_SUCCESS = typeof REQUEST_USERS_SUCCESS;

export const RECEIVE_USERS = 'RECEIVE_USERS';
export type RECEIVE_USERS = typeof RECEIVE_USERS;

export const EDIT_USER = 'EDIT_USER';
export type EDIT_USER = typeof EDIT_USER;

export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export type EDIT_USER_FAILED = typeof EDIT_USER_FAILED;

export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export type EDIT_USER_SUCCESS = typeof EDIT_USER_SUCCESS;

export const REQUEST_USER = 'REQUEST_USER';
export type REQUEST_USER = typeof REQUEST_USER;

export const RECEIVE_USER_FAILED = 'RECEIVE_USER_FAILED';
export type RECEIVE_USER_FAILED = typeof RECEIVE_USER_FAILED;

export const RECEIVE_USER_SUCCESS = 'RECEIVE_USER_SUCCESS';
export type RECEIVE_USER_SUCCESS = typeof RECEIVE_USER_SUCCESS;

export const REQUEST_USER_WALLET = 'REQUEST_USER_WALLET';
export type REQUEST_USER_WALLET = typeof REQUEST_USER_WALLET;

export const RECEIVE_USER_WALLET_FAILED = 'RECEIVE_USER_WALLET_FAILED';
export type RECEIVE_USER_WALLET_FAILED = typeof RECEIVE_USER_WALLET_FAILED;

export const RECEIVE_USER_WALLET_SUCCESS = 'RECEIVE_USER_WALLET_SUCCESS';
export type RECEIVE_USER_WALLET_SUCCESS = typeof RECEIVE_USER_WALLET_SUCCESS;