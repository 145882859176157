export const REQUEST_ITEMS = 'REQUEST_ITEMS';
export type REQUEST_ITEMS = typeof REQUEST_ITEMS;

export const REQUEST_ITEMS_FAILED = 'REQUEST_ITEMS_FAILED';
export type REQUEST_ITEMS_FAILED = typeof REQUEST_ITEMS_FAILED;

export const REQUEST_ITEMS_SUCCESS = 'REQUEST_ITEMS_SUCCESS';
export type REQUEST_ITEMS_SUCCESS = typeof REQUEST_ITEMS_SUCCESS;

export const RECEIVE_ITEMS = 'RECEIVE_ITEMS';
export type RECEIVE_ITEMS = typeof RECEIVE_ITEMS;

export const CREATE_ITEM = 'CREATE_ITEM';
export type CREATE_ITEM = typeof CREATE_ITEM;

export const CREATE_ITEM_FAILED = 'CREATE_ITEM_FAILED';
export type CREATE_ITEM_FAILED = typeof CREATE_ITEM_FAILED;

export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export type CREATE_ITEM_SUCCESS = typeof CREATE_ITEM_SUCCESS;

export const EDIT_ITEM = 'EDIT_ITEM';
export type EDIT_ITEM = typeof EDIT_ITEM;

export const EDIT_ITEM_FAILED = 'EDIT_ITEM_FAILED';
export type EDIT_ITEM_FAILED = typeof EDIT_ITEM_FAILED;

export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export type EDIT_ITEM_SUCCESS = typeof EDIT_ITEM_SUCCESS;

export const REQUEST_ITEM = 'REQUEST_ITEM';
export type REQUEST_ITEM = typeof REQUEST_ITEM;

export const REQUEST_ITEM_FAILED = 'REQUEST_ITEM_FAILED';
export type REQUEST_ITEM_FAILED = typeof REQUEST_ITEM_FAILED;

export const REQUEST_ITEM_SUCCESS = 'REQUEST_ITEM_SUCCESS';
export type REQUEST_ITEM_SUCCESS = typeof REQUEST_ITEM_SUCCESS;

export const RECEIVE_ITEM = 'RECEIVE_ITEM';
export type RECEIVE_ITEM = typeof RECEIVE_ITEM;
