import { TransactionAction } from '../actions';
import * as TransactionActionType from '../actions/types/transactions';
import { getInitialState, Transaction } from '../state/transactions';

export const transactions = (
  state: Transaction = getInitialState(),
  action: TransactionAction
): Transaction => {
  switch (action.type) {
    case TransactionActionType.REQUEST_TRANSACTIONS:
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isFetching: true,
        },
      };
    case TransactionActionType.RECEIVE_TRANSACTIONS:
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isFetching: false,
          data: action.transactions,
        },
      };
    case TransactionActionType.REQUEST_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isFetching: false,
          error: action.error,
        },
      };
    case TransactionActionType.REQUEST_GRAPH:
      return {
        ...state,
        graphsList: {
          ...state.graphsList,
          isFetching: true,
        },
      };
    case TransactionActionType.RECEIVE_GRAPH:
      return {
        ...state,
        graphsList: {
          ...state.graphsList,
          isFetching: false,
          data: action.graph,
        },
      };
    case TransactionActionType.REQUEST_GRAPH_FAILED:
      return {
        ...state,
        graphsList: {
          ...state.graphsList,
          isFetching: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};
