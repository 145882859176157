import { FanzoneData } from 'crypthum-sdk/dist/schemas/FanzoneData';

import { EmptyStateModel, StateModel } from './types/state.model';

export interface Fanzone {
  edit: StateModel<void>;
  fanzoneList: StateModel<FanzoneData[]>;
  createMoment: StateModel<void>;
}

export function getInitialState(): Fanzone {
  return {
    edit: EmptyStateModel,
    fanzoneList: EmptyStateModel,
    createMoment: EmptyStateModel
  };
}
