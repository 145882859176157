import { FanzoneData } from 'crypthum-sdk/dist/schemas/FanzoneData';
import { NewFanzoneData } from 'crypthum-sdk/dist/schemas/NewFanzoneData';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { editFanzoneAction, requestFanzoneAction } from 'src/redux/actions';
import { StoreState } from 'src/redux/state';

const SortableNewsItem = SortableElement(({ value }) => (
  <li style={{ height: 120 }} className='SortableItem'>
    <div className='card card-fanzone-news'>
      <div className='card-body collapse show'>
        <img
          className='card-img-top img-responsive img-fanzone-news'
          src={require('../../images/mdm-square.png')}
          alt='Card image cap'
        />
        <h4 className='card-title p-l-120'>Una noticia</h4>
        <p className='card-text p-l-120'>
          With supporting text below as a natural lead-in to additional content.
        </p>
      </div>
    </div>
  </li>
));

const SortablePollItem = SortableElement(({ value }) => (
  <li style={{ height: 120 }} className='SortableItem'>
    <div className='card card-fanzone-news'>
      <div className='card-body collapse show'>
        <img
          className='card-img-top img-responsive img-fanzone-news'
          src={require('../../images/mdm-square.png')}
          alt='Card image cap'
        />
        <h4 className='card-title p-l-120'>Una encuesta</h4>
        <p className='card-text p-l-120'>
          With supporting text below as a natural lead-in to additional content.
        </p>
      </div>
    </div>
  </li>
));

const SortableGalleryItem = SortableElement(({ value }) => (
  <li style={{ height: 120 }} className='SortableItem'>
    <div className='card card-fanzone-news'>
      <div className='card-body collapse show'>
        <img
          className='card-img-top img-responsive img-fanzone-news'
          src={require('../../images/mdm-square.png')}
          alt='Card image cap'
        />
        <h4 className='card-title p-l-120'>Una galería</h4>
        <p className='card-text p-l-120'>
          With supporting text below as a natural lead-in to additional content.
        </p>
      </div>
    </div>
  </li>
));

const SortableFollowItem = SortableElement(({ value }) => (
  <li style={{ height: 120 }} className='SortableItem'>
    <div className='card card-fanzone-news'>
      <div className='card-body collapse show'>
        <img
          className='card-img-top img-responsive img-fanzone-news'
          src={require('../../images/mdm-square.png')}
          alt='Card image cap'
        />
        <h4 className='card-title p-l-120'>Un follow</h4>
        <p className='card-text p-l-120'>
          With supporting text below as a natural lead-in to additional content.
        </p>
      </div>
    </div>
  </li>
));

const SortableCustomContainer = SortableContainer(({ children }) => {
  return <ul className='SortableList'>{children}</ul>;
});

interface DispatchProps {
  listFanzone: () => void;
  editFanzone: (newFanzone: NewFanzoneData[]) => void;
}

interface AddEditClubProps extends FanzoneData, DispatchProps {}

class OrderFanzone extends React.Component<AddEditClubProps, RouteProps> {
  constructor(public readonly props: AddEditClubProps) {
    super(props);
  }
  /*
  state = {
    items: [
      { 'type': 'POLL', 'data': 1 },
      { 'type': 'NEWS', 'data': 3 },
      { 'type': 'GALLERY' },
      { 'type': 'FOLLOW' }]
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  }

  addNewToList(type: string) {
    const items = Object.assign([], this.state.items);
    items.unshift({ 'type': type });
    this.setState({ items: items });
  }

  sendNewFanzone() {

    const finalObject: NewFanzoneData[] = [];

    this.state.items.map((value: { type: 'NEWS' | 'GALLERY' | 'FOLLOW' | 'POLL', data: number }, index) => {
      finalObject.push({ 'order': index, 'type': value.type, 'data': value.data, 'id': null });
    });

    this.props.editFanzone(finalObject);
  }

  render() {

    const { items } = this.state;

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-3 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='fanzone.fanzone' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='fanzone.fanzone' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='fanzone.list_modules' /></li>
            </ol>
          </div>
          <div className='col-md-9 align-self-center text-right d-none d-md-block'>
            <Link to='/player/add'>
              <button type='button' className='btn btn-info m-r-20'>
                <FormattedMessage id='fanzone.add_new' />
              </button>
            </Link>
            <Link to='/player/add'>
              <button type='button' className='btn btn-info m-r-20'>
                <FormattedMessage id='fanzone.add_poll' />
              </button>
            </Link>
            <button type='button' onClick={() => { this.addNewToList('FOLLOW'); }} className='btn btn-info m-r-20'>
              <FormattedMessage id='fanzone.add_followers' />
            </button>
            <button type='button' onClick={() => { this.addNewToList('GALLERY'); }} className='btn btn-info'>
              <FormattedMessage id='fanzone.add_gallery' />
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <SortableCustomContainer onSortEnd={this.onSortEnd}>
              {
                items.map((value: { type: 'NEWS' | 'GALLERY' | 'FOLLOW' | 'POLL', data: number }, index) => {

                  let block: any;

                  if (value.type === 'NEWS') {
                    block = <SortableNewsItem key={`item-${index}`} index={index} value={value.type} />;
                  } else if (value.type === 'GALLERY') {
                    block = <SortableGalleryItem key={`item-${index}`} index={index} value={value.type} />;
                  } else if (value.type === 'FOLLOW') {
                    block = <SortableFollowItem key={`item-${index}`} index={index} value={value.type} />;
                  } else if (value.type === 'POLL') {
                    block = <SortablePollItem key={`item-${index}`} index={index} value={value.type} />;
                  }

                  return block;
                })
              }
            </SortableCustomContainer>
          </div>
        </div>

        <div className='form-actions text-right'>
          <Link to='/fanzone/order'>
            <button type='button' className='btn btn-info m-r-10'>Cancel</button>
          </Link>
          <button type='button' className='btn btn-success' onClick={() => { this.sendNewFanzone(); }} >Save Changes</button>
        </div>

      </div>
    );
  } */
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    listFanzone: () => dispatch(requestFanzoneAction()),
    editFanzone: (newFanzone: NewFanzoneData[]) => dispatch(editFanzoneAction(newFanzone)),
  };
};

export default connect<FanzoneData, DispatchProps>(
  (store: StoreState) => store.club,
  mapDispatchToProps
)(OrderFanzone);
