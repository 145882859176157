export const REQUEST_ROLES = 'REQUEST_ROLES';
export type REQUEST_ROLES = typeof REQUEST_ROLES;

export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export type RECEIVE_ROLES = typeof RECEIVE_ROLES;

export const NEW_ROL = 'NEW_ROL';
export type NEW_ROL = typeof NEW_ROL;

export const NEW_ROL_FAILED = 'NEW_ROL_FAILED';
export type NEW_ROL_FAILED = typeof NEW_ROL_FAILED;

export const NEW_ROL_SUCCESS = 'NEW_ROL_SUCCESS';
export type NEW_ROL_SUCCESS = typeof NEW_ROL_SUCCESS;

export const EDIT_ROL = 'EDIT_ROL';
export type EDIT_ROL = typeof EDIT_ROL;

export const EDIT_ROL_FAILED = 'EDIT_ROL_FAILED';
export type EDIT_ROL_FAILED = typeof EDIT_ROL_FAILED;

export const EDIT_ROL_SUCCESS = 'EDIT_ROL_SUCCESS';
export type EDIT_ROL_SUCCESS = typeof EDIT_ROL_SUCCESS;

export const OPEN_ADD_MODAL = 'OPEN_ADD_MODAL';
export type OPEN_ADD_MODAL = typeof OPEN_ADD_MODAL;

export const CLOSE_ADD_MODAL = 'CLOSE_ADD_MODAL';
export type CLOSE_ADD_MODAL = typeof CLOSE_ADD_MODAL;

export const OPEN_EDIT_MODAL = 'OPEN_EDIT_MODAL';
export type OPEN_EDIT_MODAL = typeof OPEN_EDIT_MODAL;

export const CLOSE_EDIT_MODAL = 'CLOSE_EDIT_MODAL';
export type CLOSE_EDIT_MODAL = typeof CLOSE_EDIT_MODAL;

export const REQUEST_ACTIONS = 'REQUEST_ACTIONS';
export type REQUEST_ACTIONS = typeof REQUEST_ACTIONS;

export const RECEIVE_ACTIONS = 'RECEIVE_ACTIONS';
export type RECEIVE_ACTIONS = typeof RECEIVE_ACTIONS;

export const ADD_USER_TO_ROL = 'ADD_USER_TO_ROL';
export type ADD_USER_TO_ROL = typeof ADD_USER_TO_ROL;

export const REMOVE_USER_TO_ROL = 'REMOVE_USER_TO_ROL';
export type REMOVE_USER_TO_ROL = typeof REMOVE_USER_TO_ROL;
