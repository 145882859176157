import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestNews } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { News } from '../../redux/state/news';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getNews: (limit: number, offset: number, query: string) => void;
}

interface ListNewsProps extends News, DispatchProps { }

class ListNews extends React.Component<ListNewsProps> {

  constructor(public readonly props: ListNewsProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getNews(10, 0, '');
  }

  render() {
    const columns = [
      {
        name: 'title',
        label: 'Title'
      }, {
        name: 'keywords',
        label: 'Keywords'
      }, {
        name: 'created',
        label: 'Created'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const data: any[] = [];

    if (this.props.newsList.data) {
      for (const news of this.props.newsList.data.data) {
        data.push({
          title: news.i18nTitleKey,
          keywords: news.keywords,
          created: moment(news.created).format('YYYY-MM-DD HH:mm:ss'),
          options: <div>
            <Link to={`/fanzone/news/edit/${news.id}`}>
              <button className='btn btn-success waves-effect waves-light' type='button'>
                <i className='fa fa-pencil-alt' />
              </button>
            </Link>
            <button className='btn btn-googleplus waves-effect waves-light m-l-10' type='button'
              data-id={`${news.id}`} data-toggle='modal' data-target='#deleteNewsModal'>
              <i className='fa fa-trash-alt' />
            </button>
          </div>
        });
      }
    }

    const count = this.props.newsList.data ? this.props.newsList.data.count : 0;

    const options = {
      ...dataTableOptions,
      count: count,
      isLoading: this.props.newsList.isFetching,
      onTableChange: (action, tableState) => {
        const search = tableState.searchText == null ? '' : tableState.searchText;
        this.props.getNews(tableState.rowsPerPage, tableState.rowsPerPage * tableState.page, search);
      }
    };

    let body;

    if (this.props.newsList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching news</h3>
          <p className='card-text'>There was an error downloading the news list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getNews(100, 0, ''); }}>Try again</button>
        </div>
      </div>;

    } else {
      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='news.news_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='news.news_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='news.news' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='news.news' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='news.list_news' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/fanzone/news/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle'></i> <FormattedMessage id='news.create_news' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <div id='deleteNewsModal' className='modal fade' tabIndex={-1} role='dialog' aria-labelledby='myModalLabel' aria-hidden='true'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title' id='myModalLabel'>Delete news</h4>
                <button type='button' className='close' data-dismiss='modal' aria-hidden='true'>×</button>
              </div>
              <div className='modal-body'>
                Se va a eliminar definitivamente la noticia <b>"FC Barcelona gana la liga"</b> del sistema.
                ¿Estás seguro de que quieres realizar esta acción?
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-info waves-effect' data-dismiss='modal'>Close</button>
                <button type='button' className='btn btn-danger waves-effect' data-dismiss='modal'>Delete</button>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getNews: (limit: number, offset: number, query: string) => dispatch(requestNews(limit, offset, query)),
  };
};

export default connect<News, DispatchProps>(
  (store: StoreState) => store.news,
  mapDispatchToProps,
)(ListNews);
