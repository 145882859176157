import { RolAction } from '../actions';
import * as RoleAction from '../actions/types/roles';
import { getInitialState, Rol } from '../state/roles';

export const roles = (state: Rol = getInitialState(), action: RolAction): Rol => {
  switch (action.type) {
    case RoleAction.NEW_ROL:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true
        }
      };
    case RoleAction.NEW_ROL_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false
        }
      };
    case RoleAction.NEW_ROL_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error
        }
      };
    case RoleAction.EDIT_ROL:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true
        }
      };
    case RoleAction.EDIT_ROL_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false
        }
      };
    case RoleAction.EDIT_ROL_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error
        }
      };
    case RoleAction.REQUEST_ROLES:
      return {
        ...state,
        rolList: {
          ...state.rolList,
          isFetching: true
        }
      };
    case RoleAction.RECEIVE_ROLES:
      return {
        ...state,
        rolList: {
          ...state.rolList,
          isFetching: false,
          data: action.roles
        }
      };
    case RoleAction.REQUEST_ACTIONS:
      return {
        ...state,
        actionList: {
          ...state.actionList,
          isFetching: true
        }
      };
    case RoleAction.RECEIVE_ACTIONS:
      return {
        ...state,
        actionList: {
          ...state.actionList,
          isFetching: false,
          data: action.actions
        }
      };
    case RoleAction.OPEN_ADD_MODAL:
      return {
        ...state,
        addModal: true,
        roleUsers: []
      };
    case RoleAction.CLOSE_ADD_MODAL:
      return {
        ...state,
        addModal: false
      };
    case RoleAction.OPEN_EDIT_MODAL:
      return {
        ...state,
        editModal: action.rol,
        addModal: false,
        roleUsers: action.rol.users.map(user => ({ id: user, text: user}))
      };
    case RoleAction.CLOSE_EDIT_MODAL:
      return {
        ...state,
        editModal: null
      };
    default:
      return state;
  }
};
