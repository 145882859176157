import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, RouteProps } from 'react-router-dom';
import Select from 'react-select';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ValidationService } from 'src/services/validation/validation.service';

import { createMomentAction, requestItems, requestPlayers } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { FormMedia } from '../../redux/state/entities/FormMedia';
import FileDropzone from '../utils/FileDropzone';
import { addMomentValidations } from './validations/add-moment';

interface DispatchProps {
  getUsers: (limit: number, offset: number, query: string) => void;
  getItems: (limit: number, offset: number, query: string) => void;
  createMoment: (video: FormMedia, userId: number, itemId: number) => void;
}

interface AddMomentProps extends StoreState, DispatchProps {}

class AddMoment extends React.Component<AddMomentProps, RouteProps> {
  private userIdSelector: React.RefObject<Select> = React.createRef();
  private video: FormMedia;
  private itemId: React.RefObject<Select> = React.createRef();

  private validationService: ValidationService;
  private formSubmitted = false;

  constructor(public readonly props: AddMomentProps) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
    };

    this.validationService = addMomentValidations;
  }

  componentDidMount() {
    this.props.getUsers(10, 0, '');
    this.props.getItems(10, 0, '');
  }

  handleValidation(inputName?: string) {
    if (!this.formSubmitted) {
      return true;
    } else if (!inputName) {
      this.validationService.validate();
    } else {
      this.validationService.validate(inputName);
    }

    this.setState({
      errors: {
        video: this.validationService.getLastError('video'),
        userId: this.validationService.getLastError('userId'),
        itemId: this.validationService.getLastError('itemId'),
      },
    });

    return this.validationService.getAllErrors().globalStatus;
  }

  submit(e) {
    this.formSubmitted = true;

    e.preventDefault();

    this.validationService.updateValue('video', this.getFileDropzoneFoundationData('video'));
    this.validationService.updateValue(
      'userId',
      this.userIdSelector.current.state.value ? this.userIdSelector.current.state.value.value : null
    );

    this.validationService.updateValue(
      'itemId',
      this.itemId.current.state.value ? this.itemId.current.state.value.value : null
    );

    if (this.handleValidation()) {
      this.sendFoundationForm();
    }
  }

  getFileDropzoneFoundationData(type: string) {
    if (this.props.foundation.foundation.data && !(type in this.state.fields)) {
      return this.props.foundation.foundation.data[type].mediaStorage;
    } else if (this[type]) {
      if (this[type].previewUrl) {
        return this[type].previewUrl;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  handleChange(field, e) {
    const fields = this.state.fields;
    fields[field] = e.target.value;
    this.validationService.updateValue(field, e.target.value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  handleDropzoneChange(field, value) {
    const fields = this.state.fields;
    fields[field] = value;
    this.validationService.updateValue(field, value);
    this.setState({ fields });
    this.handleValidation(field);
  }

  inputHandleChangeItems = (inputText: string) => {
    this.props.getItems(10, 0, inputText);
  }

  getItemsOptions = () => {
    return this.props.item.itemList.data
      ? this.props.item.itemList.data.data.map((val) => ({
          value: val.id,
          label:
            val.id +
            ' | ' +
            val.user.name +
            ' ' +
            val.user.surname +
            ' (' +
            val.player.fullName +
            ')' +
            ' [' +
            moment(val.created).format('YYYY-MM-DD') +
            ']',
        }))
      : [];
  }

  getUsersOptions = () => {
    return this.props.player.playerList.data
      ? this.props.player.playerList.data.data.map((val) => ({
          value: val.user.id,
          label: val.fullName + ' (' + val.user.id + ')',
        }))
      : [];
  }

  inputHandleChangeUsers = (inputText: string) => {
    this.props.getUsers(10, 0, inputText);
  }

  private sendFoundationForm() {
    this.props.createMoment(
      this.video,
      this.userIdSelector.current.state.value.value,
      this.itemId.current.state.value.value
    );
  }

  render() {
    if (this.props.foundation.foundation.isFetching) {
      return (
        <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
          <span className='sr-only'>...</span>
        </div>
      );
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'>
              <FormattedMessage id='news.news' />
            </h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <FormattedMessage id='news.news' />
              </li>
              <li className='breadcrumb-item active'>
                <FormattedMessage id={'sidebar.add_moment'} />
              </li>
            </ol>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <h3 className='card-title'>Create Fanzone Video</h3>
                    <hr />
                    <fieldset>
                      <div className='row'>
                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['userId'] ? ' error' : '')}>
                            <label className='control-label'>User</label>
                            <Select
                              className='select2 m-b-10 select2-multiple'
                              ref={this.userIdSelector}
                              style={{ width: '100%' }}
                              options={this.getUsersOptions()}
                              onInputChange={this.inputHandleChangeUsers}
                            />
                            <span className='error-message'>{this.state.errors['userId']}</span>
                          </div>
                        </div>

                        <div className='col-md-4'>
                          <div className={'form-group' + (this.state.errors['itemId'] ? ' error' : '')}>
                            <label className='control-label'>Asset</label>
                            <Select
                              ref={this.itemId}
                              value={this.state.fields['itemId']}
                              defaultValue={''}
                              options={this.getItemsOptions()}
                              onInputChange={this.inputHandleChangeItems}
                            />
                            <span className='error-message'>{this.state.errors['itemId']}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className={'form-group' + (this.state.errors['video'] ? ' error' : '')}>
                            <label>Video</label>
                            <FileDropzone
                              value={this.state.fields['video']}
                              accept='video/*'
                              preview={
                                this.state.fields['video'] ? this.state.fields['video'].previewUrl : undefined
                              }
                              onFileChange={(file: FormMedia[]) => {
                                this.video = file[0];
                                this.handleDropzoneChange('video', file[0] ? file[0].previewUrl : '');
                              }}
                            />
                            <span className='error-message'>{this.state.errors['video']}</span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className='form-actions text-right'>
                    <Link to='/fanzone/add'>
                      <button type='button' className='btn btn-info m-r-10'>
                        Cancel
                      </button>
                    </Link>

                    <button
                      disabled={this.props.foundation.foundationList.isFetching}
                      className='btn btn-success'
                      type='submit'
                    >
                      Upload Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getUsers: (limit: number, offset: number, query: string) =>
      dispatch(requestPlayers(limit, offset, query)),
    getItems: (limit: number, offset: number, query: string) => dispatch(requestItems(limit, offset, query)),
    createMoment: (video: FormMedia, userId: number, itemId: number) =>
      dispatch(createMomentAction(userId, video, itemId)),
  };
};

export default connect<StoreState, DispatchProps>(
  (store: StoreState) => store,
  mapDispatchToProps
)(AddMoment);
