import { push } from 'connected-react-router';
import {
  CreateFoundation as CreateFoundationEndpoint,
  EditFoundation as EditFoundationEndpoint,
  GetFoundationById,
  GetFoundations,
  NewFoundationSchema,
} from 'crypthum-sdk';
import { CreateMedia } from 'crypthum-sdk/dist/schemas/CreateMedia';
import { ListFoundation } from 'crypthum-sdk/dist/schemas/ListFoundation';
import { Foundation } from 'crypthum-sdk/src/schemas/Foundation';
import { NotificationManager } from 'react-notifications';

import { SDKProvider } from '../../components/providers/SDKProvider';
import { FormMedia } from '../state/entities/FormMedia';
import { endLoading, startLoading } from './global';
import * as types from './types';

const cryptoMuseumsSDK = SDKProvider.get();

const getFoundations = async (limit: number, offset: number, query: string): Promise<ListFoundation> =>
  await cryptoMuseumsSDK.endpoint<GetFoundations>(GetFoundations).run({
    limit: limit,
    offset: offset,
    query: query,
  });

const getFoundationById = async (id: number): Promise<Foundation> =>
  await cryptoMuseumsSDK.endpoint<GetFoundationById>(GetFoundationById).run({
    foundation_id: id,
  });

const createFoundation = async (body: NewFoundationSchema.NewFoundation) =>
  await cryptoMuseumsSDK.endpoint<CreateFoundationEndpoint>(CreateFoundationEndpoint).run({
    body: body,
  });

const editFoundation = async (id: number, body: NewFoundationSchema.NewFoundation) =>
  await cryptoMuseumsSDK.endpoint<EditFoundationEndpoint>(EditFoundationEndpoint).run({
    foundation_id: id,
    body: body,
  });

export interface CreateFoundation {
  type: types.CREATE_FOUNDATION;
}

export interface CreateFoundationFailed {
  type: types.CREATE_FOUNDATION_FAILED;
  error: string;
}

export interface CreateFoundationSuccess {
  type: types.CREATE_FOUNDATION_SUCCESS;
}

export interface EditFoundation {
  type: types.EDIT_FOUNDATION;
}

export interface EditFoundationFailed {
  type: types.EDIT_FOUNDATION_FAILED;
  error: string;
}

export interface EditFoundationSuccess {
  type: types.EDIT_FOUNDATION_SUCCESS;
}

export interface RequestFoundations {
  type: types.REQUEST_FOUNDATIONS;
}

export interface RequestFoundationsFailed {
  type: types.REQUEST_FOUNDATIONS_FAILED;
  error: string;
}

export interface RequestFoundationsSuccess {
  type: types.REQUEST_FOUNDATIONS_SUCCESS;
}

export interface ReceiveFoundations {
  type: types.RECEIVE_FOUNDATIONS;
  foundations: ListFoundation;
}

export interface RequestFoundation {
  type: types.REQUEST_FOUNDATION;
}

export interface RequestFoundationFailed {
  type: types.REQUEST_FOUNDATION_FAILED;
  error: string;
}

export interface RequestFoundationSuccess {
  type: types.REQUEST_FOUNDATION_SUCCESS;
}

export interface ReceiveFoundation {
  type: types.RECEIVE_FOUNDATION;
  foundation: Foundation;
}

export type FoundationAction =
  | RequestFoundation
  | RequestFoundationSuccess
  | RequestFoundationFailed
  | ReceiveFoundation
  | RequestFoundations
  | RequestFoundationsSuccess
  | RequestFoundationsFailed
  | ReceiveFoundations
  | CreateFoundation
  | CreateFoundationFailed
  | CreateFoundationSuccess
  | EditFoundation
  | EditFoundationFailed
  | EditFoundationSuccess;

export const createFoundationSuccess = () => (dispatch) => {
  dispatch(receiveFoundations(new ListFoundation(0, [])));
  dispatch({ type: types.CREATE_FOUNDATION_SUCCESS });
  dispatch(push('/foundations'));
  NotificationManager.success('La fundación ha sido creada correctamente.');
};

export const createFoundationFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.CREATE_FOUNDATION_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al crear la fundación.');
};

export const createFoundationAction = (
  name: string,
  link: string,
  status: 'ENABLED' | 'DISABLED',
  type: 'FOUNDATION' | 'CAUSE',
  description: Map<any, any>,
  logo: FormMedia,
  userId: number
) => async (dispatch) => {
  dispatch({ type: types.CREATE_FOUNDATION });
  dispatch(startLoading());

  if (!logo.file) {
    throw new Error('logo is null');
  }

  const file: File = logo.file;
  const newLogo = (await cryptoMuseumsSDK.media.upload(new CreateMedia(file.name, false, file))).id;

  // TODO Add video
  createFoundation(
    new NewFoundationSchema.NewFoundation(name, link, status, type, newLogo, null, null, description, userId)
  )
    .then(() => {
      dispatch(createFoundationSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(createFoundationFailed(e));
      dispatch(endLoading());
    });
  dispatch({ type: types.CREATE_FOUNDATION });
};

export const editFoundationSuccess = () => (dispatch) => {
  dispatch({ type: types.EDIT_FOUNDATION_SUCCESS });
  dispatch(push('/foundations'));
  NotificationManager.success('La fundación ha sido editada correctamente.');
};

export const editFoundationFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.EDIT_FOUNDATION_FAILED, error: errorKey });
  NotificationManager.error('Ha ocurrido un error al editar la fundación.');
};

export const editFoundationAction = (
  id: number,
  name: string,
  link: string,
  status: 'ENABLED' | 'DISABLED',
  type: 'FOUNDATION' | 'CAUSE',
  description: Map<any, any>,
  logo: FormMedia,
  userId: number
) => async (dispatch) => {
  dispatch({ type: types.EDIT_CLUB });
  dispatch(startLoading());

  let newLogo;

  if (logo && logo.file) {
    newLogo = (await cryptoMuseumsSDK.media.upload(new CreateMedia(logo.file.name, false, logo.file))).id;
  }
  // TODO Add video
  editFoundation(
    id,
    new NewFoundationSchema.NewFoundation(name, link, status, type, newLogo, undefined, undefined, description, userId)
  )
    .then(() => {
      dispatch(editFoundationSuccess());
      dispatch(endLoading());
    })
    .catch((e) => {
      dispatch(editFoundationFailed(e));
      dispatch(endLoading());
    });
};

export const receiveFoundations = (foundations: ListFoundation) => (dispatch) => {
  dispatch({ type: types.RECEIVE_FOUNDATIONS, foundations: foundations });
};

export const requestFoundationsFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATIONS_FAILED, error: errorKey });
};

export const requestFoundations = (limit: number, offset: number, query: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATIONS });

  getFoundations(limit, offset, query)
    .then((foundations) => {
      dispatch(receiveFoundations(foundations));
    })
    .catch((e) => {
      dispatch(requestFoundationsFailed(e));
    });
};

export const requestFoundationsSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATIONS_SUCCESS });
};

export const receiveFoundation = (foundation: Foundation) => (dispatch) => {
  dispatch({ type: types.RECEIVE_FOUNDATION, foundation: foundation });
};

export const requestFoundationFailed = (errorKey: string) => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATION_FAILED, error: errorKey });
};

export const requestFoundation = (id: number) => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATION });

  getFoundationById(id)
    .then((foundation) => {
      dispatch(receiveFoundation(foundation));
    })
    .catch((e) => {
      dispatch(requestFoundationFailed(e));
    });
};

export const requestFoundationSuccess = () => (dispatch) => {
  dispatch({ type: types.REQUEST_FOUNDATION_SUCCESS });
};
