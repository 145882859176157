import * as React from 'react';
import { connect } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestPlayerRate } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Player } from '../../redux/state/players';
import * as GetReactRef from '../utils/ReactRefsValuesGetter';

interface DispatchProps {
  getRate: (instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string) => void;
}

interface AllRateProps extends Player, DispatchProps {}

class License extends React.Component {
  instagramRef: React.RefObject<HTMLInputElement> = React.createRef();
  ageRef: React.RefObject<HTMLInputElement> = React.createRef();
  transferMarketRef: React.RefObject<HTMLInputElement> = React.createRef();
  sofifaRef: React.RefObject<HTMLInputElement> = React.createRef();
  private instagramClubs: { id: string; text: string }[] = [];

  constructor(public readonly props: AllRateProps) {
    super(props);

    this.state = {
      free1: <React.Fragment />,
      free2: <React.Fragment />,
      free3: <React.Fragment />,
      gold1: <React.Fragment />,
      gold2: <React.Fragment />,
      gold3: <React.Fragment />,
    };
  }

  enterFree1() {
    this.setState({
      free1: (
        <p className='m-0 position-relative'>
          <span className='hover-free highlighted-free font-weight-bold'>
            <span>50%</span>
          </span>
        </p>
      ),
    });
  }

  leaveFree1() {
    this.setState({ free1: <React.Fragment /> });
  }

  enterFree2() {
    this.setState({
      free2: (
        <p className='m-0 position-relative'>
          <span className='hover-free highlighted-free font-weight-bold'>
            <span>55%</span>
          </span>
        </p>
      ),
    });
  }

  leaveFree2() {
    this.setState({ free2: <React.Fragment /> });
  }

  enterFree3() {
    this.setState({
      free3: (
        <p className='m-0 position-relative'>
          <span className='hover-free highlighted-free font-weight-bold'>
            <span>60%</span>
          </span>
        </p>
      ),
    });
  }

  leaveFree3() {
    this.setState({ free3: <React.Fragment /> });
  }

  enterGold1() {
    this.setState({
      gold1: (
        <p className='m-0 position-relative'>
          <span className='hover-gold highlighted-gold font-weight-bold'>
            <span>70%</span>
          </span>
        </p>
      ),
    });
  }

  leaveGold1() {
    this.setState({ gold1: <React.Fragment /> });
  }

  enterGold2() {
    this.setState({
      gold2: (
        <p className='m-0 position-relative'>
          <span className='hover-gold highlighted-gold font-weight-bold'>
            <span>75%</span>
          </span>
        </p>
      ),
    });
  }

  leaveGold2() {
    this.setState({ gold2: <React.Fragment /> });
  }

  enterGold3() {
    this.setState({
      gold3: (
        <p className='m-0 position-relative'>
          <span className='hover-gold highlighted-gold font-weight-bold'>
            <span>80%</span>
          </span>
        </p>
      ),
    });
  }

  leaveGold3() {
    this.setState({ gold3: <React.Fragment /> });
  }

  private submit(e) {
    e.preventDefault();
    this.props.getRate(
      GetReactRef.asString(this.instagramRef),
      this.instagramClubs.map((value) => value.text),
      GetReactRef.asString(this.sofifaRef),
      GetReactRef.asString(this.transferMarketRef)
    );
  }

  handleAddIG(tagName: string) {
    this.instagramClubs.push({ id: tagName, text: tagName });
  }

  handleDeleteIG(tagIndex: number) {
    this.instagramClubs.splice(tagIndex, 1);
  }

  render() {
    let priceWithDiscount = (
      <div>
        <sup>€</sup>100.000
      </div>
    );
    if (this.props.rate.data) {
      const age = GetReactRef.asNumber(this.ageRef);
      const ages = [18, 20, 22, 24, 26, 28, 30, 32, 34, 35];
      let agePoints = 1;
      for (const ratio of ages) {
        if (age > ratio) {
          agePoints++;
        }
      }
      const points = (
        (this.props.rate.data.rss * 60) / 10 +
        (this.props.rate.data.fifa * 10) / 10 +
        (this.props.rate.data.market * 20) / 10 +
        (agePoints * 10) / 10
      ).toFixed(0);
      console.log(points);
      const discount = parseInt(points, undefined) * 1000;
      priceWithDiscount = (
        <div>
          <sup>€</sup>
          {(100000 - discount + '').replace(/\d(?=(?:\d{3})+$)/g, '$&.')}
          <span className='title-big-original'>€ 100.000</span>
        </div>
      );
    }
    let head;
    if (this.props.rate.error) {
      head = (
        <div className='card card-inverse card-danger'>
          <div className='card-body'>
            <h3 className='card-title'>Error fetching rate</h3>
            <p className='card-text'>
              There was an error downloading the rate. Please, try again in a few minutes.
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className='license-background'>
        <div className='col-free'>
          <div className='free'>
            <div className='header'>
              <p className='title'>Licencia</p>
              <p className='title-big'>Free Plan</p>
            </div>
            <div className='body'>
              <p>Museo</p>
              <p>Shirtum TV</p>
              <p>Smart Contract</p>
              <p className='gray-color'>
                Hasta 3 jugadores invitados. 1% de comisión por venta de cada pieza
              </p>

              <div className='container-fluid'>
                <p>Ganancias en la venta de tus tokens</p>
                <div className='row no-gutters'>
                  <div className='col-3'>
                    <p className='m-0 highlighted-free'>FANS</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>0-5 M</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>5-10 M</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>+10 M</p>
                  </div>
                </div>

                <div className='row no-gutters slider-row'>
                  <div className='col-12 slider-col'>
                    <div className='slider' />
                  </div>

                  <div className='col-3 text-center'>
                    <div className='slider-point slider-point-free' />
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-free'
                      type='radio'
                      id='free1'
                      name='fans-free'
                      value='50'
                    />
                    <label
                      htmlFor='free1'
                      onMouseEnter={this.enterFree1.bind(this)}
                      onMouseLeave={this.leaveFree1.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-free'
                      type='radio'
                      id='free2'
                      name='fans-free'
                      value='55'
                    />
                    <label
                      htmlFor='free2'
                      onMouseEnter={this.enterFree2.bind(this)}
                      onMouseLeave={this.leaveFree2.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-free'
                      type='radio'
                      id='free3'
                      name='fans-free'
                      value='60'
                    />
                    <label
                      htmlFor='free3'
                      onMouseEnter={this.enterFree3.bind(this)}
                      onMouseLeave={this.leaveFree3.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                </div>

                <div className='row no-gutters hover-row'>
                  <div className='offset-3 col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['free1']}</div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['free2']}</div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['free3']}</div>
                    </div>
                  </div>
                </div>
              </div>

              <p>Derechos reales de por vida en el mercado 2%</p>
              <p>Comisión sobre beneficio por intercambio 10%</p>

              <div className='subsection'>
                <p className='subsection-title'>REQUISITOS MÍNIMOS</p>
                <label>
                  <input type='checkbox' /> He jugado en primera división o series A
                </label>
                <label>
                  <input type='checkbox' /> 500.000 seguidores en redes sociales
                </label>
              </div>
            </div>
            <div className='footer2'>
              <button type='button' className='free-button'>
                RESERVA TU MUSEO
              </button>
            </div>
          </div>
        </div>
        <div className='col-gold'>
          <div className='gold'>
            <div className='header'>
              <p className='title'>Licencia Pre-Launch</p>
              <p className='title-big'>{priceWithDiscount}</p>
            </div>
            <div className='body'>
              <p>Museo destacado en la Home</p>
              <p>Shirtum TV</p>
              <p>Smart Contract</p>
              <p className='gray-color'>
                Hasta <span className='highlighted'>11 jugadores</span> invitados.{' '}
                <span className='highlighted'>2% de comisión</span> por venta de cada pieza
              </p>

              <div className='container-fluid'>
                <p>Ganancias en la venta de tus tokens</p>
                <div className='row no-gutters'>
                  <div className='col-3'>
                    <p className='m-0 highlighted'>FANS</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>0-5 M</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>5-10 M</p>
                  </div>
                  <div className='col-3'>
                    <p className='m-0'>+10 M</p>
                  </div>
                </div>

                <div className='row no-gutters slider-row'>
                  <div className='col-12 slider-col'>
                    <div className='slider' />
                  </div>

                  <div className='col-3 text-center'>
                    <div className='slider-point slider-point-gold' />
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-gold'
                      type='radio'
                      id='gold1'
                      name='fans-gold'
                      value='70'
                    />
                    <label
                      htmlFor='gold1'
                      onMouseEnter={this.enterGold1.bind(this)}
                      onMouseLeave={this.leaveGold1.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-gold'
                      type='radio'
                      id='gold2'
                      name='fans-gold'
                      value='75'
                    />
                    <label
                      htmlFor='gold2'
                      onMouseEnter={this.enterGold2.bind(this)}
                      onMouseLeave={this.leaveGold2.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                  <div className='col-3 text-center'>
                    <input
                      className='fans-radio fans-radio-gold'
                      type='radio'
                      id='gold3'
                      name='fans-gold'
                      value='80'
                    />
                    <label
                      htmlFor='gold3'
                      onMouseEnter={this.enterGold3.bind(this)}
                      onMouseLeave={this.leaveGold3.bind(this)}
                    >
                      <span />
                    </label>
                  </div>
                </div>

                <div className='row no-gutters'>
                  <div className='offset-3 col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['gold1']}</div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['gold2']}</div>
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='position-relative'>
                      <div className='position-absolute hover-col'>{this.state['gold3']}</div>
                    </div>
                  </div>
                </div>
              </div>

              <p>
                Derechos reales de por vida en el mercado{' '}
                <span className='highlighted font-weight-bold'>5%</span>
              </p>
              <p>
                Comisión sobre beneficio por intercambio{' '}
                <span className='highlighted font-weight-bold'>20%</span>
              </p>
              <p>Pase preferente de Museo</p>
              <p>Apoyo en Marketing y en Redes Sociales</p>

              <div className='subsection'>
                <p className='subsection-title'>CALCULAR DESCUENTO</p>
                <form onSubmit={this.submit.bind(this)}>
                  <div className='form-body'>
                    <fieldset>
                      <div className='container-fluid'>
                        <div className='row'>
                          <div className='col-xl-6 col-lg-12 col-md-6'>
                            <div className={'form-group'}>
                              <label className='control-label'>Instagram Player</label>
                              <div className='input-group mb-3'>
                                <input ref={this.instagramRef} type='text' className='form-control' />
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-6 col-lg-12 col-md-6'>
                            <div className={'form-group'}>
                              <label className='control-label'>Transfermarkt URL</label>
                              <div className='input-group mb-3'>
                                <input ref={this.transferMarketRef} type='text' className='form-control' />
                              </div>
                            </div>
                          </div>

                          <div className='col-xl-6 col-lg-12 col-md-6'>
                            <div className={'form-group'}>
                              <label className='control-label'>Instagram Clubs</label>
                              <ReactTags
                                autofocus={false}
                                className='form-control'
                                inputFieldPosition='inline'
                                handleAddition={(tag: { id: string; text: string }) =>
                                  this.handleAddIG(tag.text)
                                }
                                handleDelete={(tagIndex: number) => this.handleDeleteIG(tagIndex)}
                                allowDragDrop={false}
                                tags={this.instagramClubs}
                              />
                            </div>
                          </div>
                          <div className='col-xl-6 col-lg-12 col-md-6'>
                            <div className={'form-group'}>
                              <label className='control-label'>SoFIFA URL</label>
                              <div className='input-group mb-3'>
                                <input ref={this.sofifaRef} type='text' className='form-control' />
                              </div>
                            </div>
                          </div>

                          <div className='col-xl-6 col-lg-12 col-md-6'>
                            <div className={'form-group'}>
                              <label className='control-label'>Edad</label>
                              <div className='input-group mb-3'>
                                <input ref={this.ageRef} type='number' className='form-control' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  {head}

                  <div className='form-actions text-center'>
                    <button disabled={this.props.rate.isFetching} className='free-button' type='submit'>
                      Aplicar descuento
                    </button>
                  </div>
                </form>
              </div>

              <div className='subsection'>
                <p className='subsection-title'>REQUISITOS MÍNIMOS</p>
                <label>
                  <input type='checkbox' /> He jugado en primera división o series A
                </label>
                <label>
                  <input type='checkbox' /> 500.000 seguidores en redes sociales
                </label>
              </div>
            </div>
            <div className='footer2'>
              <button type='button' className='gold-button'>
                RESERVA TU MUSEO
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getRate: (instagram: string, clubInstagrams: string[], sofifaUrl: string, transfermarktUrl: string) =>
      dispatch(requestPlayerRate(instagram, clubInstagrams, sofifaUrl, transfermarktUrl)),
  };
};

export default connect<Player, DispatchProps>(
  (store: StoreState) => store.player,
  mapDispatchToProps
)(License);
