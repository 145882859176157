import { FoundationAction } from "../actions";
import * as FoundationActionType from "../actions/types/foundations";
import { Foundation, getInitialState } from "../state/foundations";

export const foundations = (state: Foundation = getInitialState(), action: FoundationAction): Foundation => {
  switch (action.type) {
    case FoundationActionType.CREATE_FOUNDATION:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: true,
        },
      };
    case FoundationActionType.CREATE_FOUNDATION_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
        },
      };
    case FoundationActionType.CREATE_FOUNDATION_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isFetching: false,
          error: action.error,
        },
      };
    case FoundationActionType.EDIT_FOUNDATION:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: true,
        },
      };
    case FoundationActionType.EDIT_FOUNDATION_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
        },
      };
    case FoundationActionType.EDIT_FOUNDATION_FAILED:
      return {
        ...state,
        edit: {
          ...state.edit,
          isFetching: false,
          error: action.error,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATIONS:
      return {
        ...state,
        foundationList: {
          ...state.foundationList,
          isFetching: true,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATIONS_FAILED:
      return {
        ...state,
        foundationList: {
          ...state.foundationList,
          isFetching: false,
          error: action.error,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATIONS_SUCCESS:
      return {
        ...state,
        foundationList: {
          ...state.foundationList,
          isFetching: false,
        },
      };
    case FoundationActionType.RECEIVE_FOUNDATIONS:
      return {
        ...state,
        foundationList: {
          ...state.foundationList,
          isFetching: false,
          data: action.foundations,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATION:
      return {
        ...state,
        foundation: {
          ...state.foundation,
          isFetching: true,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATION_FAILED:
      return {
        ...state,
        foundation: {
          ...state.foundation,
          isFetching: false,
          error: action.error,
        },
      };
    case FoundationActionType.REQUEST_FOUNDATION_SUCCESS:
      return {
        ...state,
        foundation: {
          ...state.foundation,
          isFetching: false,
        },
      };
    case FoundationActionType.RECEIVE_FOUNDATION:
      return {
        ...state,
        foundation: {
          ...state.foundation,
          isFetching: false,
          data: action.foundation,
        },
      };
    default:
      return state;
  }
};
