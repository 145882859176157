import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { requestCategories } from '../../redux/actions';
import { StoreState } from '../../redux/state';
import { Category } from '../../redux/state/categories';
import { dataTableOptions } from '../ui/DataTableOptions';

interface DispatchProps {
  getCategories: () => void;
}

interface ListCategoriesProps extends Category, DispatchProps { }

class ListCategories extends React.Component<ListCategoriesProps> {

  constructor(public readonly props: ListCategoriesProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCategories();
  }

  private retrieveCategory(id: number) {

    const categoryList = this.props.categoryList.data ? this.props.categoryList.data : [];

    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].id === id) {
        return categoryList[i];
      }
    }

    return null;
  }

  private retrieveParents(categories: number[]) {
    let categoriesString = '';
    let i: number;

    for (i = 0; i < categories.length; i++) {
      const category = this.retrieveCategory(categories[i]);
      if (category !== null) {
        if (categoriesString.length > 0) {
          categoriesString += ', ';
        }
        categoriesString += category.name;
      }
    }

    return categoriesString;
  }


  render() {

    const columns = [
      {
        name: 'name',
        label: 'Full Name'
      }, {
        name: 'parents',
        label: 'Parents'
      }, {
        name: 'created',
        label: 'Created'
      }, {
        name: 'options',
        label: 'Options'
      }
    ];

    const data: any[] = [];

    if (this.props.categoryList.data) {
      for (const category of this.props.categoryList.data) {
        data.push({
          name: category.name,
          parents: this.retrieveParents(category.parents),
          created: moment(category.created).format('YYYY-MM-DD HH:mm:ss'),
          options: <Link to={`/category/edit/${category.id}`}>
            <button className='btn btn-success waves-effect waves-light' type='button'>
              <i className='fa fa-pencil-alt' />
            </button>
          </Link>,
        });
      }
    }

    const options = {
      ...dataTableOptions,
      customSearch: undefined,
    };

    let body;

    if (this.props.categoryList.isFetching) {

      body = <div className='spinner-grow' style={{ width: '3em', height: '3em' }} role='status'>
        <span className='sr-only'>...</span>
      </div>;

    } else if (this.props.categoryList.error) {

      body = <div className='card card-inverse card-danger'>
        <div className='card-body'>
          <h3 className='card-title'>Error fetching categories</h3>
          <p className='card-text'>There was an error downloading the category list. Please, try again in a few minutes.</p>
          <button className='btn btn-inverse' onClick={() => { this.props.getCategories(); }}>Try again</button>
        </div>
      </div>;

    } else {

      body = <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'><FormattedMessage id='categories.category_table.title' /></h4>
              <h6 className='card-subtitle'><FormattedMessage id='categories.category_table.subtitle' /></h6>
              <div className='table-responsive'>
                <MUIDataTable
                  data={data}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>
      </div>;
    }

    return (
      <div className='container-fluid'>
        <div className='row page-titles'>
          <div className='col-md-5 align-self-center'>
            <h3 className='text-themecolor'><FormattedMessage id='categories.categories' /></h3>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'><FormattedMessage id='categories.categories' /></li>
              <li className='breadcrumb-item active'><FormattedMessage id='categories.list_categories' /></li>
            </ol>
          </div>
          <div className='col-md-7 align-self-center text-right d-none d-md-block'>
            <Link to='/category/add'>
              <button type='button' className='btn btn-info'>
                <i className='fa fa-plus-circle'></i> <FormattedMessage id='categories.create_category' />
              </button>
            </Link>
          </div>
        </div>

        {body}

        <NotificationContainer />

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    getCategories: () => dispatch(requestCategories())
  };
};

export default connect<Category, DispatchProps>(
  (store: StoreState) => store.category,
  mapDispatchToProps,
)(ListCategories);
